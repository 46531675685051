import Components from '..';
import { ISkiSchool } from '../../modules/skiSchool/models';
import './SchoolTemplate.scss';

interface IProps {
  schools: ISkiSchool[];
}

const SchoolTemplate = (props: IProps) => {
  const { schools } = props;

  return (
    <div className="berg-components-school-template">
      {schools?.map((school) => (
        <Components.School key={school.id} school={school} />
      ))}
    </div>
  );
};

export default SchoolTemplate;
