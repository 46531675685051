/* eslint-disable complexity */
import { useTranslation } from 'react-i18next';
import Components from '../../components';
import Hooks from '../../hooks';
import {
  getAccommodations,
  getDaysCount,
  getOverallAvailability,
  getSearchParams,
} from '../../modules/accommodation/selectors';
import Criteria from './Accommodation/Criteria';
import Pictures from '../../assets/picture';
import { useContext, useEffect, useState } from 'react';
import { IAccommodation } from '../../modules/accommodation/models';
import Icons from '../../assets/icons';
import { resetCriteria } from '../../modules/accommodation/slice';
import {
  BasketItemType,
  IAccommodationBasketItem,
} from '../../modules/basket/models';
import { IProduct } from '../../modules/common/models/productModels';
import { CookieNames, Season } from '../../config/globalVariables';
import { SeasonContext } from '../../context/SeasonContext';
import visibleOnRelease from '../../config/visibleOnRelease';
import { addToBasket } from '../../modules/basket/operations';
import OutOfService from '../../components/OutOfService/OutOfService';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Accommodation = () => {
  const { t } = useTranslation('pages');
  const { theme } = useContext(SeasonContext);

  const [selectedRoom, setSelectedRoom] = useState<IAccommodation>();

  const rooms = Hooks.useAppSelector((s) => getAccommodations(s.accommodation));
  const dispatch = Hooks.useAppDispatch();
  const daysCount = Hooks.useAppSelector((s) => getDaysCount(s.accommodation));
  const searchParams = Hooks.useAppSelector((s) =>
    getSearchParams(s.accommodation)
  );

  const [cookies] = useCookies([CookieNames.PromoCode]);
  const deadline =
    moment(searchParams.startDate).isAfter(moment('2023-12-15')) ||
    moment(searchParams.endDate).isAfter(moment('2023-12-15'));

  const onCardClick = (id: string) => {
    setSelectedRoom(rooms.find((room) => room.id === id));
  };

  const backToList = () => setSelectedRoom(undefined);
  const isAvailable = Hooks.useAppSelector((s) =>
    getOverallAvailability(s.accommodation)
  );

  useEffect(
    () => () => {
      dispatch(resetCriteria());
    },
    [dispatch]
  );

  const [quantityRoom, setQuantityRoom] = useState<number>(1);
  const [quantitySupply, setQuantitySupply] = useState<number>(0);

  const onCountChangeRoom = (count: number) => {
    if (count < 1) return;
    setQuantitySupply(0);
    setQuantityRoom(count);
  };

  // const onCountChangeSupply = (count: number) => {
  //   if (count < -1) return;
  //   setQuantitySupply(count);
  // };

  const handleSubmitClick = (accommodation: IAccommodation) => {
    const item = {
      itemType: BasketItemType.Accommodation,
      item: accommodation as IProduct,
      price: accommodation.price.grossAmount,
      additionalGuestPrice: accommodation.price.additionalGuestPrice,
      quantity: quantityRoom,
      additionalGuestsCount: quantitySupply,
      currency: accommodation.price.currency,
      startDate: searchParams.startDate,
      endDate: searchParams.endDate,
      adultCount: searchParams.adultCount,
      childrenAges: searchParams.childrenAges,
      daysCount: daysCount,
      priceBeforeRecalculation: searchParams.priceBeforeRecalculation,
      priceRecalculated: searchParams.priceRecalculated,
    } as IAccommodationBasketItem;
    dispatch(addToBasket(item));
  };

  return (
    <div className="berg-pages-activities__accommodation">
      <Components.Portal id="header">
        <div className="berg-pages-activities__header--accommodation">
          <h1>{t('activities.accommodation.header')}</h1>
        </div>
      </Components.Portal>
      <Criteria selectedRoom={selectedRoom} />
      {visibleOnRelease.outOfService && <OutOfService />}
      {selectedRoom ? (
        <>
          <button onClick={backToList}>
            <Icons.Back className="berg-pages-activities__back-buttons" />
            <Icons.BackMobile className="berg-pages-activities__back-mobile-buttons" />
          </button>

          <div className="berg-pages-activities__accommodation--discount-fullpage">
            {Season.Winter === theme ? <Icons.Star /> : <Icons.StarPerry />}
            {t('activities.accommodation.discount')}
          </div>
          {visibleOnRelease.parking && (
            <div className="berg-pages-activities__parking--info">
              <p>{t('activities.parking_info_accommodation')}</p>
            </div>
          )}

          <Components.RoomFullCard
            daysCount={daysCount}
            adultCount={searchParams.adultCount}
            childrenCount={searchParams.childrenAges?.length}
            startDate={searchParams.startDate}
            endDate={searchParams.endDate}
            accommodation={selectedRoom}
            onSubmitClick={handleSubmitClick}
            quantityRoom={quantityRoom}
            // quantitySupply={quantitySupply}
            onCountChangeRoom={onCountChangeRoom}
            // onCountChangeSupply={onCountChangeSupply}
          />
        </>
      ) : (
        <>
          {rooms?.length > 0 ? (
            <>
              <h3 data-available={isAvailable}>
                {t('activities.accommodation.results_header')}
                {cookies[CookieNames.PromoCode] && deadline && (
                  <div className="berg-pages-activities__accommodation--promo-deadline">
                    <Icons.AlertAttentionRed />
                    {t('activities.accommodation.promo_deadline')}
                  </div>
                )}
                <span>
                  {!isAvailable &&
                    t('activities.accommodation.unavailable_note')}
                </span>

                <div className="berg-pages-activities__accommodation--discount">
                  {Season.Winter === theme ? (
                    <Icons.Star />
                  ) : (
                    <Icons.StarPerry />
                  )}
                  {t('activities.accommodation.discount')}
                </div>
                {visibleOnRelease.parking && (
                  <div className="berg-pages-activities__parking--info">
                    <p>{t('activities.parking_info_accommodation')}</p>
                  </div>
                )}
              </h3>

              <div className="berg-pages-activities__rooms-wrapper">
                {rooms.map((room) => (
                  <Components.RoomCard
                    key={room.id}
                    accommodation={room}
                    daysCount={daysCount}
                    onRoomCardClick={onCardClick}
                    // onSubmitClick={handleSubmitClick}
                  />
                ))}
              </div>
            </>
          ) : (
            <img
              className="berg-pages-activities__accommodation-placeholder"
              src={Pictures.RoomPlaceholder}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Accommodation;
