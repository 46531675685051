import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductType } from '../common/models/productModels';
import { ISkiSchool, ISkiSchoolStateModel } from './models';
import { fetchSkiSchoolTypes, fetchSkiSchools } from './operations';

const initialState = {} as ISkiSchoolStateModel;

const skiRentalSlice = createSlice({
  name: 'SkiSchoolTypes',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSkiSchoolTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSkiSchoolTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.skiSchoolTypes = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchSkiSchools.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSkiSchools.fulfilled,
      (state, action: PayloadAction<ISkiSchool[]>) => {
        state.schoolPasses = action.payload;
        state.loading = false;
      }
    );
  },
});

export const { setDate } = skiRentalSlice.actions;
export default skiRentalSlice.reducer;
