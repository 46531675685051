/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '../../assets/icons';
import './Footer.scss';
// import policyReturns from '../../assets/regulations/Polityka_zwrotów_Brama_w_Gorce.pdf';
// import generalTradingConditions from '../../assets/regulations/Ogólne_Warunki_Handlowe_Brama_w_Gorce.pdf';
import regulaminSprzedazyBrama from '../../assets/regulations/brama-regulamin-sprzedazy.pdf';
import regulaminSprzedazyPotok from '../../assets/regulations/GP_regulamin_sprzedaży.pdf';
import regulaminSprzedazyFlisacy from '../../assets/regulations/Flisacy_Regulamin_sprzedaży.pdf';
import { useLogosAndLinks } from '../../utils/useLogoAndLinks';
import formatPhoneNumber from '../../utils/formattedPhone';

const Footer = () => {
  const { t } = useTranslation('components');
  const year = new Date().getFullYear();
  const {
    logoSrcFlisacy,
    logoSrcBrama,
    logoSrcPolana,
    logoSrcPotok,
    linkBrama,
    linkFlisacy,
    linkPolana,
    linkPotok,
  } = useLogosAndLinks();

  const labelBrama = process.env.REACT_APP_LABEL_BRAMA;
  const labelFlisacy = process.env.REACT_APP_LABEL_FLISACY;
  const labelPolana = process.env.REACT_APP_LABEL_POLANA;
  const labelPotok = process.env.REACT_APP_LABEL_POTOK;

  const addressBrama = process.env.REACT_APP_ADDRESS_BRAMA;
  const addressFlisacy = process.env.REACT_APP_ADDRESS_FLISACY;
  const addressPolana = process.env.REACT_APP_ADDRESS_POLANA;
  const addressPotok = process.env.REACT_APP_ADDRESS_POTOK;

  const phoneBrama = process.env.REACT_APP_PHONE_BRAMA;
  const phoneFlisacy = process.env.REACT_APP_PHONE_FLISACY;
  const phonePolana = process.env.REACT_APP_PHONE_POLANA;
  const phonePotok = process.env.REACT_APP_PHONE_POTOK;

  const emailBrama = process.env.REACT_APP_EMAIL_BRAMA;
  const emailFlisacy = process.env.REACT_APP_EMAIL_FLISACY;
  const emailPolana = process.env.REACT_APP_EMAIL_POLANA;
  const emailPotok = process.env.REACT_APP_EMAIL_POTOK;

  const buildType = process.env.REACT_APP_BUILD_TYPE;

  return (
    <section>
      <div className="footer-component-body">
        <div className="footer-component-body__left">
          <div className="footer-component-header">
            {buildType === 'BRAMA' && (
              <a href={linkBrama}>
                <img src={logoSrcBrama} alt="Logo Brama W Gorce" />
              </a>
            )}
            {buildType === 'FLISACY' && (
              <a href={linkFlisacy}>
                <img src={logoSrcFlisacy} alt="Logo Flisacy" />
              </a>
            )}
            {buildType === 'POLANA' && (
              <a href={linkPolana}>
                <img src={logoSrcPolana} alt="Logo Długa Polana" />
              </a>
            )}
            {buildType === 'POTOK' && (
              <a href={linkPotok}>
                <img src={logoSrcPotok} alt="Logo Goracy Potok" />
              </a>
            )}
          </div>
          <div className="footer-component-company-data">
            <div>
              <p>
                {labelBrama && <strong>{labelBrama}:</strong>}
                {labelFlisacy && <strong>{labelFlisacy}:</strong>}
                {labelPolana && <strong>{labelPolana}:</strong>}
                {labelPotok && <strong>{labelPotok}:</strong>}
              </p>
              <p>
                {addressBrama && <p>{addressBrama}</p>}
                {addressFlisacy && <p>{addressFlisacy}</p>}
                {addressPolana && <p>{addressPolana}</p>}
                {addressPotok && <p>{addressPotok}</p>}
              </p>
            </div>
            {phoneBrama && emailBrama && (
              <div>
                <a href={`tel:${phoneBrama}`}>
                  <p>
                    <strong>{t('footer.phone')}</strong>{' '}
                    {formatPhoneNumber(phoneBrama)},
                  </p>
                </a>

                <a href={`mailto:${emailBrama}`}>
                  <p>
                    <strong>{t('footer.mail')}</strong> {emailBrama}
                  </p>
                </a>
              </div>
            )}
            {phoneFlisacy && emailFlisacy && (
              <div>
                <a href={`tel:${phoneFlisacy}`}>
                  <p>
                    <strong>{t('footer.phone')}</strong>{' '}
                    {formatPhoneNumber(phoneFlisacy)},
                  </p>
                </a>

                <a href={`mailto:${emailFlisacy}`}>
                  <p>
                    <strong>{t('footer.mail')}</strong> {emailFlisacy}
                  </p>
                </a>
              </div>
            )}
            {phonePolana && emailPolana && (
              <div>
                <a href={`tel:${phonePolana}`}>
                  <p>
                    <strong>{t('footer.phone')}</strong>{' '}
                    {formatPhoneNumber(phonePolana)},
                  </p>
                </a>

                <a href={`mailto:${emailPolana}`}>
                  <p>
                    <strong>{t('footer.mail')}</strong> {emailPolana}
                  </p>
                </a>
              </div>
            )}
            {phonePotok && emailPotok && (
              <div>
                <a href={`tel:${phonePotok}`}>
                  <p>
                    <strong>{t('footer.phone')}</strong>{' '}
                    {formatPhoneNumber(phonePotok)},
                  </p>
                </a>

                <a href={`mailto:${emailPotok}`}>
                  <p>
                    <strong>{t('footer.mail')}</strong> {emailPotok}
                  </p>
                </a>
              </div>
            )}
          </div>
        </div>
        {buildType === 'BRAMA' && linkBrama && (
          <a
            className="footer-component-button"
            href={linkBrama.concat('kontakt')}
          >
            <button>{t('footer.contact')}</button>
          </a>
        )}
        {buildType === 'FLISACY' && linkFlisacy && (
          <a
            className="footer-component-button"
            href={linkFlisacy.concat('dojazd---kontakt')}
          >
            <button>{t('footer.contact')}</button>
          </a>
        )}
        {buildType === 'POLANA' && linkPolana && (
          <a
            className="footer-component-button"
            href={linkPolana.concat('menu-gora/kontakt')}
          >
            <button>{t('footer.contact')}</button>
          </a>
        )}
        {buildType === 'POTOK' && linkPotok && (
          <a
            className="footer-component-button"
            href={linkPotok.concat('kontakt')}
          >
            <button>{t('footer.contact')}</button>
          </a>
        )}
      </div>
      <hr />
      <div className="footer-component-footer">
        <div className="footer-component-footer__rules">
          {buildType === 'BRAMA' && linkBrama && (
            <div>
              <a
                href={linkBrama.concat('regulamin')}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations')}
              </a>
              {/* <a href={policyReturns} target="_blank" rel="noreferrer">
                {t('footer.policy_returns')}
              </a> */}
              <a
                href={linkBrama.concat('polityka-prywatnosci')}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.rodo')}
              </a>
              <a
                href={linkBrama.concat('polityka-prywatnosci')}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.cookies')}
              </a>
              <a
                href={regulaminSprzedazyBrama}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.sale')}
              </a>
              {/* <a
                href={generalTradingConditions}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.generalBusinessConditions')}
              </a> */}
            </div>
          )}
          {buildType === 'FLISACY' && linkFlisacy && (
            <div>
              <a
                href="https://flisacy.sromowce.pl/regulamin/Regulamin-pl.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations')}
              </a>
              <a
                href={linkFlisacy.concat('rodo')}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.rodo')}
              </a>
              <a
                href={regulaminSprzedazyFlisacy}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.sale')}
              </a>
            </div>
          )}
          {buildType === 'POLANA' && linkPolana && (
            <div>
              <a
                href="https://tatrysuperski.pl/assets/public/dokumenty/regulaminy/regulamin_korzystania_z_karnetu_PL.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations_tts')}
              </a>
              <a
                href="https://tatrysuperski.pl/assets/public/dokumenty/regulaminy/regulamin_sprzedazy_internetowej_PL.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations_online_tts')}
              </a>
              <a
                href={linkPolana.concat('polityka-prywatnosci')}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy')}
              </a>
            </div>
          )}
          {buildType === 'POTOK' && linkPotok && (
            <div>
              <a
                href={linkPotok.concat(
                  '_pliki/regulamin-ogolny-kompleksu-goracy-potok-v-11032021,abb76.pdf'
                )}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.regulations')}
              </a>

              <a
                href={linkPotok.concat(
                  '_pliki/polityka-prywatnosci-v11032021,791f8.pdf'
                )}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.privacy')}
              </a>

              <a
                href={regulaminSprzedazyPotok}
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.sale')}
              </a>
            </div>
          )}
        </div>
        <div className="footer-component-footer__berg">
          <span className="footer-component-footer-copyright">
            {t('footer.copyright', { year })}
          </span>
          <a
            href="http://bergregions.pl"
            target="_blank"
            rel="noreferrer"
            className="footer-component-footer__berg-logo"
          >
            <Icons.LogoBergRegions />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
