import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';
import useDialog from './useDialog';
import useOutsideClick from './useOutsideClick';
import usePriceDisplay from './usePriceDisplay';
import useQueryParams from './useQueryParams';
import useDateFormat from './useDateFormat';

const Hooks = {
  useOutsideClick,
  usePriceDisplay,
  useAppDispatch,
  useAppSelector,
  useDialog,
  useQueryParams,
  useDateFormat,
};

export default Hooks;
