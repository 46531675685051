import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';
import { PaymentMethod } from '../common/models/enums';
import { CompanyData, ContactData } from '../common/models/orderData';
import {
  basketExpirationItemKey,
  basketItemKey,
  expirationDateFormat,
} from './const';
import { IBasketItem, IBasketStateModel } from './models';
import {
  addQuantityToBasketItem,
  addToBasket,
  removeBasketItem,
} from './operations';
import {
  IProductAdditionalSettings,
  IProductComponents,
} from '../common/models/productModels';

let initialState = {
  totalPrice: 0,
  totalPriceAtLocation: 0,
  items: [] as IBasketItem[],
  basketCurrency: '',
  showPopups: false,
  lastItemId: '',
} as IBasketStateModel;

const expirationDateValue = localStorage.getItem(basketExpirationItemKey);
if (
  !expirationDateValue ||
  moment(expirationDateValue, expirationDateFormat).isAfter(new Date())
) {
  const localStorageState = localStorage.getItem(basketItemKey);
  if (localStorageState) {
    try {
      initialState = JSON.parse(localStorageState) as IBasketStateModel;
      initialState.showPopups = false;
      console.info('Basket state initialized from local storage');
    } catch (error) {
      console.warn(
        'Cannot parse initial state from local storage',
        localStorageState
      );
      localStorage.removeItem(basketItemKey);
    }
  }
} else {
  console.log('Basket expired');
  localStorage.removeItem(basketExpirationItemKey);
  localStorage.removeItem(basketItemKey);
}

const basketSlice = createSlice({
  name: 'Basket',
  initialState,
  reducers: {
    updateContactData: (state, action: PayloadAction<ContactData>) => {
      state.contactData = action.payload;
      updateLocalStorage(state);
    },
    updateCompanyData: (state, action: PayloadAction<CompanyData>) => {
      state.companyData = action.payload;
      updateLocalStorage(state);
    },
    clearBasket: (state) => {
      state.companyData = undefined;
      state.contactData = undefined;
      state.totalPrice = 0;
      state.totalPriceAtLocation = 0;
      state.items = [];
      state.basketCurrency = '';
      localStorage.removeItem(basketItemKey);
      localStorage.removeItem(basketExpirationItemKey);
    },
    showPopups: (state) => {
      state.showPopups = true;
    },
    hidePopups: (state) => {
      console.log();
      state.showPopups = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      addToBasket.fulfilled,
      (
        state,
        action: PayloadAction<[items: IBasketItem[], lastId: string]>
      ) => {
        state.items = action.payload[0];
        state.lastItemId = action.payload[1];
        state.showPopups = true;
        updateTotal(state);
        updateLocalStorage(state);
      }
    );
    builder.addCase(
      removeBasketItem.fulfilled,
      (
        state,
        action: PayloadAction<[items: IBasketItem[], removed: boolean]>
      ) => {
        if (action.payload[1]) {
          state.items = action.payload[0];
          updateTotal(state);
          updateLocalStorage(state);
        }
      }
    );
    builder.addCase(
      addQuantityToBasketItem.fulfilled,
      (
        state,
        action: PayloadAction<[items: IBasketItem[], changed: boolean]>
      ) => {
        if (action.payload[1]) {
          state.items = action.payload[0];
          updateTotal(state);
          updateLocalStorage(state);
        }
      }
    );
  },
});

const updateTotal = (state: IBasketStateModel) => {
  const calculatePriceReducer = (acc: number, item: IBasketItem) => {
    console.log(
      `Acc: ${acc}, Price: ${item.price}, quantity: ${
        item.quantity
      }, dayCount: ${item?.daysCount}, priceSupply: ${
        item?.additionalGuestPrice
      },quantitySupply: ${
        item?.additionalGuestsCount
      }, selectedHour: ${item?.productComponents?.map(
        (product: IProductComponents) =>
          product.productAdditionalSettings.map(
            (item: IProductAdditionalSettings) => item?.value
          )
      )} `
    );
    return (
      acc +
      calculatePrice(
        item.price,
        item?.daysCount || 1,
        item.quantity,
        item?.additionalGuestPrice,
        item?.additionalGuestsCount
      )
    );
  };
  state.totalPrice = state.items.reduce(calculatePriceReducer, 0);
  state.totalPriceAtLocation = state.items
    .filter((x) => x.item.defaultPaymentMethod === PaymentMethod.OnSite)
    .reduce(calculatePriceReducer, 0);
  state.basketCurrency = state.items?.[0]?.currency;
};

// const roundPrice = (value: number) =>
//   +Math.round((value * 10 + Number.EPSILON) * 100) / 100;

const updateLocalStorage = (state: IBasketStateModel) => {
  localStorage.setItem(basketItemKey, JSON.stringify(state));
  const expirationDate = moment().add(20, 'minutes');

  localStorage.setItem(
    basketExpirationItemKey,
    expirationDate.format(expirationDateFormat)
  );
};

export const { updateContactData, updateCompanyData, clearBasket, hidePopups } =
  basketSlice.actions;
export default basketSlice.reducer;
