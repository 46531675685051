/* eslint-disable complexity */
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Season } from '../../config/globalVariables';
import { SeasonContext } from '../../context/SeasonContext';
import Tooltip from '../Tooltip/Tooltip';

const CtaButton = () => {
  const { season, theme } = useContext(SeasonContext);

  const { t } = useTranslation('components');

  return (
    <>
      {((theme === Season.Winter && season === Season.Summer) ||
        (theme === Season.Summer && season === Season.Summer) ||
        (theme === Season.Winter && season === Season.Winter)) && (
        <div className="berg-components-navigation-cta">
          <Link to="/activities/tickets">
            <span>{t('navigation.cta_summer')}</span>
          </Link>
        </div>
      )}
      {/* {theme === Season.Winter && season === Season.Winter && (
        <div className="berg-components-navigation-cta">
          <Link to="/activities/tickets?prefill=today">
            <span>
              <Trans ns="components" i18nKey={'navigation.cta_winter'}>
                KUP KARNET <b>ONLINE</b>
              </Trans>
            </span>
          </Link>
        </div>
      )} */}
      {theme === Season.Summer && season === Season.Winter && (
        <div className="berg-components-navigation-cta-disabled">
          <Link to="">
            <span>
              <Tooltip content={t('tourist_attractions.season')}>
                <Trans ns="components" i18nKey={'navigation.cta_winter'}>
                  KUP KARNET <b>ONLINE</b>
                </Trans>
              </Tooltip>
            </span>
          </Link>
        </div>
      )}
    </>
  );
};

export default CtaButton;
