/* eslint-disable max-lines */
import { Trans, useTranslation } from 'react-i18next';
import { ICount, IPickedTickets } from './types';
import './Parking.scss';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  IConsumerCategory,
  IProduct,
} from '../../modules/common/models/productModels';
import Hooks from '../../hooks';
import {
  BasketItemType,
  IBasketItem,
  IParkingBasketItem,
} from '../../modules/basket/models';
import Pictures from '../../assets/picture';
import ParkingMediaData from './ParkingMediaData/ParkingMediaData';
import { getParkingDate } from '../../modules/parking/selectors';
import Components from '..';
import Tooltip from '../Tooltip/Tooltip';
import { IParking } from '../../modules/parking/models';
import { addToBasket } from '../../modules/basket/operations';
import { getParkingDefaults } from '../../modules/location/settings/selectors';
import { SeasonContext } from '../../context/SeasonContext';
import { Season } from '../../config/globalVariables';

interface IProps {
  parking: IParking;
}

const Parking = (props: IProps) => {
  const {
    parking: { name, description, consumerCategories, coverImageUrl },
  } = props;

  const [pickedCategory, setPickedCategory] = useState<
    IConsumerCategory | null | undefined
  >();
  const [quantity, setQuantity] = useState<number>(0);
  const totalPrice = +(pickedCategory?.price.grossAmount ?? 0) * quantity;
  const [consumerCategoriesCount, setConsumerCategoriesCount] = useState<
    ICount[]
  >([]);

  const { season } = useContext(SeasonContext);

  const defaults = Hooks.useAppSelector((s) => getParkingDefaults(s.settings));

  const { t } = useTranslation('components');

  const [pickedTickets, setPickedTickets] = useState<IPickedTickets[]>([]);
  const [licencePlates, setLicencePlates] = useState<string[]>([]);

  const currentCurrency = consumerCategories[0]?.price.currency;

  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  const { show, hide, Dialog } = Hooks.useDialog();

  const date = Hooks.useAppSelector((s) => getParkingDate(s.parking));

  const formRef = useRef<HTMLFormElement>(null);

  const typeDropdown = consumerCategories?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleCategoryChange = (value: string) => {
    // setPickedCategory(consumerCategories.find((cat) => cat.id === value));
    if (consumerCategories.length > 1) {
      setPickedCategory(consumerCategories.find((cat) => cat.id === value));
    } else {
      setPickedCategory(consumerCategories[0]);
    }
    setQuantity(0);
  };

  const dispatch = Hooks.useAppDispatch();

  const handleAddToBasket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const item: IBasketItem = {
      itemType: BasketItemType.Parking,
      price: pickedCategory?.price.grossAmount,
      quantity: licencePlates?.length,
      date,
      currency: currentCurrency,
      item: props.parking as IProduct,
      consumerCategoryId: pickedCategory?.id,
      pickupMethod: props.parking.defaultPickupMethod,
      licencePlates,
      priceBeforeRecalculation: 0,
      priceRecalculated: false,
    } as IParkingBasketItem;
    dispatch(addToBasket(item));
    e.currentTarget.reset();
    setPickedCategory(null);
    setQuantity(0);
    resetConsumerCategoriesCount();
  };

  const resetConsumerCategoriesCount = useCallback(
    (shouldPrefill?: boolean) => {
      setConsumerCategoriesCount(
        consumerCategories.map((cat, index) => {
          let count = 0;

          if (shouldPrefill && index === 0) {
            count = 1;
          }

          return {
            id: cat.id,
            name: cat.name,
            count,
            price: +cat.price.grossAmount,
          } as ICount;
        })
      );
    },
    [consumerCategories]
  );

  const onCountChange = (count: number) => {
    setConsumerCategoriesCount((prev: ICount[]) =>
      prev.map((prevCount: ICount) => ({ ...prevCount, count }))
    );
    if (count < -1) return;

    setQuantity(count);
  };

  useEffect(() => {
    resetConsumerCategoriesCount();
  }, [resetConsumerCategoriesCount]);

  useEffect(() => {
    const carrierInputs: IPickedTickets[] = [];
    const chosenVehicle = consumerCategoriesCount.find(
      (x) => x?.id === pickedCategory?.id
    );
    if (chosenVehicle)
      for (let i = 0; i < quantity; i++) {
        carrierInputs.push({
          id: chosenVehicle?.id,
          name: chosenVehicle?.name,
          uniqueId: `${chosenVehicle?.id}${i}`,
        });
      }

    setPickedTickets(carrierInputs);
  }, [consumerCategoriesCount]);

  useEffect(() => {
    setLicencePlates(pickedTickets.map((ticket) => ticket.dataCarrierId ?? ''));
  }, [pickedTickets]);

  return (
    <form
      ref={formRef}
      onSubmit={handleAddToBasket}
      className="berg-components-parking-wrapper"
    >
      <div className="berg-components-parking__picture">
        <img src={coverImageUrl} alt={t('parking.img_alt')} />
      </div>
      <div className="berg-components-parking__content">
        <div className="berg-components-parking__title">
          {name}
          <hr className="berg-components-parking__line" />
        </div>
        <div className="berg-components-parking__choices">{description}</div>

        <div className="berg-components-parking__choices">
          {consumerCategories.length > 1 ? (
            <div>
              <label>{t('parking.choice_of_ticket_type')}</label>
              <Components.Dropdown
                shouldReset={pickedCategory === null}
                name="parkingOptions"
                options={typeDropdown}
                onChange={handleCategoryChange}
                defaultValue={
                  consumerCategories
                    ? consumerCategories[0]?.id
                    : defaults?.defaultTypeId
                }
              />
            </div>
          ) : (
            <div style={{ display: 'none' }}>
              <label>{t('parking.choice_of_ticket_type')}</label>
              <Components.Dropdown
                shouldReset={pickedCategory === null}
                name="parkingOptions"
                options={typeDropdown}
                onChange={handleCategoryChange}
                defaultValue={
                  consumerCategories
                    ? consumerCategories[0]?.id
                    : defaults?.defaultTypeId
                }
              />
            </div>
          )}
          <div>
            <label>{t('parking.number_of_tickets')}</label>
            <Components.NumberCounter
              counter={quantity}
              onChange={onCountChange}
              disabled={!pickedCategory}
              maxCounter={99}
            />
          </div>
        </div>
        {quantity > 0 && (
          <div className="berg-components-parking__number-ID">
            {licencePlates.length > 0 &&
            licencePlates.some((x) => x.length > 1) ? (
              <>
                <img onClick={show} src={Pictures.AddedIdCar} alt="check" />
                <Trans
                  i18nKey="ticket.parking_check"
                  ns="components"
                  components={[<button key={0} type="button" onClick={show} />]}
                />
              </>
            ) : (
              <>
                <img
                  onClick={show}
                  src={
                    Season.Summer === season
                      ? Pictures.AddIdCarSummer
                      : Pictures.AddIdCar
                  }
                  alt="uncheck"
                />
                <Trans
                  i18nKey="ticket.parking"
                  ns="components"
                  components={[<button key={0} type="button" onClick={show} />]}
                />
              </>
            )}

            <Dialog>
              <ParkingMediaData
                tickets={pickedTickets}
                setPickedTickets={setPickedTickets}
                hide={hide}
              />
            </Dialog>
          </div>
        )}

        <div
          className="berg-components-parking__sum"
          aria-disabled={!pickedCategory}
        >
          {getPriceDisplay(totalPrice)}
        </div>
        {licencePlates.some((x) => x.length < 2) ||
        licencePlates?.length == 0 ? (
          <Tooltip content={t('parking.alert_tooltip')}>
            <button className="berg-components-parking__submit" disabled>
              {t('therms.submit')}
            </button>
          </Tooltip>
        ) : (
          <button className="berg-components-parking__submit">
            {t('parking.submit')}
          </button>
        )}
      </div>
    </form>
  );
};
export default Parking;
