import React from 'react';
import BergPageContentWrapper from '../../components/Storybook/BergPageContentWrapper/BergPageContentWrapper';
import QuickForm from './cart-data/QuickForm';
import Components from '../../components';
import { useTranslation } from 'react-i18next';
import useCartSteps from '../../hooks/useCartSteps';

const CartData = () => {
  const { t } = useTranslation('pages');

  const { steps, currentStep } = useCartSteps(1);

  return (
    <BergPageContentWrapper header={t('cart_data.page_header')}>
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      <QuickForm />
    </BergPageContentWrapper>
  );
};

export default CartData;
