/* eslint-disable complexity */
/* eslint-disable max-lines */
import BergPageContentWrapper from '../../../components/Storybook/BergPageContentWrapper/BergPageContentWrapper';
import { Trans, useTranslation } from 'react-i18next';
import Components from '../../../components';
import calculatePrice from '../../../utils/calculatePrice';
import moment from 'moment';
import { IColumn } from '../../../components/OrderTable/OrderTable';
import './CartSummary.scss';
import '../../payment/PaymentStatus.scss';
import CollapseList from '../../../components/CollapseList/CollapseList';
import { useNavigate } from 'react-router-dom';
import Hooks from '../../../hooks';
import {
  getBasketCompanyData,
  getBasketContactData,
  getBasketCurrency,
  getBasketItemsByType,
  getBasketTotal,
} from '../../../modules/basket/selectors';
import { BasketItemType } from '../../../modules/basket/models';
import useCartSteps from '../../../hooks/useCartSteps';
import { createOrder } from '../../../modules/order/operations';
import { useState } from 'react';
import Tooltip from '../../../components/Tooltip/Tooltip';
import formattedPrice from '../../../utils/formattedPrice';
import getClientsStyle from '../../../utils/getClientsStyle';
import {
  IProductAdditionalSettings,
  IProductComponents,
} from '../../../modules/common/models/productModels';
import formattedProductComponents from '../../../utils/formattedProductComponents';
import regulaminSprzedazyFlisacy from '../../../assets/regulations/Flisacy_Regulamin_sprzedaży.pdf';
import regulaminSprzedazyBrama from '../../../assets/regulations/brama-regulamin-sprzedazy.pdf';
import regulaminSprzedazyPotok from '../../../assets/regulations/GP_regulamin_sprzedaży.pdf';

const CartSummary = () => {
  const { t } = useTranslation(['pages', 'common', 'components']);
  const styleForClient = getClientsStyle();
  const navigate = useNavigate();
  const skiPasses = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiPass)
  );
  const accommodations = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  const parking = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Parking)
  );
  const aquapark = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Therms)
  );
  const attraction = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Attractions)
  );
  const basketTotal = Hooks.useAppSelector((x) => getBasketTotal(x.basket));
  const basketCurrency = Hooks.useAppSelector((x) =>
    getBasketCurrency(x.basket)
  );
  const formData = Hooks.useAppSelector((s) => getBasketContactData(s.basket));
  const formCompanyData = Hooks.useAppSelector((s) =>
    getBasketCompanyData(s.basket)
  );
  const { steps, currentStep } = useCartSteps(2);
  const dispatch = Hooks.useAppDispatch();
  const handleSubmit = () => {
    dispatch(createOrder())
      .unwrap()
      .then((result) => {
        if (result) {
          navigate(`/payment/status?OrderID=${result.id}`);
        }
      });
  };
  const buildType = process.env.REACT_APP_BUILD_TYPE;

  // const [language] = useState(i18n.resolvedLanguage);
  // const regulationsSales = (lang: string) => {
  //   switch (lang) {
  //     case 'en':
  //       return regulationSalesEn;
  //     case 'sk':
  //       return regulationSalesSk;
  //     default:
  //       return regulationSalesPl;
  //   }
  // };
  const [readRegulations, setReadRegulations] = useState<boolean>(false);
  const handleReadRegulations = () => {
    setReadRegulations((value) => !value);
  };
  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row.item.name}</span>
          {row.dataCarrierIds.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:accommodation.cart_table.name_header'),
      rows: (row) => row.item.name,
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_room'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.extra_bed'),
      rows: (row) => row.additionalGuestsCount || 0,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_header'),
      rows: (row) => row.daysCount,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        return (
          moment(row.startDate).format('DD.MM.YYYY') +
          ' - ' +
          moment(row.endDate).format('DD.MM.YYYY')
        );
      },
      thClassName: 'visible@l',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(
          row.price,
          row.daysCount,
          row.quantity,
          row.additionalGuestPrice,
          row.additionalGuestsCount
        )} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          {/* <span className="cart-summary-ticket-info">{row?.item?.name}</span> */}
          {row?.licencePlates?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const thermsTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row?.item?.consumerCategories
            .filter((category: any) => category?.id === row?.consumerCategoryId)
            .map((item: any) => item?.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row?.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.dataCarrierIds?.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row?.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row?.price} ${row?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.price, row?.quantity)} ${row?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const attractionTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row?.item?.consumerCategories
            .filter((category: any) => category?.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage
              items={row?.priceRecalculationMessage}
            />
          )}
          {row?.productComponents?.length > 0 &&
            row?.productComponents.some((product: IProductComponents) =>
              product?.productAdditionalSettings.some(
                (setting: IProductAdditionalSettings) => setting.value !== ''
              )
            ) && (
              <CollapseList
                buttonName={t('pages:cart_summary.skipass_additional_info')}
                items={formattedProductComponents(row)}
              />
            )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${formattedPrice(row.price)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => <div>{moment(row.date).format('DD.MM.YYYY')}</div>,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  return (
    <BergPageContentWrapper header={t('pages:cart_summary.header')}>
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      <Components.OrderContactData
        contactData={formData}
        companyData={formCompanyData}
      ></Components.OrderContactData>
      <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      {skiPasses?.length > 0 && (
        <>
          <p
            className="payment-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.passes')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiPasses}
            columns={skiPassTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </>
      )}
      {accommodations?.length > 0 && (
        <>
          <p
            className="payment-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.accommodation')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={accommodations}
            columns={accommodationTable}
          />
        </>
      )}

      {parking?.length > 0 && (
        <>
          <p
            className="payment-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.parking')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={parking}
            columns={parkingTable}
          />
        </>
      )}
      {aquapark?.length > 0 && (
        <>
          <p
            className="payment-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.parking')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={aquapark}
            columns={thermsTable}
          />
        </>
      )}
      {attraction?.length > 0 && (
        <>
          <p
            className="payment-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.attraction')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={attraction}
            columns={attractionTable}
          />
        </>
      )}
      {/* <Components.BergDevider color="ligth-grey"></Components.BergDevider> */}
      <div className="cart-summary-footer__desktop">
        <Components.BergStack
          direction="row"
          justifyContent="space-between"
          className="cart-summary-all-footer"
        >
          <Components.BergStack
            direction="column"
            spacing={8}
            // alignSelf="end"
            // mb={8}
            className="cart-summary-footer"
          >
            {buildType === 'FLISACY' && (
              <Components.BergCheckbox
                name="readRegulations"
                onValueChange={handleReadRegulations}
                label={
                  <Trans
                    ns="pages"
                    i18nKey="cart_summary.checkbox"
                    components={{
                      a: (
                        <a
                          href={regulaminSprzedazyFlisacy}
                          className="cart-summary-footer__link"
                          data-client-style={styleForClient}
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      ),
                    }}
                  ></Trans>
                }
              />
            )}
            {buildType === 'BRAMA' && (
              <Components.BergCheckbox
                name="readRegulations"
                onValueChange={handleReadRegulations}
                label={
                  <Trans
                    ns="pages"
                    i18nKey="cart_summary.checkbox"
                    components={{
                      a: (
                        <a
                          href={regulaminSprzedazyBrama}
                          className="cart-summary-footer__link"
                          data-client-style={styleForClient}
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      ),
                    }}
                  ></Trans>
                }
              />
            )}
            {buildType === 'POTOK' && (
              <Components.BergCheckbox
                name="readRegulations"
                onValueChange={handleReadRegulations}
                label={
                  <Trans
                    ns="pages"
                    i18nKey="cart_summary.checkbox"
                    components={{
                      a: (
                        <a
                          href={regulaminSprzedazyPotok}
                          className="cart-summary-footer__link"
                          data-client-style={styleForClient}
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      ),
                    }}
                  ></Trans>
                }
              />
            )}
            <Components.BergButton
              color="border"
              onClick={() => navigate('/cart/order-data')}
              label={t('pages:cart_data.go_to_ordering_data')}
            ></Components.BergButton>
          </Components.BergStack>
          <Components.BergStack
            direction="column"
            spacing={4}
            alignItems="end"
            className="cart-summary-footer"
          >
            <div className="cart-summary-footer__summary">
              <div className="order-summary-footer">
                <div className="order-summary-footer__item-header--bold">
                  {t('components:order_summary_header.amount_label')}
                </div>
                <div className="order-summary-footer__item-description--bold">
                  {basketTotal} {basketCurrency}
                </div>
              </div>
            </div>

            <Components.BergStack
              direction="column"
              spacing={4}
              justifyContent="flex-end"
              alignItems="end"
              alignSelf="end"
              className="cart-summary-footer__button"
            >
              {readRegulations ? (
                <Components.BergButton
                  color="full"
                  onClick={handleSubmit}
                  label={t('pages:cart_data.go_to_payment')}
                ></Components.BergButton>
              ) : (
                <Tooltip content={t('pages:cart_data.approval_regulations')}>
                  <Components.BergButton
                    color="gray-400"
                    onClick={handleSubmit}
                    label={t('pages:cart_data.go_to_payment')}
                    disabled={true}
                  ></Components.BergButton>
                </Tooltip>
              )}
              <img
                src={require('../../../assets/images/820x45-bankpay.png')}
                alt={t('pages:cart_data.go_to_ordering_data')}
                className="cart-summary-footer__img"
              />
            </Components.BergStack>
          </Components.BergStack>
        </Components.BergStack>
      </div>
      <div className="cart-summary-footer__mobile">
        <Components.BergStack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          {buildType === 'FLISACY' && (
            <Components.BergCheckbox
              name="readRegulations"
              onValueChange={handleReadRegulations}
              label={
                <Trans
                  ns="pages"
                  i18nKey="cart_summary.checkbox"
                  components={{
                    a: (
                      <a
                        href={regulaminSprzedazyFlisacy}
                        className="cart-summary-footer__link"
                        data-client-style={styleForClient}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    ),
                  }}
                ></Trans>
              }
            />
          )}
          {buildType === 'BRAMA' && (
            <Components.BergCheckbox
              name="readRegulations"
              onValueChange={handleReadRegulations}
              label={
                <Trans
                  ns="pages"
                  i18nKey="cart_summary.checkbox"
                  components={{
                    a: (
                      <a
                        href={regulaminSprzedazyBrama}
                        className="cart-summary-footer__link"
                        data-client-style={styleForClient}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    ),
                  }}
                ></Trans>
              }
            />
          )}
          {buildType === 'POTOK' && (
            <Components.BergCheckbox
              name="readRegulations"
              onValueChange={handleReadRegulations}
              label={
                <Trans
                  ns="pages"
                  i18nKey="cart_summary.checkbox"
                  components={{
                    a: (
                      <a
                        href={regulaminSprzedazyPotok}
                        className="cart-summary-footer__link"
                        data-client-style={styleForClient}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    ),
                  }}
                ></Trans>
              }
            />
          )}

          <div className="cart-summary-footer__summary">
            <div className="order-summary-footer">
              <div className="order-summary-footer__item-header--bold">
                {t('components:order_summary_header.amount_label')}
              </div>
              <div className="order-summary-footer__item-description--bold">
                {basketTotal} {basketCurrency}
              </div>
            </div>
          </div>
          {readRegulations ? (
            <Components.BergButton
              color="full"
              onClick={handleSubmit}
              label={t('pages:cart_data.go_to_payment')}
            ></Components.BergButton>
          ) : (
            <Tooltip content={t('pages:cart_data.approval_regulations')}>
              <Components.BergButton
                color="gray-400"
                onClick={handleSubmit}
                label={t('pages:cart_data.go_to_payment')}
                disabled={true}
              ></Components.BergButton>
            </Tooltip>
          )}

          <img
            src={require('../../../assets/images/485x115-bankpay.png')}
            alt={t('pages:cart_data.go_to_ordering_data')}
            className="cart-summary-footer__img"
          />

          <Components.BergButton
            color="full"
            onClick={() => navigate('/cart/order-data')}
            label={t('pages:cart_data.go_to_ordering_data')}
          ></Components.BergButton>
        </Components.BergStack>
      </div>
    </BergPageContentWrapper>
  );
};
export default CartSummary;
