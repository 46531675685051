import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductType } from '../common/models/productModels';
import { ITherms, IThermsStateModel } from './models';
import { fetchTherms, fetchThermsTypes } from './operations';

const initialState = {} as IThermsStateModel;

const thermsSlice = createSlice({
  name: 'ThermsTypes',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    resetPickedThermsPasses: (state) => {
      state.thermsPasses = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchThermsTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchThermsTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.thermsTypes = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchTherms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchTherms.fulfilled,
      (state, action: PayloadAction<ITherms[]>) => {
        state.thermsPasses = action.payload;
        state.loading = false;
      }
    );
  },
});

export const { setDate, resetPickedThermsPasses } = thermsSlice.actions;
export default thermsSlice.reducer;
