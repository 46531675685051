import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import {
  IAttractionsClient,
  IProductType,
} from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { setDate } from './slice';
import { IAttractions, IAttractionsResponse } from './models';

export const fetchProductTypes = createAsyncThunk<IProductType[]>(
  actionTypes.FETCH_PRODUCTS_TYPES,
  async (_, thunkApi): Promise<IProductType[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/products/types'
      );

      thunkApi.dispatch(hideLoader());
      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchProduct = createAsyncThunk(
  actionTypes.FETCH_PRODUCTS,
  async (
    params: { typeId: string; selectedAttractionIds: number[]; date: string },
    thunkApi
  ): Promise<IAttractions[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setDate(formattedDate));
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IAttractionsResponse>(
        '/v1/products',
        {
          params: {
            typeId: params.typeId,
            date: formattedDate,
            selectedAttractionIds: params.selectedAttractionIds,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchAttractionsClient = createAsyncThunk<IAttractionsClient[]>(
  actionTypes.FETCH_ATTRACTIONS_CLIENT,
  async (_, thunkApi): Promise<IAttractionsClient[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IAttractionsClient[]>(
        '/v1/attractions'
      );
      thunkApi.dispatch(hideLoader());
      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
