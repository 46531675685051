import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import Pictures from '../../assets/picture';

import './OutOfService.scss';

const OutOfService = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, 10000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }

  const close = () => {
    setShow(false);
  };

  return (
    <div className="berg-components-out-of-service">
      <img src={Pictures.OutOfService} alt="Out of service" />
      <p>
        <Trans
          ns="components"
          i18nKey="outOfService.text"
          components={{
            br: <br />,
            strong: <strong />,
          }}
        ></Trans>
      </p>
      <img src={Pictures.Close} alt="close" onClick={close}></img>
    </div>
  );
};

export default OutOfService;
