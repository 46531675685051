import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Pictures from '../../../assets/picture';
import Hooks from '../../../hooks';
import {
  BasketItemType,
  ISkiPassBasketItem,
} from '../../../modules/basket/models';
import { hidePopups } from '../../../modules/basket/slice';
import {
  IConsumerCategory,
  IProduct,
} from '../../../modules/common/models/productModels';
import { getValidityDate } from '../../../modules/skiPass/selectors';
import MediaData from '../MediaData/MediaData';
import { ICount, IPickedTickets, ITicket } from '../types';
import './Ticket.scss';
import TicketType from './TicketType';
import { getSkiPassDefaults } from '../../../modules/location/settings/selectors';
import { addToBasket } from '../../../modules/basket/operations';

interface IProps {
  ticket: ITicket;
}

const Ticket = (props: IProps) => {
  const {
    ticket: {
      isHighlighted,
      name,
      description,
      consumerCategories,
      coverImageUrl,
      shouldPrefill,
      offerSource,
    },
  } = props;

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [consumerCategoriesCount, setConsumerCategoriesCount] = useState<
    ICount[]
  >([]);

  const navigate = useNavigate();
  const { t } = useTranslation('components');
  const validityDate = Hooks.useAppSelector((s) => getValidityDate(s.skiPass));

  const defaults = Hooks.useAppSelector((s) => getSkiPassDefaults(s.settings));

  const [pickedTickets, setPickedTickets] = useState<IPickedTickets[]>([]);
  const [dataCarrierIds, setDataCarrierIds] = useState<string[]>([]);

  const currentCurrency = consumerCategories[0]?.price.currency;

  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  const { show, hide, Dialog } = Hooks.useDialog();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (consumerCategoriesCount.length) {
      setTotalPrice(
        consumerCategoriesCount
          .map((cat) => cat.count * cat.price)
          .reduce((acc, curr) => acc + curr)
      );
    }
  }, [consumerCategoriesCount]);

  const ticketClassName = [
    'berg-components-ticket',
    isHighlighted && 'highlighted-ticket',
  ].join(' ');

  const pictureClassName = [
    'berg-components-ticket-picture',
    isHighlighted && 'highlighted-ticket',
  ].join(' ');

  const dispatch = Hooks.useAppDispatch();

  const handleAddToBasket = async (e: React.SyntheticEvent) => {
    e.preventDefault();
  
    for (const x of consumerCategoriesCount) {
      if (x.count > 0) {
        const item = {
          itemType: BasketItemType.SkiPass,
          price: x.price,
          currency: currentCurrency,
          quantity: x.count,
          item: props.ticket as IProduct,
          consumerCategoryId: x.id,
          pickupMethod: props.ticket.defaultPickupMethod,
          validityDate,
          dataCarrierIds,
          priceBeforeRecalculation: 0,
          priceRecalculated: false,
        } as ISkiPassBasketItem;
  
        await dispatch(addToBasket(item));
      }
    }
    resetConsumerCategoriesCount();
  };

  const handleBuy = (e: React.SyntheticEvent) => {
    handleAddToBasket(e);
    dispatch(hidePopups());

    navigate('/cart');
  };

  const resetConsumerCategoriesCount = useCallback(
    (shouldPrefill?: boolean) => {
      const doesDefaultConsCatMatch = consumerCategories.some(
        (cat) => cat.id === defaults?.defaultConsumerCategoryId
      );

      setConsumerCategoriesCount(
        consumerCategories.map((cat, index) => {
          let count = 0;

          if (
            shouldPrefill &&
            ((doesDefaultConsCatMatch &&
              defaults?.defaultConsumerCategoryId === cat.id) ||
              index === 0)
          ) {
            count = 1;
          }

          return {
            id: cat.id,
            name: cat.name,
            count,
            price: +cat.price.grossAmount,
          } as ICount;
        })
      );
    },
    [consumerCategories, defaults?.defaultConsumerCategoryId]
  );

  const handleAddTickets = (args: { count: number; id: string }) => {
    const { count, id } = args;

    setConsumerCategoriesCount((prev: ICount[]) =>
      prev.map((prevCount: ICount) => {
        if (id === prevCount.id) return { ...prevCount, count };
        else return prevCount;
      })
    );
  };

  useEffect(() => {
    if (shouldPrefill) {
      resetConsumerCategoriesCount(true);
    } else {
      resetConsumerCategoriesCount();
    }
  }, [resetConsumerCategoriesCount, shouldPrefill]);

  useEffect(() => {
    const carrierInputs: IPickedTickets[] = [];

    consumerCategoriesCount.forEach((cat) => {
      for (let i = 0; i < cat.count; i++) {
        carrierInputs.push({
          id: cat.id,
          name: cat.name,
          uniqueId: `${cat.id}${i}`,
        });
      }
    });

    setPickedTickets(carrierInputs);
  }, [consumerCategoriesCount]);

  useEffect(() => {
    setDataCarrierIds(
      pickedTickets.map((ticket) => ticket.dataCarrierId ?? '').filter(Boolean)
    );
  }, [pickedTickets]);

  return (
    <div className="berg-components-ticket-wrapper">
      <form ref={formRef} className={ticketClassName}>
        <div className={pictureClassName}>
          <img src={coverImageUrl} alt="narciarz" />
        </div>
        <div className="berg-components-ticket-content">
          <div className="berg-components-ticket-title">{name}</div>
          <div className="berg-components-ticket-range">{description}</div>
          <div className="berg-components-ticket-types">
            {consumerCategories.map(
              (category: IConsumerCategory, index: number) => (
                <TicketType
                  key={category.id}
                  id={category.id}
                  count={consumerCategoriesCount[index]?.count}
                  onCountChange={handleAddTickets}
                  price={getPriceDisplay(+category.price.grossAmount)}
                  name={category.name}
                />
              )
            )}
          </div>
          {offerSource === 'skiData' && (
            <div
              className="berg-components-ticket-footnotes"
              aria-disabled={!pickedTickets.length}
            >
              <Trans
                i18nKey="ticket.footnotes"
                ns="components"
                components={[
                  <div key={0} />,
                  <button
                    key={1}
                    type="button"
                    onClick={show}
                    disabled={!pickedTickets.length}
                  />,
                ]}
              />
              {dataCarrierIds.length > 0 && (
                <img src={Pictures.Check} alt="check" />
              )}
              <Dialog>
                <MediaData
                  tickets={pickedTickets}
                  setPickedTickets={setPickedTickets}
                  hide={hide}
                />
              </Dialog>
            </div>
          )}

          <div className="berg-components-ticket-sum">
            {getPriceDisplay(totalPrice)}
            <div
              className="berg-components-ticket-sum__notes"
              aria-hidden={pickedTickets.length <= dataCarrierIds.length}
            >
              {t('ticket.carrier_id_warning')}
            </div>
          </div>
          <div className="berg-components-ticket-buttons">
            <button
              className="berg-components-ticket-submit"
              onClick={handleAddToBasket}
            >
              {t('ticket.submit')}
            </button>
            {shouldPrefill && (
              <button
                className="berg-components-ticket-buy"
                onClick={handleBuy}
              >
                {t('ticket.buy')}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Ticket;
