/* eslint-disable complexity */
import './ThanksForOrder.scss';
import { Trans } from 'react-i18next';
import { useEffect } from 'react';
import getClientsStyle from '../../utils/getClientsStyle';
import { useLogosAndLinks } from '../../utils/useLogoAndLinks';
import logoBramaPng from '../../assets/logo/logo_gorce.png';

const ThanksForOrder = ({ status }: { status: string }) => {
  const styleForClient = getClientsStyle();

  useEffect(() => {
    if (status !== 'paid') {
      setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
  }, [status]);

  const {
    logoSrcFlisacy,
    logoSrcPolana,
    logoSrcPotok,
    linkBrama,
    linkFlisacy,
    linkPolana,
    linkPotok,
  } = useLogosAndLinks();

  const buildType = process.env.REACT_APP_BUILD_TYPE;

  return (
    <section
      className="berg-components-thanks"
      data-client-style={styleForClient}
    >
      {buildType === 'BRAMA' && linkBrama && (
        <a href={linkBrama}>
          <img
            src={logoBramaPng}
            alt="Logo Brama W Gorce"
            className="berg-components-thanks__image"
          />
        </a>
      )}
      {buildType === 'FLISACY' && linkFlisacy && (
        <a href={linkFlisacy}>
          <img
            src={logoSrcFlisacy}
            alt="Logo Flisacy"
            className="berg-components-thanks__image"
          />
        </a>
      )}
      {buildType === 'POLANA' && linkPolana && (
        <a href={linkPolana}>
          <img
            src={logoSrcPolana}
            alt="Logo Długa Polana"
            className="berg-components-thanks__image"
          />
        </a>
      )}
      {buildType === 'POTOK' && linkPotok && (
        <a href={linkPotok}>
          <img
            src={logoSrcPotok}
            alt="Logo Goracy Potok"
            className="berg-components-thanks__image"
          />
        </a>
      )}

      {status === 'paid' ? (
        <Trans
          ns="components"
          i18nKey="thanks_for_order.description"
          components={{
            br: <br />,
            strong: <strong />,
            p: <p />,
          }}
        ></Trans>
      ) : status === 'pending' ? (
        <Trans
          ns="components"
          i18nKey="thanks_for_order.description_pending"
          components={{
            br: <br />,
            strong: <strong />,
            p: <p />,
          }}
        ></Trans>
      ) : status === 'notRequired' ? (
        <Trans
          ns="components"
          i18nKey="thanks_for_order.description_not_required"
          components={{
            br: <br />,
            strong: <strong />,
            p: <p />,
          }}
        ></Trans>
      ) : (
        <Trans
          ns="components"
          i18nKey="thanks_for_order.description_failed"
          components={{
            br: <br />,
            strong: <strong />,
            p: <p />,
          }}
        ></Trans>
      )}
    </section>
  );
};

export default ThanksForOrder;
