import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icons from '../../assets/icons';
import Hooks from '../../hooks';
import {
  getBasketCurrency,
  getBasketTotal,
  getBasketTotalQuantity,
} from '../../modules/basket/selectors';
import './BasketStickyCTA.scss';
import getClientsStyle from '../../utils/getClientsStyle';

const BasketStickyCTA = () => {
  const { t } = useTranslation('components');
  const styleForClient = getClientsStyle();

  const basketTotal = Hooks.useAppSelector((s) => getBasketTotal(s.basket));
  const basketCurrency = Hooks.useAppSelector((s) =>
    getBasketCurrency(s.basket)
  );
  const basketTotalQuantity = Hooks.useAppSelector((s) =>
    getBasketTotalQuantity(s.basket)
  );

  return (
    <Link to="/cart" className="berg-components-basket-sticky-cta-container">
      <button
        className="berg-components-basket-sticky-cta"
        data-client-style={styleForClient}
      >
        <div
          className="berg-components-basket-sticky-cta__icon"
          data-client-style={styleForClient}
        >
          <Icons.Basket />
          <div
            className="berg-components-basket-sticky-cta__quantity"
            data-client-style={styleForClient}
            aria-hidden={!basketTotalQuantity}
          >
            {basketTotalQuantity}
          </div>
        </div>
        <span className="berg-components-basket-sticky-cta__text">
          <strong className="berg-components-basket-sticky-cta__price">
            {basketTotal} {basketCurrency}
          </strong>
          {t('basket_sticky_cta')}
        </span>
      </button>
    </Link>
  );
};

export default BasketStickyCTA;
