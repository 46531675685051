import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../../components';
import Hooks from '../../hooks';
import { fetchAttractionsDetails } from '../../modules/location/details/operations';
import Calendar from './Calendar';
import { useSearchParams } from 'react-router-dom';
import { TicketPrefills } from '../../config/globalVariables';
import { getAttractionsDefaults } from '../../modules/location/settings/selectors';
import snackbar from '../../utils/snackbar';
import OutOfService from '../../components/OutOfService/OutOfService';
import visibleOnRelease from '../../config/visibleOnRelease';
import {
  getProductsTypes,
  getProductsEntries,
  getAttractionsClient,
} from '../../modules/attractions/selectors';
import {
  fetchProduct,
  fetchProductTypes,
  fetchAttractionsClient,
} from '../../modules/attractions/operations';
import { resetPickedAttractionsPasses } from '../../modules/attractions/slice';
import getClientsStyle from '../../utils/getClientsStyle';
import { IAttractionsClient } from '../../modules/common/models/productModels';
import '../../components/AttractionsTemplate/AttractionsTemplate.scss';
import Icons from '../../assets/icons';
import { FaSearchPlus } from 'react-icons/fa';
import largeFakeMap from '../../assets/images/pogladowa_mapa.jpg';
import smallFakeMap from '../../assets/images/pogladowa_mapa.jpg';

const Attractions = () => {
  const { t } = useTranslation(['pages', 'components']);
  const styleForClient = getClientsStyle();
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [date, setDate] = useState<string | null>(null);
  const idClients = Hooks.useAppSelector((s) =>
    getAttractionsClient(s.attractions)
  );
  const [selectedAttractionIds, setSelectedAttractionIds] = useState<number[]>(
    []
  );
  const typeId = Hooks.useAppSelector((s) => {
    const productsTypes = getProductsTypes(s?.attractions);
    return productsTypes?.length > 0 ? productsTypes[0]?.id : '';
  });

  const selectAllClients = () => {
    setSelectedAttractionIds(
      idClients?.map((attraction) => attraction.id) || []
    );
  };

  useEffect(() => {
    if (idClients && idClients.length > 0) {
      selectAllClients();
    }
  }, [idClients]);

  const dispatch = Hooks.useAppDispatch();
  const numberOfPersons = Hooks.useAppSelector((s) =>
    getProductsTypes(s.attractions)
  );

  const attractionsPasses = Hooks.useAppSelector((s) =>
    getProductsEntries(s.attractions)
  );

  const [params] = useSearchParams();
  const prefill = params.get('prefill') ?? TicketPrefills.Today;

  const defaults = Hooks.useAppSelector((s) =>
    getAttractionsDefaults(s.settings)
  );

  const attractionsPassesContent = useMemo(
    () =>
      attractionsPasses?.map((attraction) => ({
        ...attraction,
        isHighlighted: true,
      })),
    [attractionsPasses]
  );

  const typeDropdown = numberOfPersons?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const resetDate = () => {
    const now = moment();

    if (
      now.isBetween(
        moment(defaults?.maximumBuyingTimeForToday, 'hh:mm:ss'),
        moment('23:59:59', 'hh:mm:ss')
      )
    ) {
      setDate(now.add(1, 'days').format());
      snackbar({
        type: 'warning',
        msg: t('pages:activities.tickets.nextDayWarning'),
      });
    } else {
      setDate(moment().toISOString());
    }
  };

  // const tomorrowDate = () => {
  //   const now = moment();
  //   setDate(now.add(1, 'days').format());
  // };

  const onDateChange = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      dispatch(fetchProductTypes());
      dispatch(fetchAttractionsClient());
      dispatch(fetchAttractionsDetails());
    }
  }, [date, dispatch]);

  useEffect(() => {
    if (typeId && date && selectedAttractionIds?.length > 0) {
      dispatch(fetchProduct({ typeId, date, selectedAttractionIds }));
    }

    return () => {
      dispatch(resetPickedAttractionsPasses());
    };
  }, [selectedAttractionIds, dispatch, typeId, date]);

  useEffect(() => {
    const now = moment(defaults?.localDateTime);
    if (
      prefill === TicketPrefills.Today &&
      !!defaults?.maximumBuyingTimeForToday
    ) {
      if (
        now.isBefore(moment(defaults?.maximumBuyingTimeForToday, 'HH:mm:ss'))
      ) {
        setDate(now.format());
      } else {
        setDate(now.add(1, 'days').format());
        snackbar({
          type: 'warning',
          msg: t('pages:activities.tickets.nextDayWarning'),
        });
      }
    }
  }, [
    defaults?.localDateTime,
    defaults?.maximumBuyingTimeForToday,
    prefill,
    t,
  ]);

  const toggleClient = (clientId: number) => {
    const index = selectedAttractionIds.indexOf(clientId);
    if (index === -1) {
      setSelectedAttractionIds([...selectedAttractionIds, clientId]);
    } else {
      const newSelected = [...selectedAttractionIds];
      newSelected.splice(index, 1);
      setSelectedAttractionIds(newSelected);
    }
  };

  const toggleAllClient = () => {
    setSelectedAttractionIds((prevIds) => {
      if (prevIds?.length === idClients?.length) {
        return [];
      } else {
        return idClients?.map((attraction) => attraction?.id) || [];
      }
    });
  };

  return (
    <>
      <div className="berg-pages-activities__attractions">
        <Components.FilterBar
          // defaultDropdownOptionValue={
          //   prefill === TicketPrefills.Today
          //     ? defaults?.defaultTypeId
          //     : undefined
          // }
          defaultDropdownOptionValue={defaults?.defaultTypeId}
          typeDropdown={date ? typeDropdown : undefined}
          label={t('pages:activities.ticket_type')}
          Calendar={<Calendar date={date} onDateChange={onDateChange} />}
          // onTypeChange={handleTypeChange}
          date={date}
          resetDate={resetDate}
          // tomorrowDate={tomorrowDate}
        />
        <hr />
        {date && (
          <div className="flex flex-col gap-4 text-start berg-components-attractions-template__filter-button">
            <p>{t('components:attractions.filter')}</p>
            <div
              className="flex flex-row gap-2 flex-wrap sm:gap-6 items-center"
              data-client-style={styleForClient}
            >
              {idClients?.map((attraction: IAttractionsClient) => (
                <a
                  key={attraction.id}
                  onClick={() => toggleClient(attraction?.id)}
                  className={
                    selectedAttractionIds?.includes(attraction?.id)
                      ? 'button-active'
                      : 'button-inactive'
                  }
                  data-client-style={styleForClient}
                >
                  {/* Renderowanie ikon */}
                  {attraction.name === 'Gorący Potok' ? (
                    <Icons.PoolThermometer />
                  ) : attraction.name === 'Brama w Gorce' ? (
                    <Icons.Forest />
                  ) : attraction.name === 'Długa Polana' ? (
                    <Icons.SKiFilter />
                  ) : attraction.name === 'Flisacy' ? (
                    <Icons.Kayaking />
                  ) : (
                    ''
                  )}

                  {attraction.name}
                </a>
              ))}

              <a
                onClick={() => toggleAllClient()}
                className={`button-all ${
                  selectedAttractionIds?.length === 4
                    ? 'button-active'
                    : 'button-inactive'
                }`}
                data-client-style={styleForClient}
              >
                {t('components:attractions.filter_all')}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-10 mb-4 sm:mb-16">
        <div className="flex flex-col gap-4 sm:flex-row items-start sm:gap-[86px]">
          <div className="flex flex-col gap-4 items-start text-start sm:w-[60%]">
            <div className="flex flex-row gap-4 items-center ">
              <Icons.Ticket />
              <p className="text-[10px] leading-[16px] sm:text-[16px] sm:leading-[25.6px] ">
                {t('components:attractions.rules')}
              </p>
            </div>
            {/* <div className="flex flex-row gap-4 items-center">
              <Icons.CalendarToday />
              <p className="text-[10px] leading-[16px] sm:text-[16px] sm:leading-[25.6px]">
                {t('components:attractions.ticket_value')} 31.03.2024
              </p>
            </div> */}
            <div className="flex flex-row gap-4 items-center">
              <Icons.CalendarToday />
              <p className="text-[10px] leading-[16px] sm:text-[16px] sm:leading-[25.6px]">
                {t('components:attractions.rules_time')}
              </p>
            </div>
          </div>
          <div
            className="relative"
            onClick={() => setIsClicked(!isClicked)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isClicked ? largeFakeMap : smallFakeMap}
              alt="map"
              className="w-[200px] h-auto rounded-2xl overflow-hidden"
            />
            {isHovered && (
              <div className="absolute top-0 left-0 w-full h-full bg-[#00000080] flex justify-center items-center rounded-2xl cursor-pointer">
                <FaSearchPlus className="text-white mr-[5px] cursor-pointer" />
                <p className="text-white text-base cursor-pointer">
                  {t('components:attractions.zoom')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {isClicked && (
        <div className="berg-information-image-map-background ">
          <div className="berg-information-image-map-image">
            <button
              className="absolute top-5 right-5"
              onClick={() => setIsClicked(!isClicked)}
            >
              <Icons.ClosePopup />
            </button>
            <img className="w-full" src={largeFakeMap} alt="Duże zdjęcie" />
          </div>
        </div>
      )}
      {visibleOnRelease.outOfService && <OutOfService />}
      <Components.AttractionsTemplate attractions={attractionsPassesContent} />
    </>
  );
};

export default Attractions;
