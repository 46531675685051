import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Components from '..';
import Pictures from '../../assets/picture';
import { LocalStorageKeys } from '../../config/globalVariables';
import './Cookies.scss';

const Cookies = () => {
  const { t } = useTranslation('components');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const grantConsent = (bool: 'true' | 'false') => {
    localStorage.setItem(LocalStorageKeys.CookiesConsent, bool);
    setIsVisible(false);
  };

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.CookiesConsent) === null) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="berg-components-cookies">
      <img src={Pictures.Cookies} alt="cookies" />
      <p>
        <Trans ns="components" i18nKey="cookies.text">
          Our website use cookies.
          <a href="https://www.bramawgorce.com/polityka-prywatnosci">
            Privacy Policy
          </a>
          .
        </Trans>
      </p>
      <div className="berg-components-cookies__buttons">
        <Components.BergButton
          label={t('cookies.consent')}
          color="green"
          onClick={() => grantConsent('true')}
        />
        {/* <Components.BergButton
          label={t('cookies.decline')}
          color="secondary"
          onClick={() => grantConsent('false')}
        /> */}
      </div>
    </div>
  );
};

export default Cookies;
