import Icons from '../../assets/icons';
import './DiscountMessage.scss';

interface IProps {
  items: string;
}

const DiscountMessage = (props: IProps) => {
  const { items } = props;

  return (
    <div className="berg-components-discount-message">
      <Icons.AlertAttentionRed />
      <p>{items}</p>
    </div>
  );
};

export default DiscountMessage;
