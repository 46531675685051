import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import axiosInstance from '../../services/axios';
import { ICompanyDetails } from '../common/models/orderData';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { IOrder } from './models/details';
import { mapBasketToOrderRequest } from './orderMappers';
import { clearBasket } from '../basket/slice';
import i18n from '../../i18n';

export const createOrder = createAsyncThunk(
  actionTypes.CREATE_ORDER,
  async (promoCode: string | undefined, thunkApi): Promise<IOrder | null> => {
    thunkApi.dispatch(showLoader());
    const store = thunkApi.getState() as RootState;
    const orderRequest = mapBasketToOrderRequest(store.basket);
    let url = '/v1/order';
    if (promoCode) {
      url += `?promoCode=${promoCode}`;
    }
    try {
      const response = await axiosInstance.post<IOrder>(url, orderRequest);

      thunkApi.dispatch(hideLoader());
      console.log('Create order response', response.data);

      if (response.status !== 200) {
        console.error('Create order failed', response.status);
        return null;
        //TODO: handle error response
      }
      thunkApi.dispatch(clearBasket());
      if (response.data.onlinePayment.paymentRedirectUrl.length > 0) {
        window.location.replace(response.data.onlinePayment.paymentRedirectUrl);
      }

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));

      return null;
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
  actionTypes.GET_ORDER_DETAILS,
  async (orderId: string, thunkApi): Promise<IOrder> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get(`/v1/order/${orderId}`);

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));

      throw e;
    }
  }
);

export const getCompanyData = async (nip: string): Promise<ICompanyDetails> => {
  try {
    const response = await axiosInstance.get<ICompanyDetails>(
      '/v1/order/invoice-data',
      {
        params: { nip },
      }
    );

    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};
