import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { IProductResponse, IProductType } from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { ISkiRental } from './models';
import { setValidityDate } from './slice';

export const fetchSkiRentalTypes = createAsyncThunk<IProductType[]>(
  actionTypes.FETCH_SKI_RENTAL_TYPES,
  async (_, thunkApi): Promise<IProductType[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/ski-rental/types'
      );

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchSkiRentals = createAsyncThunk(
  actionTypes.FETCH_SKI_RENTALS,
  async (
    params: { typeId: string; date: string },
    thunkApi
  ): Promise<ISkiRental[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setValidityDate(formattedDate));
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductResponse<ISkiRental>>(
        '/v1/ski-rental',
        {
          params: {
            typeId: params.typeId,
            date: formattedDate,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data?.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
