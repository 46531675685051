import React, { useEffect, useState } from 'react';
import Hooks from '../../hooks';
import './SmallPopup.scss';
import Icons from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import { hidePopups } from '../../modules/basket/slice';
import getClientsStyle from '../../utils/getClientsStyle';

const SmallPopup = () => {
  const dispatch = Hooks.useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('common');
  const styleForClient = getClientsStyle();

  useEffect(() => {
    setIsVisible(true);

    const timeoutId = setTimeout(() => {
      setIsVisible(false);
      dispatch(hidePopups());
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="berg-small-popup" data-client-style={styleForClient}>
      <Icons.Check />
      <p>{t('product_added_to_basket')}</p>
    </div>
  );
};

export default SmallPopup;
