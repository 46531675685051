/* eslint-disable complexity */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tooltip from '../Tooltip/Tooltip';
import visibleOnRelease from '../../config/visibleOnRelease';

interface IProps {
  buttonText: string;
  linkHrefForBooking?: string;
  linkHrefForReadMore?: string;
  isRent: boolean;
  additionalButtonText: string;
  additionalLinkHref: string;
  isSeason?: boolean;
}

const Buttons = (props: IProps) => {
  const {
    buttonText,
    linkHrefForBooking,
    linkHrefForReadMore,
    isRent,
    additionalButtonText,
    additionalLinkHref,
    isSeason,
  } = props;

  const { t } = useTranslation('components');

  return (
    <div className="berg-components-tourist-attractions-buttons">
      {isRent && isSeason && (
        <Link to={additionalLinkHref}>
          <button className="berg-components-tourist-attractions-buttons__for-booking">
            {additionalButtonText}
          </button>
        </Link>
      )}
      {isRent && !isSeason && visibleOnRelease.rentalAndSchool && (
        <Tooltip content={t('tourist_attractions.season')}>
          <button className="berg-components-tourist-attractions-buttons__is-season">
            {additionalButtonText}
          </button>
        </Tooltip>
      )}
      {isRent && !isSeason && !visibleOnRelease.rentalAndSchool && (
        <Tooltip content={t('tourist_attractions.rentalAndSchool')}>
          <button className="berg-components-tourist-attractions-buttons__is-season">
            {additionalButtonText}
          </button>
        </Tooltip>
      )}

      {linkHrefForBooking && !isSeason && !isRent && (
        <Tooltip content={t('tourist_attractions.season')}>
          <button className="berg-components-tourist-attractions-buttons__is-season">
            {buttonText}
          </button>
        </Tooltip>
      )}

      {linkHrefForBooking && !isSeason && isRent && (
        <Tooltip content={t('tourist_attractions.rentalAndSchool')}>
          <button className="berg-components-tourist-attractions-buttons__is-season">
            {buttonText}
          </button>
        </Tooltip>
      )}

      {linkHrefForBooking && isSeason && (
        <Link to={linkHrefForBooking}>
          <button className="berg-components-tourist-attractions-buttons__for-booking">
            {buttonText}
          </button>
        </Link>
      )}
      {linkHrefForReadMore && (
        <Link to={linkHrefForReadMore}>
          <button className="berg-components-tourist-attractions-buttons__for-read-more">
            {t('tourist_attractions.button_more')}
          </button>
        </Link>
      )}
    </div>
  );
};

export default Buttons;
