import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../..';
import Hooks from '../../../hooks';
import {
  BasketItemType,
  ISkiRentalBasketItem,
} from '../../../modules/basket/models';
import { PickupMethod } from '../../../modules/common/models/enums';
import {
  IConsumerCategory,
  IProduct,
} from '../../../modules/common/models/productModels';
import { ISkiRental } from '../../../modules/skiRental/models';
import { getValidityDate } from '../../../modules/skiRental/selectors';
import Utils from '../../../utils';
import './Rental.scss';
import { addToBasket } from '../../../modules/basket/operations';

interface IProps {
  rental: ISkiRental;
}

const catDropdownName = 'skiRentalTime';

const Rental = (props: IProps) => {
  const {
    rental: {
      id,
      name,
      description,
      consumerCategories,
      coverImageUrl,
      additionalInformation,
    },
  } = props;

  const { t } = useTranslation('components');
  const validityDate = Hooks.useAppSelector((s) =>
    getValidityDate(s.skiRental)
  );
  const [pickedCategory, setPickedCategory] = useState<
    IConsumerCategory | null | undefined
  >();

  const price = pickedCategory?.price.grossAmount ?? 0;

  const currentCurrency = consumerCategories[0].price.currency;
  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  // TODO remove from component
  const dispatch = Hooks.useAppDispatch();

  const handleAddToBasket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const values = Utils.getFormData(e);

    const addInfo = additionalInformation.map((info) => ({
      type: info.type,
      name: info.name,
      unit: info.unit,
      value: values[info.type] as string,
    }));

    const item: ISkiRentalBasketItem = {
      id,
      itemType: BasketItemType.SkiRental,
      price: +price,
      quantity: 1,
      currency: currentCurrency,
      item: props.rental as IProduct,
      consumerCategoryId: values[catDropdownName] as string,
      additionalInformation: addInfo.filter((info) => !!info.value),
      pickupMethod: props.rental.defaultPickupMethod as PickupMethod,
      date: validityDate,
      priceBeforeRecalculation: 0,
      priceRecalculated: false,
    };

    dispatch(addToBasket(item));

    e.currentTarget.reset();

    setPickedCategory(null);
  };

  const handleCategoryChange = (value: string) => {
    setPickedCategory(consumerCategories.find((cat) => cat.id === value));
  };

  useEffect(() => {
    if (consumerCategories.length === 1) {
      setPickedCategory(consumerCategories[0]);
    }
  }, [consumerCategories]);

  const timeDropdown = consumerCategories?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  return (
    <form
      onSubmit={handleAddToBasket}
      className="berg-components-extras-list-wrapper"
    >
      <div className="berg-components-extras__list-picture">
        <img src={coverImageUrl} alt="sprzęt sportowy" />
      </div>
      <div className="berg-components-extras__list-content">
        <div className="berg-components-extras__list-title">
          {name}
          <hr className="berg-components-extras__list-line" />
        </div>
        <div className="berg-components-extras__list-choices">
          {consumerCategories.length > 1 && (
            <div>
              <label>{t('extra.number_of_hours')}</label>
              <Components.Dropdown
                shouldReset={pickedCategory === null}
                options={timeDropdown}
                name={catDropdownName}
                onChange={handleCategoryChange}
              />
            </div>
          )}
          {additionalInformation.map((t, index) => (
            <div key={index}>
              <label>{t.name}</label>
              <input
                name={t.type}
                className="berg-components-extras__input-dimensions"
                type="text"
                inputMode="numeric"
                required={t.required}
                placeholder={`0 ${t.unit ?? ''}`}
                disabled={!pickedCategory}
              />
            </div>
          ))}
        </div>
        <div
          className="berg-components-extras__list-sum"
          aria-disabled={!pickedCategory}
        >
          {getPriceDisplay(price)}
        </div>
        <button
          className="berg-components-extras__list-submit"
          disabled={!pickedCategory}
        >
          {t('extra.submit')}
        </button>
        <p className="berg-components-extras__list-info">{description}</p>
      </div>
    </form>
  );
};

export default Rental;
