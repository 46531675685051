import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../..';
import Hooks from '../../../hooks';
import {
  BasketItemType,
  IBasketItem,
  IThermsBasketItem,
} from '../../../modules/basket/models';
import { PickupMethod } from '../../../modules/common/models/enums';
import {
  IConsumerCategory,
  IProduct,
} from '../../../modules/common/models/productModels';
import { ITherms } from '../../../modules/therms/models';
import { getThermsDate } from '../../../modules/therms/selectors';
import './Therms.scss';
import { addToBasket } from '../../../modules/basket/operations';

interface IProps {
  therm: ITherms;
}

const Therms = (props: IProps) => {
  const {
    therm: { name, description, consumerCategories, coverImageUrl },
  } = props;

  const { t } = useTranslation('components');

  const [quantity, setQuantity] = useState<number>(0);
  const [pickedCategory, setPickedCategory] = useState<
    IConsumerCategory | null | undefined
  >();

  const totalPrice = +(pickedCategory?.price.grossAmount ?? 0) * quantity;

  const currentCurrency = consumerCategories[0].price.currency;

  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  const dispatch = Hooks.useAppDispatch();
  const date = Hooks.useAppSelector((s) => getThermsDate(s.therms));

  const handleAddToBasket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const item: IBasketItem = {
      itemType: BasketItemType.Therms,
      price: pickedCategory?.price.grossAmount,
      quantity,
      date,
      currency: currentCurrency,
      item: props.therm as IProduct,
      consumerCategoryId: pickedCategory?.id,
      pickupMethod: props.therm.defaultPickupMethod as PickupMethod,
      priceBeforeRecalculation: 0,
      priceRecalculated: false,
    } as IThermsBasketItem;
    dispatch(addToBasket(item));
    e.currentTarget.reset();
    setPickedCategory(null);
    setQuantity(0);
  };

  const typeDropdown = consumerCategories?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleCategoryChange = (value: string) => {
    setPickedCategory(consumerCategories.find((cat) => cat.id === value));
  };

  const onCountChange = (count: number) => {
    if (count < 1) return;

    setQuantity(count);
  };

  useEffect(() => {
    if (consumerCategories.length === 1) {
      setPickedCategory(consumerCategories[0]);
    }
  }, [consumerCategories]);

  useEffect(() => {
    if (pickedCategory) setQuantity(1);
  }, [pickedCategory]);

  return (
    <form
      onSubmit={handleAddToBasket}
      className="berg-components-therms-wrapper"
    >
      <div className="berg-components-therms__picture">
        <img src={coverImageUrl} alt={t('therms.img_alt')} />
      </div>
      <div className="berg-components-therms__content">
        <div className="berg-components-therms__title">
          {name}
          <hr className="berg-components-therms__line" />
        </div>

        <div className="berg-components-therms__choices">
          <div>
            <label>{t('therms.choice_of_ticket_type')}</label>
            <Components.Dropdown
              shouldReset={pickedCategory === null}
              name="thermsOptions"
              options={typeDropdown}
              onChange={handleCategoryChange}
            />
          </div>
          <div>
            <label>{t('therms.number_of_tickets')}</label>
            <Components.NumberCounter
              counter={quantity}
              onChange={onCountChange}
              disabled={!pickedCategory}
              maxCounter={99}
            />
          </div>
        </div>
        <hr className="berg-components-therms__line" />
        <div
          className="berg-components-therms__sum"
          aria-disabled={!pickedCategory}
        >
          {getPriceDisplay(totalPrice)}
        </div>
        <button
          className="berg-components-therms__submit"
          disabled={!pickedCategory}
        >
          {t('therms.submit')}
        </button>
        <div className="berg-components-therms__info">
          <p>{description}</p>
        </div>
      </div>
    </form>
  );
};

export default Therms;
