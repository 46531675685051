import moment from 'moment';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../../components';
import Hooks from '../../hooks';
import { fetchThermsPassDetails } from '../../modules/location/details/operations';
import { fetchTherms, fetchThermsTypes } from '../../modules/therms/operations';
import {
  getThermsEntries,
  getThermsTypes,
} from '../../modules/therms/selectors';
import { resetPickedThermsPasses } from '../../modules/therms/slice';
import Calendar from './Calendar';
import { useSearchParams } from 'react-router-dom';
import { Season, TicketPrefills } from '../../config/globalVariables';
import { getAquaparkDefaults } from '../../modules/location/settings/selectors';
import snackbar from '../../utils/snackbar';
import Icons from '../../assets/icons';
import { SeasonContext } from '../../context/SeasonContext';
import OutOfService from '../../components/OutOfService/OutOfService';
import visibleOnRelease from '../../config/visibleOnRelease';

const Aquapark = () => {
  const { t } = useTranslation('pages');
  const { season } = useContext(SeasonContext);

  const [date, setDate] = useState<string | null>(null);
  const [typeId, setTypeId] = useState<string>();

  const dispatch = Hooks.useAppDispatch();
  const numberOfPersons = Hooks.useAppSelector((s) => getThermsTypes(s.therms));
  const thermsPasses = Hooks.useAppSelector((s) => getThermsEntries(s.therms));
  const [params] = useSearchParams();
  const prefill = params.get('prefill');

  const defaults = Hooks.useAppSelector((s) => getAquaparkDefaults(s.settings));

  const thermsPassesContent = useMemo(
    () =>
      thermsPasses?.map((ticket) => ({
        ...ticket,
        isHighlighted: true,
      })),
    [thermsPasses]
  );

  const typeDropdown = numberOfPersons?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleTypeChange = (id: string) => {
    setTypeId(id);
  };

  const resetDate = () => {
    const now = moment();

    if (
      now.isBetween(
        moment(defaults?.maximumBuyingTimeForToday, 'hh:mm:ss'),
        moment('23:59:59', 'hh:mm:ss')
      )
    ) {
      setDate(now.add(1, 'days').format());
      snackbar({
        type: 'warning',
        msg: t('activities.tickets.nextDayWarning'),
      });
    } else {
      setDate(moment().toISOString());
    }
  };

  const tomorrowDate = () => {
    const now = moment();
    setDate(now.add(1, 'days').format());
  };

  const onDateChange = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      dispatch(fetchThermsTypes());
      dispatch(fetchThermsPassDetails());
    }
  }, [date, dispatch]);

  useEffect(() => {
    if (date && typeId) {
      dispatch(fetchTherms({ typeId, date }));
    }

    return () => {
      dispatch(resetPickedThermsPasses());
    };
  }, [date, dispatch, typeId]);

  useEffect(() => {
    const now = moment(defaults?.localDateTime);
    if (
      prefill === TicketPrefills.Today &&
      !!defaults?.maximumBuyingTimeForToday
    ) {
      if (
        now.isBefore(moment(defaults?.maximumBuyingTimeForToday, 'HH:mm:ss'))
      ) {
        setDate(now.format());
      } else {
        setDate(now.add(1, 'days').format());
        snackbar({
          type: 'warning',
          msg: t('activities.tickets.nextDayWarning'),
        });
      }
    }
  }, [
    defaults?.localDateTime,
    defaults?.maximumBuyingTimeForToday,
    prefill,
    t,
  ]);

  return (
    <>
      <Components.Portal id="header">
        <div className="berg-pages-activities__header--therms">
          <h1>{t('activities.therms')}</h1>
        </div>
      </Components.Portal>

      <div className="berg-pages-activities__aquapark">
        <h2 className="berg-pages-activities-rental-header">
          {t('activities.therms')}
        </h2>
        <hr />
        <Components.FilterBar
          // defaultDropdownOptionValue={
          //   prefill === TicketPrefills.Today
          //     ? defaults?.defaultTypeId
          //     : undefined
          // }
          defaultDropdownOptionValue={defaults?.defaultTypeId}
          typeDropdown={date ? typeDropdown : undefined}
          label={t('activities.ticket_type')}
          Calendar={<Calendar date={date} onDateChange={onDateChange} />}
          onTypeChange={handleTypeChange}
          date={date}
          resetDate={resetDate}
          tomorrowDate={tomorrowDate}
        />
        <hr />
      </div>

      <div className="berg-pages-activities__parking--aquapark">
        {Season.Winter === season ? <Icons.Star /> : <Icons.StarPerry />}
        <p>{t('activities.parking_info_promo')}</p>
      </div>
      <p className="berg-pages-activities__combining-promo">
        {t('activities.parking_info_combining_promo')}
      </p>
      {visibleOnRelease.outOfService && <OutOfService />}
      <Components.ThermsTemplate therms={thermsPassesContent} />
    </>
  );
};

export default Aquapark;
