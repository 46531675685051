import Icons from '../../assets/icons';
import getClientsStyle from '../../utils/getClientsStyle';
import './Tooltip.scss';

interface IProps {
  content?: string | null;
  children?: JSX.Element;
}

const Tooltip = (props: IProps) => {
  const { content, children } = props;
  const styleForClient = getClientsStyle();

  if (['null', 'undefined'].includes(typeof content) || content === '') {
    return children ?? null;
  }

  return (
    <div className="berg-components-tooltip" data-client-style={styleForClient}>
      <div
        className="t-top"
        data-tooltip={content}
        data-client-style={styleForClient}
      >
        {children ?? <Icons.InfoCircle />}
      </div>
    </div>
  );
};

export default Tooltip;
