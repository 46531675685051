const formattedPrice = (price: string | number) => {
  let priceToSetup;

  if (typeof price === 'string') {
    priceToSetup = parseFloat(price).toFixed(2);
  } else if (typeof price === 'number') {
    priceToSetup = price.toFixed(2);
  } else {
    console.error('Incorrect price format');
    return;
  }

  const [totalPrice, partPrice] = priceToSetup.split('.');
  const truncatedPrice = partPrice === '00' ? totalPrice : priceToSetup;

  return truncatedPrice;
};

export default formattedPrice;
