import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { IProductType } from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { ITherms, IThermsResponse } from './models';
import { setDate } from './slice';

export const fetchThermsTypes = createAsyncThunk<IProductType[]>(
  actionTypes.FETCH_THERMS_TYPES,
  async (_, thunkApi): Promise<IProductType[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/therms/types'
      );

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchTherms = createAsyncThunk(
  actionTypes.FETCH_THERMS,
  async (
    params: { typeId: string; date: string },
    thunkApi
  ): Promise<ITherms[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setDate(formattedDate));
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IThermsResponse>('/v1/therms', {
        params: {
          typeId: params.typeId,
          date: formattedDate,
        },
      });

      thunkApi.dispatch(hideLoader());

      return response.data.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
