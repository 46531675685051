import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAttractionsClient,
  IProductType,
} from '../common/models/productModels';
import { IAttractionsStateModel, IAttractions } from './models';
import {
  fetchProductTypes,
  fetchProduct,
  fetchAttractionsClient,
} from './operations';

const initialState = {} as IAttractionsStateModel;

const attractionsSlice = createSlice({
  name: 'AttractionsTypes',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    resetPickedAttractionsPasses: (state) => {
      state.productsPasses = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProductTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchProductTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.productsTypes = action.payload;
        state.loading = false;
      }
    );

    builder.addCase(fetchProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchProduct.fulfilled,
      (state, action: PayloadAction<IAttractions[]>) => {
        state.productsPasses = action.payload;
        state.loading = false;
      }
    );

    builder.addCase(fetchAttractionsClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAttractionsClient.fulfilled,
      (state, action: PayloadAction<IAttractionsClient[]>) => {
        state.attractionsClient = action.payload;
        state.loading = false;
      }
    );
  },
});

export const { setDate, resetPickedAttractionsPasses } =
  attractionsSlice.actions;
export default attractionsSlice.reducer;
