import moment from 'moment';
import { useEffect, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Components from '../../../components';
import { Season, TicketPrefills } from '../../../config/globalVariables';
import Hooks from '../../../hooks';
import { fetchSkiPassDetails } from '../../../modules/location/details/operations';
import { getSkiPassDetails } from '../../../modules/location/details/selectors';
import {
  fetchSkiPasses,
  fetchSkiPassTypes,
} from '../../../modules/skiPass/operations';
import {
  getSkiPasses,
  getSkiPassTypes,
} from '../../../modules/skiPass/selectors';
import { resetPickedSkiPass } from '../../../modules/skiPass/slice';
import snackbar from '../../../utils/snackbar';
import Calendar from './Calendar';
import './PassesAndTickets.scss';
import SeasonTabs from './SeasonTabs';
import { getSkiPassDefaults } from '../../../modules/location/settings/selectors';
import { SeasonContext } from '../../../context/SeasonContext';
import Icons from '../../../assets/icons';
import visibleOnRelease from '../../../config/visibleOnRelease';

const PassesAndTickets = () => {
  const { t } = useTranslation('pages');
  const { theme } = useContext(SeasonContext);

  const [params] = useSearchParams();
  const prefill = params.get('prefill');

  const [date, setDate] = useState<string | null>(null);
  const [typeId, setTypeId] = useState<string>();

  const dispatch = Hooks.useAppDispatch();
  const skiPassTypes = Hooks.useAppSelector((s) => getSkiPassTypes(s.skiPass));
  const skiPasses = Hooks.useAppSelector((s) => getSkiPasses(s.skiPass));
  const skiPassDetails = Hooks.useAppSelector((s) =>
    getSkiPassDetails(s.location)
  );
  const defaults = Hooks.useAppSelector((s) => getSkiPassDefaults(s.settings));

  const skiPassesContent = useMemo(
    () =>
      skiPasses?.map((ticket, index) => ({
        ...ticket,
        isHighlighted: true,
        shouldPrefill:
          index === 0 ? prefill === TicketPrefills.Today : undefined,
      })),
    [prefill, skiPasses]
  );

  const typeDropdown = skiPassTypes?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleTypeChange = (id: string) => {
    setTypeId(id);
  };

  const resetDate = () => {
    setDate(moment().toISOString());
  };

  const tomorrowDate = () => {
    const now = moment();
    setDate(now.add(1, 'days').format());
  };

  const onDateChange = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      if (!skiPassTypes) {
        dispatch(fetchSkiPassTypes({ date }));
      }
    }
    if (!skiPassDetails) {
      dispatch(fetchSkiPassDetails());
    }
  }, [date, dispatch, skiPassDetails, skiPassTypes]);

  useEffect(() => {
    if (date && typeId) {
      dispatch(fetchSkiPasses({ typeId, date }));
    }

    return () => {
      dispatch(resetPickedSkiPass());
    };
  }, [date, dispatch, typeId]);

  useEffect(() => {
    const now = moment(defaults?.localDateTime);

    if (
      prefill === TicketPrefills.Today &&
      !!defaults?.maximumBuyingTimeForToday
    ) {
      if (
        now.isBefore(moment(defaults?.maximumBuyingTimeForToday, 'HH:mm:ss'))
      ) {
        setDate(now.format());
      } else {
        setDate(now.add(1, 'days').format());
        snackbar({
          type: 'warning',
          msg: t('activities.tickets.nextDayWarning'),
        });
      }
    }
  }, [
    defaults?.localDateTime,
    defaults?.maximumBuyingTimeForToday,
    prefill,
    t,
  ]);

  return (
    <div className="berg-pages-passes-and-tickets">
      <Components.Portal id="header">
        {Season.Winter === theme ? (
          <div className="berg-pages-activities__header--winter-lift">
            <h1>{t('activities.tickets.title')}</h1>
          </div>
        ) : (
          <div className="berg-pages-activities__header--summer-lift">
            <h1>{t('activities.tickets.summer_title')}</h1>
          </div>
        )}
      </Components.Portal>
      <div className="berg-pages-passes-and-tickets__search">
        {Season.Winter === theme ? (
          <h3>{t('activities.tickets.title')}</h3>
        ) : (
          <h3>{t('activities.tickets.summer_title')}</h3>
        )}
        <hr />
        <Components.FilterBar
          // defaultDropdownOptionValue={
          //   prefill === TicketPrefills.Today
          //     ? defaults?.defaultTypeId
          //     : undefined
          // }
          defaultDropdownOptionValue={
            skiPassTypes ? skiPassTypes[0]?.id : defaults?.defaultTypeId
          }
          typeDropdown={date ? typeDropdown : undefined}
          label={t('activities.type_of_pass')}
          Calendar={<Calendar date={date} onDateChange={onDateChange} />}
          onTypeChange={handleTypeChange}
          date={date}
          resetDate={resetDate}
          tomorrowDate={tomorrowDate}
        />
        <hr />
      </div>
      {Season.Winter === theme && (
        <SeasonTabs
          tabs={skiPassDetails?.seasonPeriods}
          date={date}
          isVisible={skiPassDetails && skiPassesContent?.length > 0}
        />
      )}
      {visibleOnRelease.parking && (
        <div className="berg-pages-activities__parking--aquapark">
          {Season.Winter === theme ? <Icons.Star /> : <Icons.StarPerry />}
          <p>{t('activities.parking_info_promo')}</p>
        </div>
      )}

      <Components.PassesAndTickets tickets={skiPassesContent} />
    </div>
  );
};

export default PassesAndTickets;
