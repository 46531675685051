import Footer from './Footer/Footer';
import Meta from './Meta/Meta';
import Navigation from './Navigation/Navigation';
import Search from './Search/Search';
import Alerts from './Alerts/Alerts';

import ContactDetails from './ContactDetails/ContactDetails';

import StickyCTA from './StickyCTA/StickyCTA';
import ContactForm from './ContactForm/ContactForm';
import TouristAttraction from './TouristAttractions/TouristAttraction';
import SkiSlope from './SkiSlope/SkiSlope';
import PoolDescription from './PoolDescription/PoolDescription';
import Dropdown from './Dropdown/Dropdown';
import BasketStickyCTA from './BasketStickyCTA/BasketStickyCTA';
import RoomCard from './RoomCard/RoomCard';
import Ticket from './PassesAndTickets/Ticket/Ticket';
import PassesAndTickets from './PassesAndTickets/PassesAndTickets';
import ExtrasListTemplate from './ExtrasListTemplate/ExtrasListTemplate';
import NumberCounter from './NumberCounter/NumberCounter';
import RoomDetails from './RoomDetails/RoomDetails';
import OptionsSwitch from './OptionsSwitch/OptionsSwitch';
import SeasonSelector from './SeasonSelector/SeasonSelector';
import Dialog from './Dialog/Dialog';
import Spinner from './Spinner/Spinner';
import RangeCalendar from './RangeCalendar/RangeCalendar';
import Tooltip from './Tooltip/Tooltip';

import Portal from './Portal/Portal';
import Tabs from './Tabs/Tabs';

import ChoiceNumberPersons from './ChoiceNumber/ChoiceNumberPersons';
import Loader from './Loader/Loader';
import SingleCalendar from './SingleCalendar/SingleCalendar';
import ChoiceNumberChildren from './ChoiceNumber/ChoiceNumberChildren';
import FilterBar from './FilterBar/FilterBar';
import SchoolTemplate from './SchoolTemplate/SchoolTemplate';
import MenuTemplate from './MenuTemplate/MenuTemplate';
import School from './SchoolTemplate/School/School';
import BergPageContentWrapper from './Storybook/BergPageContentWrapper/BergPageContentWrapper';
import BergButton from './Storybook/BergButton/BergButton';
import BergCheckbox from './Storybook/BergCheckbox/BergCheckbox';
import BergStack from './Storybook/BergStack/BergStack';
import BergTextInput from './Storybook/BergTextinput/BergTextInput';
import BergDevider from './Storybook/BergDevider/BergDevider';
import BergGridWrapper from './Storybook/BergGrid/BergGridWrapper';
import OrderSummaryHeader from './Order/OrderSummaryHeader/OrderSummaryHeader';
import ThermsTemplate from './ThermsTemplate/ThermsTemplate';
import ThanksForOrder from './ThanksForOrder/ThanksForOrder';
import DialogCartPopups from './Dialog/DialogCartPopups/DialogCartPopups';
import PopupControl from './Dialog/PopupControl';
import StepsAxis from './StepsAxis/StepsAxis';
import OrderTable from './OrderTable/OrderTable';
import OrderSummaryFooter from './Order/OrderSummaryFooter/OrderSummaryFooter';
import OrderContactData from './Order/OrderContactData/OrderContactData';
import RoomFullCard from './RoomFullCard/RoomFullCard';
import CollapseList from './CollapseList/CollapseList';
import Pagination from './Pagination/Pagination';
import ParkingTemplate from './Parking/ParkingTemplate';
import DiscountMessage from './DiscountMessage/DiscountMessage';
import OutOfService from './OutOfService/OutOfService';
import LanguageDropdown from './LanguageDropdown/LanguageDropdown';
import AttractionsTemplate from './AttractionsTemplate/AttractionsTemplate';
import Weather from './Navigation/Weather/Weather';
import SmallPopup from './SmallDialog/SmallPopup';

const Components = {
  Search,
  Footer,
  Meta,
  Navigation,
  Alerts,

  StickyCTA,
  ContactForm,
  TouristAttraction,
  ContactDetails,
  SkiSlope,
  PoolDescription,
  Dropdown,
  BasketStickyCTA,
  RoomCard,
  PassesAndTickets,
  Ticket,
  ExtrasListTemplate,
  NumberCounter,
  RoomDetails,
  OptionsSwitch,
  SeasonSelector,
  Dialog,
  Spinner,
  RangeCalendar,
  SingleCalendar,
  Tooltip,

  Portal,
  Tabs,

  ChoiceNumberPersons,
  Loader,
  ChoiceNumberChildren,
  FilterBar,
  SchoolTemplate,
  MenuTemplate,
  School,
  BergPageContentWrapper,
  BergButton,
  BergCheckbox,
  BergStack,
  BergTextInput,
  BergDevider,
  BergGridWrapper,
  OrderSummaryHeader,
  ThermsTemplate,
  ThanksForOrder,
  DialogCartPopups,
  PopupControl,
  StepsAxis,
  OrderTable,
  OrderSummaryFooter,
  OrderContactData,
  RoomFullCard,

  CollapseList,
  Pagination,
  ParkingTemplate,
  DiscountMessage,
  OutOfService,
  LanguageDropdown,
  AttractionsTemplate,
  Weather,
  SmallPopup,
};

export default Components;
