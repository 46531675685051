const getClientsStyle = (): string => {
  if (process.env.REACT_APP_STYLE_BRAMA) {
    return process.env.REACT_APP_STYLE_BRAMA;
  } else if (process.env.REACT_APP_STYLE_FLISACY) {
    return process.env.REACT_APP_STYLE_FLISACY;
  } else if (process.env.REACT_APP_STYLE_POTOK) {
    return process.env.REACT_APP_STYLE_POTOK;
  } else if (process.env.REACT_APP_STYLE_POLANA) {
    return process.env.REACT_APP_STYLE_POLANA;
  }

  return '';
};

export default getClientsStyle;
