import { useState, useEffect } from 'react';

export const useLogosAndLinks = () => {
  const linkBrama = process.env.REACT_APP_LINK_BRAMA;
  const linkFlisacy = process.env.REACT_APP_LINK_FLISACY;
  const linkPolana = process.env.REACT_APP_LINK_POLANA;
  const linkPotok = process.env.REACT_APP_LINK_POTOK;

  const [logoSrcFlisacy, setLogoSrcFlisacy] = useState('');
  const [logoSrcBrama, setLogoSrcBrama] = useState('');
  const [logoSrcPolana, setLogoSrcPolana] = useState('');
  const [logoSrcPotok, setLogoSrcPotok] = useState('');

  useEffect(() => {
    const loadLogoFlisacy = async () => {
      if (process.env.REACT_APP_LOGO_FLISACY) {
        try {
          const LogoModuleFlisacy = await import(
            `${process.env.REACT_APP_LOGO_FLISACY}`
          );
          const logoSrcFlisacy = LogoModuleFlisacy.default;
          setLogoSrcFlisacy(logoSrcFlisacy);
        } catch (error) {
          console.error(`Error loading Flisacy logo: ${error}`);
        }
      }
    };

    const loadLogoBrama = async () => {
      if (process.env.REACT_APP_LOGO_BRAMA) {
        try {
          const LogoModuleBrama = await import(
            `${process.env.REACT_APP_LOGO_BRAMA}`
          );
          const logoSrcBrama = LogoModuleBrama.default;
          setLogoSrcBrama(logoSrcBrama);
        } catch (error) {
          console.error(`Error loading Brama logo: ${error}`);
        }
      }
    };

    const loadLogoPolana = async () => {
      if (process.env.REACT_APP_LOGO_POLANA) {
        try {
          const LogoModulePolana = await import(
            `${process.env.REACT_APP_LOGO_POLANA}`
          );
          const logoSrcPolana = LogoModulePolana.default;
          setLogoSrcPolana(logoSrcPolana);
        } catch (error) {
          console.error(`Error loading Polana logo: ${error}`);
        }
      }
    };

    const loadLogoPotok = async () => {
      if (process.env.REACT_APP_LOGO_POTOK) {
        try {
          const LogoModulePotok = await import(
            `${process.env.REACT_APP_LOGO_POTOK}`
          );
          const logoSrcPotok = LogoModulePotok.default;
          setLogoSrcPotok(logoSrcPotok);
        } catch (error) {
          console.error(`Error loading Potok logo: ${error}`);
        }
      }
    };

    loadLogoFlisacy();
    loadLogoBrama();
    loadLogoPolana();
    loadLogoPotok();
  }, []);

  return {
    logoSrcFlisacy,
    logoSrcBrama,
    logoSrcPolana,
    logoSrcPotok,
    linkBrama,
    linkFlisacy,
    linkPolana,
    linkPotok,
  };
};
