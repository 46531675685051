import { memo } from 'react';
import './StepsAxis.scss';
import getClientsStyle from '../../utils/getClientsStyle';

interface IStepProps {
  id: number;
  currentStep: number;
  title: string;
  onClick?: () => void;
}

interface IStepsAxisProps {
  currentStep: number;
  steps: { title: string; onClick?: () => void }[];
}

const areStepsAxisPropsEqual = (prev: any, next: any) =>
  prev.currentSep === next.currentStep;

const Step = (props: IStepProps) => {
  const { id, currentStep, title, onClick } = props;
  const styleForClient = getClientsStyle();

  const className = ['steps-axis-step'];

  if (currentStep === id) {
    className.push('steps-axis-step--active');
  }

  if (id > currentStep) {
    className.push('steps-axis-step--muted');
  }

  return (
    <div
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      className={className.join(' ')}
      data-client-style={styleForClient}
    >
      <div
        className="steps-axis-step__number"
        data-client-style={styleForClient}
      >
        {id + 1}
      </div>
      <div className="steps-axis-step__title">{title}</div>
    </div>
  );
};

const StepsAxis = (props: IStepsAxisProps) => {
  const { steps, currentStep } = props;

  const stepsRender = [];

  for (let i = 0; i < steps.length; i++) {
    stepsRender.push(
      <Step
        id={i}
        key={steps[i].title}
        title={steps[i].title}
        currentStep={currentStep}
        onClick={steps[i].onClick}
      />
    );

    if (i < steps.length - 1) {
      stepsRender.push(
        <span key={i} className="berg-components-steps-axis__separator" />
      );
    }
  }

  return <div className="berg-components-steps-axis">{stepsRender}</div>;
};

export default memo(StepsAxis, areStepsAxisPropsEqual);
