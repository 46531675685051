import { CookieNames } from '../../config/globalVariables';
import { useCookies } from 'react-cookie';
import useAppSelector from '../../hooks/useAppSelector';
import { getBasketItemsByType } from '../../modules/basket/selectors';
import { BasketItemType } from '../../modules/basket/models';
import moment from 'moment';

export const usePromoCodeIndicator = () => {
  const [cookies] = useCookies([CookieNames.PromoCode]);
  const accommodations = useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  return (): boolean => {
    return (
      cookies[CookieNames.PromoCode] &&
      accommodations.some((x) =>
        moment(x.startDate).isBefore(moment('2023-12-16'))
      )
    );
  };
};
