import { ILocationDetailsModel } from './models/locationDetails';

export const getSkiPassDetails = (state: ILocationDetailsModel) =>
  state.skiPass;

export const getSkiSchoolPassDetails = (state: ILocationDetailsModel) =>
  state.skiSchool;

export const getThermsDetails = (state: ILocationDetailsModel) => state.therms;

export const getSkiRentalDetails = (state: ILocationDetailsModel) =>
  state.skiRental;

export const getRestaurantDetails = (state: ILocationDetailsModel) =>
  state.restaurant;
