import React from 'react';
import './RoomFullCard.scss';
import { useTranslation } from 'react-i18next';
import { IAccommodation } from '../../modules/accommodation/models';
import Hooks from '../../hooks';
import Icon from '../RoomCard/Icon';

import Carousel from '../ArrowsCarousel/ArrowsCarousel';
import RoomPriceSummary from './RoomPriceSummary';
import BergDevider from '../Storybook/BergDevider/BergDevider';
import Components from '..';

interface IProps {
  daysCount: number;
  adultCount: number;
  childrenCount: number;
  startDate: string;
  endDate: string;
  accommodation: IAccommodation;
  onSubmitClick: (accommodation: IAccommodation) => void;
  quantityRoom: number;
  // quantitySupply: number;
  onCountChangeRoom: (count: number) => void;
  // onCountChangeSupply: (count: number) => void;
}

const RoomFullCard = (props: IProps) => {
  const {
    daysCount,
    adultCount,
    childrenCount,
    startDate,
    endDate,
    accommodation,
    onSubmitClick,
    quantityRoom,
    // quantitySupply,
    onCountChangeRoom,
    // onCountChangeSupply,
  } = props;

  const { t } = useTranslation('components');

  const { show, Dialog } = Hooks.useDialog({
    buttonClassName: 'berg-components-room-full-card__gallery-close-btn',
    dialogClassName: 'berg-components-room-full-card__dialog',
  });

  const convertDate = (str: string) => str.split('-').reverse().join('-');

  return (
    <section className="berg-components-room-full-card">
      <div className="berg-components-room-full-card__header">
        {accommodation.name}
      </div>
      <div className="berg-components-room-full-card__card">
        <div className="berg-components-room-full-card__images" onClick={show}>
          {accommodation.images.slice(0, 3).map((image, index) => (
            <img key={index} src={image}></img>
          ))}
        </div>
        <div className="berg-components-room-full-card__images-mobile">
          <Carousel>
            {accommodation.images.map((image, index) => (
              <img key={index} src={image}></img>
            ))}
          </Carousel>
        </div>
        <div className="berg-components-room-full-card__description--small">
          <RoomPriceSummary
            accommodation={accommodation}
            daysCount={daysCount}
            onSubmitClick={onSubmitClick}
            quantityRoom={quantityRoom}
            // quantitySupply={quantitySupply}
            onCountChangeRoom={onCountChangeRoom}
            // onCountChangeSupply={onCountChangeSupply}
          />
        </div>
        <div className="berg-components-room-full-card__stay--small">
          <div className="berg-components-room-full-card__stay--price">
            <div>
              <p>{t('room_full_card.check_in')}</p>
              <p>{convertDate(startDate)}</p>
            </div>
            <div>
              <p>{t('room_full_card.check_out')}</p>
              <p>{convertDate(endDate)}</p>
            </div>
            <div>
              <p>{t('room_full_card.count_night')}</p>
              <p>{daysCount}</p>
            </div>
            <div>
              <p>{t('room_full_card.number_of_people')}</p>
              <p>
                {t('room_full_card.adult', { count: adultCount })},{' '}
                {t('room_full_card.child', { count: childrenCount })}
              </p>
            </div>
          </div>
        </div>
        <div className="berg-components-room-full-card__devider">
          <BergDevider color="lighter-grey"></BergDevider>
        </div>
        <div className="berg-components-room-full-card__features-and-price">
          <div className="berg-components-room-full-card__features">
            {accommodation.features.map(({ icon, name }, index) => (
              <div key={index}>
                <Icon icon={icon} />
                <span>{name}</span>
                <hr></hr>
              </div>
            ))}
          </div>
        </div>
        <div className="berg-components-room-full-card__devider">
          <BergDevider color="lighter-grey"></BergDevider>
        </div>
        <div className="berg-components-room-full-card__stay">
          <div className="berg-components-room-full-card__stay--price">
            <div>
              <p>{t('room_full_card.check_in')}</p>
              <p>{convertDate(startDate)}</p>
            </div>
            <div>
              <p>{t('room_full_card.check_out')}</p>
              <p>{convertDate(endDate)}</p>
            </div>
            <div>
              <p>{t('room_full_card.count_night')}</p>
              <p>{daysCount}</p>
            </div>
            <div>
              <p>{t('room_full_card.number_of_people')}</p>
              <p>
                {t('room_full_card.adult', { count: adultCount })},{' '}
                {t('room_full_card.child', { count: childrenCount })}
              </p>
            </div>
          </div>
        </div>
        <div className="berg-components-room-full-card__description">
          <div
            className="berg-components-room-full-card__description--text"
            dangerouslySetInnerHTML={{ __html: accommodation.description }}
          ></div>
          <div className="berg-components-room-full-card__description--big">
            <RoomPriceSummary
              accommodation={accommodation}
              daysCount={daysCount}
              onSubmitClick={onSubmitClick}
              quantityRoom={quantityRoom}
              // quantitySupply={quantitySupply}
              onCountChangeRoom={onCountChangeRoom}
              // onCountChangeSupply={onCountChangeSupply}
            />
          </div>
        </div>

        <div className="berg-components-room-full-card__submit">
          <BergDevider color="lighter-grey"></BergDevider>
          <Components.BergButton
            label={t('room_full_card.submit')}
            color="primary-dark"
            onClick={() => onSubmitClick(accommodation)}
          />
        </div>
      </div>
      <Dialog>
        <div className="berg-components-room-full-card__gallery">
          <Carousel>
            {accommodation.images.map((image, index) => (
              <img key={index} src={image}></img>
            ))}
          </Carousel>
        </div>
      </Dialog>
    </section>
  );
};

export default RoomFullCard;
