import { IProductType } from '../common/models/productModels';
import { ITherms, IThermsStateModel } from './models';

export const getThermsTypes = (
  state: IThermsStateModel
): IProductType[] => state.thermsTypes;

export const getThermsEntries = (
  state: IThermsStateModel
): ITherms[] => state.thermsPasses;

export const getThermsDate = (state: IThermsStateModel): string =>
  state.date;
