import { useTranslation } from 'react-i18next';
// import Components from '../..';
import { IOrder } from '../../../modules/order/models/details';
import './OrderSummaryFooter.scss';
interface IProps {
  orderDetails: IOrder;
}
const OrderSummaryFooter = ({ orderDetails }: IProps) => {
  const { t } = useTranslation('components');

  const onlinePrice = Number(orderDetails?.onlinePayment.grossAmount);
  const skiRentalPrice =
    orderDetails?.skiRentalOrder?.items
      .map((i) => Number(i.orderItemPrice?.grossAmount))
      .reduce((acc, product) => acc + Number(product)) ?? 0;
  const skiSchoolPrice =
    orderDetails?.skiSchoolOrder?.items
      .map((i) => Number(i.orderItemPrice.grossAmount))
      .reduce((acc, product) => acc + Number(product)) ?? 0;

  const sumRentalAndSchool = skiRentalPrice + skiSchoolPrice;

  return (
    <div>
      <div className="order-summary-footer">
        <div className="order-summary-footer__item-header--bold">
          {t('order_summary_header.amount_label')}
        </div>
        <div className="order-summary-footer__item-description--bold">
          {onlinePrice + sumRentalAndSchool}{' '}
          {orderDetails?.onlinePayment.currency}
        </div>
      </div>
      {/* <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      <div className="order-summary-footer">
        <div className="order-summary-footer__item-header--price-on-Site">
          {t('order_summary_header.amount_label_offline')}
        </div>
        <div className="order-summary-footer__item-description">
          {sumRentalAndSchool} {orderDetails?.onlinePayment.currency}
        </div>
      </div>
      <div className="order-summary-footer">
        <div className="order-summary-footer__item-header--price-online">
          {t('order_summary_header.amount_label_online')}
        </div>
        <div className="order-summary-footer__item-description--price-online">
          {onlinePrice} {orderDetails?.onlinePayment.currency}
        </div>
      </div> */}
    </div>
  );
};

export default OrderSummaryFooter;
