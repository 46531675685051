import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductType } from '../common/models/productModels';
import { ISkiRental, ISkiRentalStateModel } from './models';
import { fetchSkiRentals, fetchSkiRentalTypes } from './operations';

const initialState = {} as ISkiRentalStateModel;

const skiRentalSlice = createSlice({
  name: 'SkiRental',
  initialState,
  reducers: {
    setValidityDate: (state, action: PayloadAction<string>) => {
      state.validityDate = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSkiRentalTypes.fulfilled,
      (state, action: PayloadAction<IProductType[]>) => {
        state.skiRentalTypes = action.payload;
      }
    );
    builder.addCase(
      fetchSkiRentals.fulfilled,
      (state, action: PayloadAction<ISkiRental[]>) => {
        state.skiRentals = action.payload;
      }
    );
  },
});

export const { setValidityDate } = skiRentalSlice.actions;
export default skiRentalSlice.reducer;
