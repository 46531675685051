import moment from 'moment';
import { ISeasonPeriod } from '../../../modules/location/details/models/locationDetails';
import isWithinDatesRange from '../../../utils/isWithinDatesRange';
import { useTranslation } from 'react-i18next';

interface IProps {
  tabs: ISeasonPeriod[];
  date: string | null;
  isVisible: boolean;
}

const SeasonTabs = (props: IProps) => {
  const { tabs, isVisible, date } = props;

  const { t } = useTranslation('pages');

  if (!isVisible) {
    return null;
  }

  const getDateDisplay = (month: number, day: number) => {
    const momentDate = moment({ month: month - 1, day });
    const monthName = momentDate.format('MMMM');
    const polishMonths = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
    const isPolishMonth = polishMonths.includes(monthName);
  
    return `${momentDate.format('DD')} ${isPolishMonth ? monthName : monthName.charAt(0).toUpperCase() + monthName.slice(1)}`;
  };

  const formattedTabs = tabs?.map((tab) => {
    const description = `${getDateDisplay(
      tab.from.month,
      tab.from.day
    )} - ${getDateDisplay(tab.to.month, tab.to.day)}`;

    const isWithinRange = isWithinDatesRange(tab.from, tab.to, moment(date));
    const translationKey = tab.seasonType === 'top' ? 
      t('activities.tickets.calendar_legend_1') : 
      (tab.seasonType === 'high' ? t('activities.tickets.calendar_legend_2') : 
      t('activities.tickets.calendar_legend_4'));

    return {
      title: translationKey,
      description,
      isWithinRange,
      priority: tab.priority,
    };
  });

  const highestPriorityTabWithinRange = formattedTabs
    .filter((tab) => tab.isWithinRange)
    .reduce((maxPriorityTab, currentTab) => {
      if (currentTab.priority > maxPriorityTab.priority) {
        return currentTab;
      } else {
        return maxPriorityTab;
      }
    });

  const tabsWithPickedCurrentSeason = formattedTabs.map((tab) => {
    const isActive = tab.title === highestPriorityTabWithinRange.title;

    return { title: tab.title, description: tab.description, isActive };
  });

  return (
    <div className="seasons-tabs">
      {tabsWithPickedCurrentSeason.map((tab, index) => {
        const className = `season-tab${
          tab.isActive ? ' season-tab--active' : ''
        }`;

        return (
          <div key={index} className={className}>
            <div className="season-tab__title">{tab.title}</div>
            <div className="season-tab__description">{tab.description}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SeasonTabs;
