import { PickupMethod } from '../common/models/enums';
import { CompanyData, ContactData } from '../common/models/orderData';
import { IProduct, IProductComponents } from '../common/models/productModels';

export enum BasketItemType {
  SkiPass = 'SkiPass',
  SkiRental = 'SkiRental',
  SkiSchool = 'SkiSchool',
  Accommodation = 'Accommodation',
  Therms = 'Therms',
  Parking = 'Parking',
  Attractions = 'Attractions',
}

export interface IBasketStateModel {
  totalPrice: number;
  totalPriceAtLocation: number;
  items: IBasketItem[];
  companyData?: CompanyData;
  contactData?: ContactData;
  basketCurrency: string;
  showPopups: boolean;
  lastItemId: string;
}

export interface IBasketAdditionalInformation {
  type: string;
  value: string;
  name: string;
  unit: string;
}

export interface IBasketItem {
  id: string;
  quantity: number;
  price: number;
  currency: string;
  consumerCategoryId: string;
  itemType: BasketItemType;
  item: IProduct;
  additionalInformation: IBasketAdditionalInformation[];
  pickupMethod?: PickupMethod;
  validityDate?: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  daysCount?: number;
  additionalGuestsCount?: number;
  additionalGuestPrice?: number;
  licencePlates?: string[];
  childrenAges?: number[];
  adultCount?: number;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
  name?: string;
  productComponents?: IProductComponents[];
  selectedHour?: string;
}

export interface ISkiPassBasketItem extends IBasketItem {
  dataCarrierIds: string[];
  validityDate: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface ISkiRentalBasketItem extends IBasketItem {
  date: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface ISkiSchoolBasketItem extends IBasketItem {
  date: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface IThermsBasketItem extends IBasketItem {
  date: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface IParkingBasketItem extends IBasketItem {
  date: string;
  licencePlates?: string[];
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface IAccommodationBasketItem extends IBasketItem {
  childrenAges: number[];
  adultCount: number;
  startDate: string;
  endDate: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
}

export interface IAttractionsBasketItem extends IBasketItem {
  date: string;
  priceBeforeRecalculation: number;
  priceRecalculated: boolean;
  name: string;
  productComponents: IProductComponents[];
}

export interface IQuantityOperation {
  basketId: string;
  changeValueBy: number;
}
