import Moon from './moon.svg';
import SkierOnLift from './skier_on_lift.svg';
import SkiGear from './ski_gear.svg';
import Pool from './pool.svg';
import Bike from './bike.svg';
import LogoColor from './logo_meander_color.svg';
import LogoWhite from './logo_meander_white.svg';
import SkiAndSkier from './ski_and_skier.svg';
import DownhillSkier from './downhill_skier.svg';
import Close from './close.svg';
import HeaderHandle from './header-handle.svg';
import Check from './check.svg';
import Error from './error.svg';
import Spinner from './spinner.svg';
import Cookies from './cookies.svg';
import WinterSuccess from './winter_success.svg';
import SummerSuccess from './summer_success.svg';
import RoomPlaceholder from './room-placeholder.svg';
import SummerLift from './summer-lift.svg';
import Parking from './parking.svg';
import AddIdCar from './add_id_car.svg';
import AddIdCarSummer from './add_id_car_summer.svg';
import AddedIdCar from './added_id_car.svg';
import OutOfService from './outOfService.svg';
import LogoGorce from './logo_gorce.svg';
import Box from './box.svg';

const Pictures = {
  Moon,
  DownhillSkier,
  SkierOnLift,
  SkiGear,
  Pool,
  Bike,
  LogoColor,
  LogoWhite,
  SkiAndSkier,
  Close,
  HeaderHandle,
  Check,
  Error,
  Spinner,
  Cookies,
  WinterSuccess,
  SummerSuccess,
  RoomPlaceholder,
  SummerLift,
  Parking,
  AddIdCar,
  AddIdCarSummer,
  AddedIdCar,
  OutOfService,
  LogoGorce,
  Box,
} as const;

export type TPictures = keyof typeof Pictures;

export default Pictures;
