import { IProductType } from '../common/models/productModels';
import { ISkiRental, ISkiRentalStateModel } from './models';

export const getSkiRentalTypes = (
  state: ISkiRentalStateModel
): IProductType[] => state.skiRentalTypes;

export const getSkiRentals = (state: ISkiRentalStateModel): ISkiRental[] =>
  state.skiRentals;

export const getValidityDate = (state: ISkiRentalStateModel): string =>
  state.validityDate;
