/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Components from '../../components';
import { IColumn } from '../../components/OrderTable/OrderTable';
import Hooks from '../../hooks';
import useCartSteps from '../../hooks/useCartSteps';
import { fetchOrderDetails } from '../../modules/order/operations';
import { getOrderDetails } from '../../modules/order/selectors';
import './PaymentStatus.scss';
import '../../pages/cart/cart-summary/CartSummary.scss';
import CollapseList from '../../components/CollapseList/CollapseList';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';
import formattedPrice from '../../utils/formattedPrice';
import getClientsStyle from '../../utils/getClientsStyle';
import { IProductComponents } from '../../modules/common/models/productModels';
// import formattedProductComponents from '../../utils/formattedProductComponents';

const PaymentStatus = () => {
  const { t } = useTranslation(['components', 'pages', 'common']);
  const styleForClient = getClientsStyle();
  const dispatch = Hooks.useAppDispatch();
  const orderDetails = Hooks.useAppSelector((s) => getOrderDetails(s.order));
  const queryParams = Hooks.useQueryParams();
  const navigate = useNavigate();
  const orderId = queryParams.get('OrderID');
  const [status, setStatus] = useState(() => {
    const storedStatus = localStorage.getItem('paymentStatus');
    return storedStatus || '';
  });

  const { steps, currentStep } = useCartSteps(4);

  useEffect(() => {
    if (orderDetails) {
      const newStatus = orderDetails.onlinePayment.status;
      setStatus(newStatus);
      localStorage.setItem('paymentStatus', newStatus);
    }
    if (orderId) {
      dispatch(fetchOrderDetails(orderId));
    }
  }, [dispatch, orderId, orderDetails?.onlinePayment.status]);

  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row?.consumerCategoryName}
          <span className="cart-summary-ticket-info">{row?.productName}</span>
          {row.dataCarrierIds.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row?.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row?.orderItemPrice?.grossAmount} ${row?.orderItemPrice?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const skiRentalTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row?.productName}
          {row?.additionalInformation?.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.ski_rental_additional_info')}
              items={row?.additionalInformation.map(
                (info: any) =>
                  `${info?.name}: ${info?.value}${
                    info?.unit === null ? '' : info?.unit
                  }`
              )}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row?.orderItemPrice?.grossAmount} ${row?.orderItemPrice?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.data).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const thermsTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => row?.productName,
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row?.orderItemPrice?.grossAmount} ${row?.orderItemPrice?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.data).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const skiSchoolTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => row?.productName,
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row?.orderItemPrice?.grossAmount} ${row?.orderItemPrice?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.data).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row?.consumerCategoryName}
          <span className="cart-summary-ticket-info">{row?.productName}</span>
        </div>
      ),
      tdClassName: 'full@sm w25@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_room'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_adult'),
      rows: (row) => row.adultCount,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_child'),
      rows: (row) => row.childrenAges?.length || 0,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number_of_nights'),
      rows: (row) => moment(row?.endDate).diff(row?.startDate, 'days'),
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        const startDate = moment(row.startDate);
        const endDate = moment(row.endDate);

        if (startDate.isSame(endDate, 'month')) {
          return `${+startDate.format('DD')}-${+endDate.format(
            'DD'
          )}.${endDate.format('MM.YYYY')}`;
        } else if (startDate.isSame(endDate, 'year')) {
          return `${+startDate.format('DD')}.${startDate.format(
            'MM'
          )}-${+endDate.format('DD')}.${endDate.format('MM.YYYY')}`;
        } else {
          return `${+startDate.format('DD')}.${startDate.format(
            'MM.YYYY'
          )}-${+endDate.format('DD')}.${endDate.format('MM.YYYY')}`;
        }
      },
      thClassName: 'visible@l width-date',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l sum',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row?.consumerCategoryName}{' '}
          <span className="cart-summary-ticket-info">{row?.productName}</span>
          {row?.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.licencePlates?.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row?.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row?.orderItemPrice?.grossAmount} ${row?.orderItemPrice?.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row?.data).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) => (
        <div>
          {row?.orderItemPrice?.priceRecalculated ? (
            <div className="berg-table-discount">
              {calculatePrice(row?.price, row?.quantity)} {row?.currency}{' '}
              <span>
                {calculatePrice(
                  row?.orderItemPrice?.grossAmount,
                  row?.quantity
                )}{' '}
                {row?.currency}
              </span>
            </div>
          ) : (
            `${calculatePrice(
              row?.orderItemPrice?.grossAmount,
              row?.quantity
            )} ${row?.orderItemPrice?.currency}`
          )}
        </div>
      ),

      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];
  console.log(orderDetails?.attractionsOrder);

  const attractionsTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row?.consumerCategoryName}{' '}
          <span className="cart-summary-ticket-info">{row?.productName}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.orderItemComponent?.some((product: IProductComponents) =>
            product?.productComponentAdditionalSetting?.some(
              (setting) =>
                setting.value !== undefined && setting.value.trim() !== ''
            )
          ) && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row?.orderItemComponent
                .filter(
                  (product: IProductComponents) =>
                    product?.productComponentAdditionalSetting &&
                    product?.productComponentAdditionalSetting[0]?.value
                )
                .map((product: IProductComponents) => {
                  const productName = product?.name;
                  const value =
                    (product?.productComponentAdditionalSetting &&
                      product?.productComponentAdditionalSetting[0]?.value) ||
                    '';

                  return `${productName} - ${value}`;
                })}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${formattedPrice(row?.orderItemPrice?.grossAmount)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row?.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => <div>{moment(row.date).format('DD.MM.YYYY')}</div>,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row?.orderItemPrice?.grossAmount, row?.quantity)} ${
          row?.orderItemPrice?.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w25@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  return (
    <Components.BergPageContentWrapper
      header={t('pages:order_confirmation.payment_header')}
    >
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      <div className="payment-status-wrapper">
        <Components.BergStack direction="column">
          <Components.OrderSummaryHeader
            orderDetails={orderDetails}
          ></Components.OrderSummaryHeader>
          <Components.BergStack
            direction="row"
            className="payment-status-wrapper__payment-box"
            alignItems="center"
          >
            <div className="payment-status-wrapper__item-header">
              {t('components:order_summary_header.payment_status_label')}
            </div>
            <div
              className="payment-status-wrapper__payment-status"
              data-client-style={styleForClient}
            >
              {orderDetails
                ? t(
                    `components:payment_status.${orderDetails?.onlinePayment.status}`
                  )
                : null}
            </div>
          </Components.BergStack>
          <div className="payment-status-wrapper__thank-you-message">
            {status === 'paid' &&
              t('pages:order_confirmation.thank_you_message')}
          </div>
          <Components.ThanksForOrder status={status} />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          <Components.OrderContactData
            contactData={orderDetails?.contactData}
            companyData={orderDetails?.companyData}
          ></Components.OrderContactData>
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          {orderDetails?.skiPassOrder?.items.length > 0 && (
            <div className="skiPasses-table-status">
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.passes')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.skiPassOrder.items}
                columns={skiPassTable}
              />
            </div>
          )}
          {orderDetails?.skiRentalOrder?.items.length > 0 && (
            <>
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.rentals')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.skiRentalOrder.items}
                columns={skiRentalTable}
              />
              <Components.BergDevider color="ligth-grey"></Components.BergDevider>
            </>
          )}
          {orderDetails?.thermsOrder?.items.length > 0 && (
            <div className="therms-table-status">
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.therms')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.thermsOrder.items}
                columns={thermsTable}
              />
            </div>
          )}
          {orderDetails?.skiSchoolOrder?.items.length > 0 && (
            <>
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.schools')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.skiSchoolOrder.items}
                columns={skiSchoolTable}
              />
            </>
          )}
          {orderDetails?.accommodationOrder?.items.length > 0 && (
            <div className="accommodations-table">
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.accommodation')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.accommodationOrder.items}
                columns={accommodationTable}
              />
            </div>
          )}

          {orderDetails?.parkingOrder?.items.length > 0 && (
            <div className="parking-table-status">
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.parking')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.parkingOrder.items}
                columns={parkingTable}
              />
            </div>
          )}

          {orderDetails?.attractionsOrder?.items.length > 0 && (
            <div className="parking-table-status">
              <p
                className="payment-status-label"
                data-client-style={styleForClient}
              >
                {t('common:table_label.attraction')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.attractionsOrder.items}
                columns={attractionsTable}
              />
            </div>
          )}

          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          <Components.BergStack
            direction="row"
            justifyContent="space-between"
            className="payment-status-wrapper__buttons"
          >
            <Components.BergButton
              label={t('components:payment_status.back_to_main_page')}
              color="full"
              onClick={() => navigate(`/?prefill=today`)}
            ></Components.BergButton>
            <Components.OrderSummaryFooter orderDetails={orderDetails} />
          </Components.BergStack>
        </Components.BergStack>
      </div>
    </Components.BergPageContentWrapper>
  );
};

export default PaymentStatus;
