import { IProductType } from '../common/models/productModels';
import { ISkiSchool, ISkiSchoolStateModel } from './models';

export const getSkiSchoolTypes = (
  state: ISkiSchoolStateModel
): IProductType[] => state.skiSchoolTypes;

export const getSkiSchoolEntries = (
  state: ISkiSchoolStateModel
): ISkiSchool[] => state.schoolPasses;

export const getSkiSchoolDate = (state: ISkiSchoolStateModel): string =>
  state.date;
