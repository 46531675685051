import React from 'react';
import { Trans } from 'react-i18next';
import './CookiesPolicy.scss';

const CookiesPolicy = () => (
  <section className="berg-page-cookies-policy">
    <Trans
      ns="pages"
      i18nKey="cookies"
      components={{ h1: <h1 />, p: <p />, br: <br />, h2: <h2 /> }}
    ></Trans>
  </section>
);

export default CookiesPolicy;
