import axios from 'axios';
import axiosInstance from '../../../services/axios';
import { IContactFormLp } from '../details/models/contact';
import { IContactForm, IContactResponse } from './contact.model';

export const sendContactForm = async (data: IContactForm): Promise<number> => {
  try {
    const response = await axiosInstance.post<IContactResponse>(
      '/v1/location/contact',
      data
    );

    return response.status;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};

export const sendContactFormLp = async (
  data: IContactFormLp
): Promise<number> => {
  try {
    const response = await axiosInstance.post<IContactResponse>(
      '/v1/location/contact/landing-page',
      data
    );

    return response.status;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};

export const sendEquipmentRentalSkis = async (
  data: {
    name: string;
    mail: string;
    phone: string;
    preferredDate: string;
    height: string;
    weight: string;
    shoeSize: string;
    gender: string;
    skiSchool?: string | null | undefined;
    equipmentType: (string | null)[];
    additionalAccessories: string[];
    additionalInformation?: string; 
  }
): Promise<number> => {
  try {
    const url = '/v1/location/equipment-rental-form';

    const equipmentRental = {
      name: data.name,
      mail: data.mail,
      phone: data.phone,
      preferredDate: data.preferredDate,
      height: data.height,
      weight: data.weight,
      shoeSize: data.shoeSize,
      gender: data.gender,
      skiSchool: data.skiSchool || null,
      equipmentType: data.equipmentType.filter(Boolean) as string[],
      additionalAccessories: data.additionalAccessories.filter(Boolean) as string[],
      additionalInformation: data.additionalInformation || null,
    };

    const response = await axiosInstance.post(url, equipmentRental);

    return response.status || 500;
  } catch (error: any) {
    console.error('Error:', error);
  
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Response status:', error.response?.status);
    } else {
      console.error('Error message:', error.message);
    }
    return 500;
  }
}

export const sendEquipmentRentalBike = async (
  data: {
    name: string;
    mail: string;
    phone: string;
    preferredDate: string;
    height: string;
    additionalInformation: string;
  },
  equipmentTypeType: string[],
  additionalAccessories: string[]
): Promise<number> => {
  try {
    const equipmentRental = {
      name: data.name,
      mail: data.mail,
      phone: data.phone,
      preferredDate: data.preferredDate,
      height: parseInt(data.height),
      equipmentTypeType: equipmentTypeType,
      additionalAccessories: additionalAccessories,
      additionalInformation: data.additionalInformation,
    };
    const response = await axiosInstance.post<IContactResponse>(
      '/v1/location/equipment-rental-form',
      equipmentRental
    );

    return response.status;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
};