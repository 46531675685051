import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../../../i18n';
import axiosInstance from '../../../services/axios';
import { hideLoader, hideLoaderFailure, showLoader } from '../../layout/slice';
import actionTypes from '../actionTypes';

import {
  ISeasonResponse,
  ISeasonResponseState,
} from './models/locationDetails';

export const fetchSkiPassDetails = createAsyncThunk(
  actionTypes.FETCH_SKI_PASS_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=skiPass'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchSkiSchoolPassDetails = createAsyncThunk(
  actionTypes.FETCH_SKI_SCHOOL_PASS_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=skiSchool'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchThermsPassDetails = createAsyncThunk(
  actionTypes.FETCH_THERMS_PASS_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=therms'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchSkiRentalPassDetails = createAsyncThunk(
  actionTypes.FETCH_SKI_RENTAL_PASS_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=skiRental'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchRestaurantDetails = createAsyncThunk(
  actionTypes.FETCH_RESTAURANT_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=restaurant'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchParkingDetails = createAsyncThunk(
  actionTypes.FETCH_PARKING_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=parking'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchAttractionsDetails = createAsyncThunk(
  actionTypes.FETCH_ATTRACTIONS_DETAILS,
  async (_, thunkApi): Promise<ISeasonResponseState> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISeasonResponse>(
        '/v1/location/details?category=attractions'
      );

      thunkApi.dispatch(hideLoader());

      return {
        response: response.data,
      };
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
