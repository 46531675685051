import React, { useEffect, useRef } from 'react';
import './InformationPopup.scss';
import Icons from '../../assets/icons';
import { IAttractions } from '../../modules/attractions/models';
// import { useTranslation } from 'react-i18next';

interface IProps {
  attraction: IAttractions;
  isVisibleInfoPopup: boolean;
  handleClose: () => void;
}

const InformationPopup = (props: IProps) => {
  const { attraction, isVisibleInfoPopup, handleClose } = props;
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (isVisibleInfoPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisibleInfoPopup, handleClose]);

  const handleCloseButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    handleClose();
  };

  if (!isVisibleInfoPopup) {
    return null;
  }

  return (
    <>
      <div className="berg-information-popup-background" />
      <div
        ref={popupRef}
        className="berg-information-popup flex flex-col gap-5 justify-center"
      >
        <div className="flex flex-row justify-between">
          <h3>{attraction.name}</h3>
          <button onClick={handleCloseButtonClick}>
            <Icons.ClosePopup />
          </button>
        </div>
        {attraction.fullDescription && (
          <div
            className="flex flex-col gap-5 text-start"
            dangerouslySetInnerHTML={{ __html: attraction?.fullDescription }}
          ></div>
        )}
      </div>
    </>
  );
};

export default InformationPopup;
