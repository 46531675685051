import './NumberCounter.scss';
import { CgChevronDown, CgChevronUp } from 'react-icons/cg';

interface IProps {
  counter: number;
  disabled?: boolean;
  maxCounter: number;
  onChange?: (value: number) => void;
  clean?: any;
}

const NumberCounter = ({ counter, onChange, disabled, maxCounter }: IProps) => {
  const increment = () => {
    if (counter >= maxCounter) {
      return;
    }
    onChange?.(counter + 1);
  };

  const decrement = () => {
    if (counter <= 0) {
      return;
    }
    onChange?.(counter - 1);
  };

  return (
    <div className="berg-components-number-counter" aria-disabled={disabled}>
      <div className="berg-components-number-counter-digit">{counter}</div>
      <button
        type="button"
        className="berg-components-number-counter-chevron"
        onClick={increment}
      >
        <CgChevronUp />
      </button>
      <button
        type="button"
        className="berg-components-number-counter-chevron"
        onClick={decrement}
      >
        <CgChevronDown />
      </button>
    </div>
  );
};

export default NumberCounter;
