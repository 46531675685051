import {
  BasketItemType,
  IAccommodationBasketItem,
  IAttractionsBasketItem,
  IBasketItem,
  IBasketStateModel,
  IParkingBasketItem,
  ISkiPassBasketItem,
  ISkiRentalBasketItem,
  ISkiSchoolBasketItem,
  IThermsBasketItem,
} from '../basket/models';
import { ISkiPass } from '../skiPass/models/skiPass';
import { ISkiRental } from '../skiRental/models';
import { ISkiSchool } from '../skiSchool/models';
import { ITherms } from '../therms/models';
import {
  CreateSkiSchoolOrderItem,
  CreateSkiRentalOrderItem,
  CreateSkiPassOrder,
  CreateSkiPassOrderItem,
  CreateSkiRentalOrder,
  CreateSkiSchoolOrder,
  OrderRequest,
  CreateThermsOrder,
  CreateThermsOrderItem,
  CreateAccommodationOrderItem,
  CreateAccommodationOrder,
  CreateParkingOrder,
  CreateParkingOrderItem,
  CreateAttractionsOrder,
  CreateAttractionsOrderItem,
} from './models/create';
import { IAccommodation } from '../accommodation/models';
import { PaymentMethod, PickupMethod } from '../common/models/enums';
import { ContactData } from '../common/models/orderData';
import { IParking } from '../parking/models';
import { IAttractions } from '../attractions/models';

export const mapBasketToOrderRequest = (
  basket: IBasketStateModel
): OrderRequest =>
  ({
    companyData: basket.companyData,
    contactData: mapToContactData(basket.contactData),
    skiPassOrder: mapToSkiPassOrder(basket.items),
    skiSchoolOrder: mapToSkiSchoolOrder(basket.items),
    skiRentalOrder: mapToSkiRentalOrder(basket.items),
    thermsOrder: mapToThermsOrder(basket.items),
    accommodationOrder: mapToAccommodationOrder(basket.items),
    parkingOrder: mapToParkingOrder(basket.items),
    attractionsOrder: mapToAttractionsOrder(basket.items),
  } as OrderRequest);

const mapToContactData = (contactData?: ContactData) => {
  if (contactData) {
    const newContactData = { ...contactData };

    newContactData.phone = contactData.phone
      ? `+${contactData.phonePrefix}${contactData.phone}`
      : undefined;

    delete newContactData.phonePrefix;

    return newContactData;
  }
};

const mapToSkiPassOrder = (basketItems: IBasketItem[]): CreateSkiPassOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.SkiPass)
    .map((x) => {
      const basketItem = x as ISkiPassBasketItem;
      const skiPass = x.item as ISkiPass;
      return {
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        paymentMethod: skiPass.defaultPaymentMethod,
        pickupMethod: basketItem.pickupMethod,
        productId: skiPass.id,
        validityDate: basketItem.validityDate,
        dataCarrierIds: basketItem.dataCarrierIds,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateSkiPassOrderItem;
    });
  return {
    items: items,
  } as CreateSkiPassOrder;
};

const mapToSkiSchoolOrder = (
  basketItems: IBasketItem[]
): CreateSkiSchoolOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.SkiSchool)
    .map((x) => {
      const basketItem = x as ISkiSchoolBasketItem;
      const skiSchool = x.item as ISkiSchool;
      return {
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        paymentMethod: skiSchool.defaultPaymentMethod,
        pickupMethod: basketItem.pickupMethod,
        productId: skiSchool.id,
        date: basketItem.date,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateSkiSchoolOrderItem;
    });
  return {
    items: items,
  } as CreateSkiSchoolOrder;
};

const mapToSkiRentalOrder = (
  basketItems: IBasketItem[]
): CreateSkiRentalOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.SkiRental)
    .map((x) => {
      const basketItem = x as ISkiRentalBasketItem;
      const skiRental = x.item as ISkiRental;
      return {
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        paymentMethod: skiRental.defaultPaymentMethod,
        pickupMethod: basketItem.pickupMethod,
        productId: skiRental.id,
        fromDate: basketItem.date,
        toDate: basketItem.date,
        additionalInformation: basketItem.additionalInformation,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateSkiRentalOrderItem;
    });
  return {
    items: items,
  } as CreateSkiRentalOrder;
};

const mapToThermsOrder = (basketItems: IBasketItem[]): CreateThermsOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Therms)
    .map((x) => {
      const basketItem = x as IThermsBasketItem;
      const therms = x.item as ITherms;
      return {
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        paymentMethod: therms.defaultPaymentMethod,
        pickupMethod: basketItem.pickupMethod,
        productId: therms.id,
        date: basketItem.date,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateThermsOrderItem;
    });
  return {
    items: items,
  } as CreateThermsOrder;
};

const mapToAccommodationOrder = (
  basketItems: IBasketItem[]
): CreateAccommodationOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Accommodation)
    .map((x) => {
      const basketItem = x as IAccommodationBasketItem;
      const accommodation = x.item as IAccommodation;
      return {
        productId: accommodation.id,
        quantity: basketItem.quantity,
        startDate: basketItem.startDate,
        endDate: basketItem.endDate,
        paymentMethod: PaymentMethod.OnLine,
        pickupMethod: PickupMethod.OnSite,
        adultCount: basketItem.adultCount,
        childrenAges: basketItem.childrenAges,
        additionalGuestsCount: basketItem.additionalGuestsCount,
        daysCount: basketItem.daysCount,
        adultsCount: basketItem.adultCount,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateAccommodationOrderItem;
    });
  return {
    items: items,
  } as CreateAccommodationOrder;
};

const mapToParkingOrder = (basketItems: IBasketItem[]): CreateParkingOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Parking)
    .map((x) => {
      const basketItem = x as IParkingBasketItem;
      const parking = x.item as IParking;
      return {
        productId: parking.id,
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        date: basketItem.date,
        paymentMethod: PaymentMethod.OnLine,
        pickupMethod: PickupMethod.OnSite,
        licencePlates: basketItem.licencePlates,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
      } as CreateParkingOrderItem;
    });
  return {
    items: items,
  } as CreateParkingOrder;
};

const mapToAttractionsOrder = (
  basketItems: IBasketItem[]
): CreateAttractionsOrder => {
  const items = basketItems
    .filter((x) => x.itemType === BasketItemType.Attractions)
    .map((x) => {
      const basketItem = x as IAttractionsBasketItem;
      const attraction = x.item as IAttractions;
      return {
        productId: attraction.id,
        quantity: basketItem.quantity,
        consumerCategoryId: basketItem.consumerCategoryId,
        date: basketItem.date,
        paymentMethod: PaymentMethod.OnLine,
        pickupMethod: PickupMethod.OnSite,
        priceBeforeRecalculation: basketItem.priceBeforeRecalculation,
        priceRecalculated: basketItem.priceRecalculated,
        orderItemComponents: basketItem.productComponents,
      } as CreateAttractionsOrderItem;
    });
  return {
    items: items,
  } as CreateAttractionsOrder;
};
