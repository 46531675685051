/* eslint-disable complexity */
import React from 'react';
import './RoomFullCard.scss';

import { IAccommodation } from '../../modules/accommodation/models';
import BergDevider from '../Storybook/BergDevider/BergDevider';
import { Trans, useTranslation } from 'react-i18next';
import Hooks from '../../hooks';
import Components from '..';

interface IProps {
  daysCount: number;
  accommodation: IAccommodation;
  onSubmitClick: (accommodation: IAccommodation) => void;
  quantityRoom: number;
  onCountChangeRoom: (count: number) => void;
}

const RoomPriceSummary = (props: IProps) => {
  const {
    accommodation,
    daysCount,
    onSubmitClick,
    quantityRoom,
    onCountChangeRoom,
  } = props;
  const { t } = useTranslation('components');

  const { getPriceDisplay } = Hooks.usePriceDisplay(
    accommodation.price.currency
  );

  const priceRoom = +accommodation.price.grossAmount;

  return (
    // <section className="berg-components-room-price-summary">
    //   <div className="berg-components-room-price-summary__price">
    //     <div>
    //       <label>
    //         <Trans
    //           values={{ people: accommodation.guestsIncludedInPrice }}
    //           ns="components"
    //           i18nKey="room_price_summary.price_for_room"
    //           components={{
    //             br: <br />,
    //           }}
    //         ></Trans>
    //       </label>
    //       <p>
    //         {getPriceDisplay(+accommodation.price.grossAmount)}
    //         <span>{t('room_price_summary.night')}</span>
    //       </p>
    //     </div>
    //     <div>
    //       <label>{t('room_price_summary.count_for_room')}</label>
    //       <Components.NumberCounter
    //         counter={quantityRoom}
    //         onChange={onCountChangeRoom}
    //         maxCounter={accommodation.availability}
    //       />
    //     </div>
    //   </div>
    //   <BergDevider color="lighter-grey"></BergDevider>
    //   <div className="berg-components-room-price-summary__supply">
    //     <div>
    //       {t('room_price_summary.supply', {
    //         price: getPriceDisplay(accommodation.price.additionalGuestPrice),
    //       })}{' '}
    //     </div>
    //     <div>
    //       <label>{t('room_price_summary.count_supply')}</label>
    //       {accommodation.maxAdditionalGuests === 0 ? (
    //         <Components.NumberCounter
    //           counter={0}
    //           onChange={onCountChangeSupply}
    //           maxCounter={accommodation?.maxAdditionalGuests * quantityRoom}
    //         />
    //       ) : (
    //         <Components.NumberCounter
    //           counter={quantitySupply}
    //           onChange={onCountChangeSupply}
    //           maxCounter={accommodation?.maxAdditionalGuests * quantityRoom}
    //         />
    //       )}
    //     </div>
    //     <div>
    //       {t('room_price_summary.max_count_supply', {
    //         count: accommodation?.maxAdditionalGuests * quantityRoom,
    //       })}{' '}
    //     </div>
    //   </div>
    //   <BergDevider color="lighter-grey"></BergDevider>
    //   <div className="berg-components-room-price-summary__total-price">
    //     <p>{t('room_price_summary.total')}:</p>
    //     <p>
    //       {quantityRoom === 0
    //         ? getPriceDisplay(
    //             +(accommodation?.price.grossAmount ?? 0) * daysCount
    //           )
    //         : getPriceDisplay(
    //             +(accommodation?.price.grossAmount ?? 0) *
    //               daysCount *
    //               quantityRoom +
    //               quantitySupply *
    //                 accommodation.price.additionalGuestPrice *
    //                 daysCount
    //           )}
    //     </p>
    //   </div>
    //   <BergDevider color="lighter-grey"></BergDevider>
    //   <div className="berg-components-room-price-summary__bed-layout">
    //     <p>{t('room_price_summary.bed_layout')}</p>
    //     <div>
    //       {/* <p>{t('room_price_summary.price_for_double_bed')}</p> */}
    //       <div>
    //         <p>
    //           {
    //             accommodation.bedTypes.find(
    //               (bed) => bed.icon === 'double' || 'single'
    //             )?.count
    //           }{' '}
    //           {
    //             accommodation.bedTypes.find(
    //               (bed) => bed.icon === 'double' || 'single'
    //             )?.name
    //           }
    //         </p>
    //         <p>
    //           {getPriceDisplay(
    //             accommodation.price.grossAmount * daysCount * quantityRoom
    //           )}
    //         </p>
    //       </div>
    //       {quantitySupply !== 0 && (
    //         <div>
    //           <p>
    //             {t('room_price_summary.price_for_supply', {
    //               count: quantitySupply,
    //             })}
    //           </p>
    //           <p>
    //             {getPriceDisplay(
    //               accommodation.price.additionalGuestPrice *
    //                 daysCount *
    //                 quantitySupply
    //             )}
    //           </p>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    //   <Components.BergButton
    //     label={t('room_full_card.submit')}
    //     color="primary-dark"
    //     onClick={() => onSubmitClick(accommodation)}
    //   />
    // </section>
    <section className="berg-components-room-price-summary">
      <div className="berg-components-room-price-summary__price">
        <div>
          <label>
            <Trans
              values={{ people: accommodation.guestsIncludedInPrice }}
              ns="components"
              i18nKey="room_price_summary.price_for_room"
              components={{
                br: <br />,
              }}
            ></Trans>
          </label>
          <p>
            {getPriceDisplay(+accommodation.price.grossAmount)}
            <span>{t('room_price_summary.night')}</span>
          </p>
        </div>
        {accommodation?.price?.additionalGuestsCount > 0 && (
          <div className="berg-components-room-price-summary__price--supply">
            {t('room_price_summary.supply_price_info', {
              price: getPriceDisplay(
                accommodation.price.additionalGuestPrice *
                  accommodation.price.additionalGuestsCount
              ),
              count: accommodation.price.additionalGuestsCount,
            })}
          </div>
        )}

        <div>
          <label>{t('room_price_summary.count_for_room')}</label>
          <Components.NumberCounter
            counter={quantityRoom}
            onChange={onCountChangeRoom}
            maxCounter={accommodation.availability}
          />
        </div>
      </div>
      <BergDevider color="lighter-grey"></BergDevider>
      <div className="berg-components-room-price-summary__total-price">
        <p>{t('room_price_summary.total')}:</p>
        <p>
          {quantityRoom === 1
            ? getPriceDisplay(Math.round(priceRoom * daysCount * 100) / 100)
            : getPriceDisplay(
                (Math.round(priceRoom * daysCount * 100) / 100) * quantityRoom
              )}
        </p>
      </div>
      <Components.BergButton
        label={t('room_full_card.submit')}
        color="primary-dark"
        onClick={() => onSubmitClick(accommodation)}
      />
    </section>
  );
};

export default RoomPriceSummary;
