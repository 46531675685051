import { useTranslation } from 'react-i18next';
import Components from '../..';
import { IOrder } from '../../../modules/order/models/details';
import './OrderSummaryHeader.scss';
import getClientsStyle from '../../../utils/getClientsStyle';
interface IProps {
  orderDetails: IOrder;
}
const OrderSummaryHeader = ({ orderDetails }: IProps) => {
  const { t } = useTranslation('components');
  const styleForClient = getClientsStyle();

  const date = orderDetails?.creationDate?.split('T')[0];
  const convertDate = (str: string) => str?.split('-').reverse().join('.');

  return (
    <div className="order-summary-header">
      <Components.BergStack
        direction="column"
        spacing={0}
        pt={0}
        pb={0}
        mt={0}
        mb={0}
      >
        <Components.BergStack
          direction="row"
          spacing={0}
          pt={0}
          pb={1}
          mt={0}
          mb={0}
        >
          <div className="order-summary-header__item-header--date">
            {t('order_summary_header.order_date')}
          </div>
          <div
            className="order-summary-header__item-description--date"
            data-client-style={styleForClient}
          >
            {convertDate(date)}
          </div>
        </Components.BergStack>
        <Components.BergDevider color="ligth-grey"></Components.BergDevider>
      </Components.BergStack>
    </div>
  );
};

export default OrderSummaryHeader;
