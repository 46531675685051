import {
  IAttractionsClient,
  IProductType,
} from '../common/models/productModels';
import { IAttractionsStateModel, IAttractions } from './models';

export const getProductsTypes = (
  state: IAttractionsStateModel
): IProductType[] => state.productsTypes;

export const getProductsEntries = (
  state: IAttractionsStateModel
): IAttractions[] => state.productsPasses;

export const getAttractionsDate = (state: IAttractionsStateModel): string =>
  state.date;

export const getAttractionsClient = (
  state: IAttractionsStateModel
): IAttractionsClient[] => state.attractionsClient;
