const visibleOnRelease = {
  planStay: false,
  mainSearch: false,
  czechLang: true,
  rentalBike: false,
  reviews: false,
  showPromoBanner: false,
  showAlert: false,
  skiSeasons: false,
  acc: false,
  ski: false,
  news: process.env.REACT_APP_ENVIRONMENT === 'development',
  summerLifts: process.env.REACT_APP_ENVIRONMENT === 'development',
  investmentAndDevelopment: false,
  restaurantMenu: false,
  rentalAndSchool: true,
  outOfService: false,
  parking: process.env.REACT_APP_ENVIRONMENT === 'development',
  weather: process.env.REACT_APP_ENVIRONMENT === 'development',
};

export default visibleOnRelease;
