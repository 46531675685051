import './ThermsTemplate.scss';
import Therms from './Therms/Therms';
import { ITherms } from '../../modules/therms/models';

interface IProps {
  therms: ITherms[];
}

const ThermsTemplate = (props: IProps) => {
  const { therms } = props;

  return (
    <div className="berg-components-therms-template">
      {therms?.map((therm) => (
        <Therms key={therm.id} therm={therm} />
      ))}
    </div>
  );
};

export default ThermsTemplate;
