import { IProductComponents } from '../modules/common/models/productModels';

const formattedProductComponents = (row: { [key: string]: any }) =>
  row?.productComponents
    .filter(
      (product: IProductComponents) =>
        product.productAdditionalSettings[0]?.value
    )
    .map((product: IProductComponents) => {
      const productName = product?.name;
      const value = product?.productAdditionalSettings[0]?.value || '';

      return `${productName} - ${value}`;
    });

export default formattedProductComponents;
