import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../..';
import Hooks from '../../../hooks';
import {
  BasketItemType,
  IBasketItem,
  ISkiSchoolBasketItem,
} from '../../../modules/basket/models';
import { PickupMethod } from '../../../modules/common/models/enums';
import {
  IConsumerCategory,
  IProduct,
} from '../../../modules/common/models/productModels';
import { ISkiSchool } from '../../../modules/skiSchool/models';
import { getSkiSchoolDate } from '../../../modules/skiSchool/selectors';
import './School.scss';
import { addToBasket } from '../../../modules/basket/operations';

interface IProps {
  school: ISkiSchool;
}

const School = (props: IProps) => {
  const {
    school: { name, description, consumerCategories, coverImageUrl },
  } = props;

  const { t } = useTranslation('components');
  const [pickedCategory, setPickedCategory] = useState<
    IConsumerCategory | null | undefined
  >();

  const price = pickedCategory?.price.grossAmount ?? 0;

  const currentCurrency = consumerCategories[0].price.currency;

  const { getPriceDisplay } = Hooks.usePriceDisplay(currentCurrency);

  const dispatch = Hooks.useAppDispatch();
  const date = Hooks.useAppSelector((s) => getSkiSchoolDate(s.skiSchool));

  const handleAddToBasket = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const item: IBasketItem = {
      itemType: BasketItemType.SkiSchool,
      price: +price,
      quantity: 1,
      date: date,
      currency: currentCurrency,
      item: props.school as IProduct,
      consumerCategoryId: pickedCategory?.id,
      pickupMethod: props.school.defaultPickupMethod as PickupMethod,
    } as ISkiSchoolBasketItem;
    console.log(item);
    dispatch(addToBasket(item));
    e.currentTarget.reset();
    setPickedCategory(null);
  };

  const typeDropdown = consumerCategories?.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleCategoryChange = (value: string) => {
    setPickedCategory(consumerCategories.find((cat) => cat.id === value));
  };

  useEffect(() => {
    if (consumerCategories.length === 1) {
      setPickedCategory(consumerCategories[0]);
    }
  }, [consumerCategories]);

  return (
    <form
      onSubmit={handleAddToBasket}
      className="berg-components-school-wrapper"
    >
      <div className="berg-components-school__picture">
        <img src={coverImageUrl} alt={t('school.img_alt')} />
      </div>
      <div className="berg-components-school__content">
        <div className="berg-components-school__title">
          {name}
          <hr className="berg-components-school__line"></hr>
        </div>

        <div className="berg-components-school__choices">
          <label>{t('school.number_of_hours')}</label>
          <Components.Dropdown
            shouldReset={pickedCategory === null}
            name="skiSchoolOptions"
            options={typeDropdown}
            onChange={handleCategoryChange}
          />
        </div>
        <div
          className="berg-components-school__sum"
          aria-disabled={!pickedCategory}
        >
          {getPriceDisplay(price)}
        </div>
        <button
          className="berg-components-school__submit"
          disabled={!pickedCategory}
        >
          {t('school.submit')}
        </button>
        <div className="berg-components-school__info">
          <p>{description}</p>
        </div>
      </div>
    </form>
  );
};

export default School;
