interface ISiteSettings {
  instagramUrl?: string;
  facebookUrl?: string;
  bramaGorce: string;
}

const siteSettings: ISiteSettings = {
  bramaGorce: 'https://www.bramawgorce.com/',
};

export default siteSettings;
