import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Components from '../../components';
import Cookies from '../../components/Cookies/Cookies';
import Loader from '../../components/Loader/Loader';
import visibleOnRelease from '../../config/visibleOnRelease';
import Context from '../../context';
import Hooks from '../../hooks';
import { fetchAnnouncements } from '../../modules/location/announcements/operations';
import { fetchBillboards } from '../../modules/location/billboards/operations';
import { fetchSettings } from '../../modules/location/settings/operations';
import { fetchWeather } from '../../modules/location/weather/operations';
import ScrollToTop from './ScrollToTop';
import './Start.scss';
import 'react-toastify/dist/ReactToastify.css';
import Icons from '../../assets/icons';
import OutOfService from '../../components/OutOfService/OutOfService';
import { useTranslation } from 'react-i18next';
import { useLogosAndLinks } from '../../utils/useLogoAndLinks';

// eslint-disable-next-line complexity
const Start = () => {
  const dispatch = Hooks.useAppDispatch();
  const { t } = useTranslation('common');
  const loc = useLocation().pathname;
  const [location, setLocation] = useState(loc);

  const {
    logoSrcFlisacy,
    logoSrcBrama,
    logoSrcPolana,
    logoSrcPotok,
    linkBrama,
    linkFlisacy,
    linkPolana,
    linkPotok,
  } = useLogosAndLinks();

  useEffect(() => {
    setLocation(loc);
  }, [location, loc]);

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchAnnouncements());
    dispatch(fetchBillboards());

    const handleFetchWeather = () => {
      dispatch(fetchWeather());
    };

    handleFetchWeather();

    const weatherIntervalIndex = setInterval(handleFetchWeather, 300_000);

    return () => {
      clearInterval(weatherIntervalIndex);
    };
  }, [dispatch]);

  useEffect(() => {
    const buildType = process.env.REACT_APP_BUILD_TYPE;

    let title, description, ogImageUrl, favicon, ogDescription, ogTitle, ogName;
    switch (buildType) {
      case 'BRAMA':
        title = 'Brama w Gorce - Niezwykła podróż w serce przyrody';
        description =
          'Poznaj Centrum Edukacyjno-Przyrodnicze Brama w Gorce. Idealne miejsce do aktywnego wypoczynku i poznawania bogactwa przyrody w sercu Podhala. Odkryj dolinę potoku Kowaniec i lokalne atrakcje przyrodnicze dostępne przez cały rok dla każdego, w tym osób z niepełnosprawnościami.';
        ogImageUrl = 'https://bramawgorce.bergregions.pl/assets/logo_gorce.png';
        (ogDescription = 'Brama w Gorce - Niezwykła podróż w serce przyrody'),
          (ogTitle = 'Brama w Gorce - Niezwykła podróż w serce przyrody'),
          (ogName = 'Brama w Gorce - Niezwykła podróż w serce przyrody'),
          (favicon = '/assets/favicon/favicon-32x32.png');
        break;
      case 'FLISACY':
        title = 'Flisacy - Przygoda na rzece';
        description =
          'Spływ Przełomem Dunajca to największa atrakcja turystycznych polskich gór. Podczas spływu tratwami na własne oczy zobaczysz piękno Pienin, a flisackie opowieści dopełnią całej wyprawy. Pływamy co roku od pierwszego kwietnia do ostatniego października.';
        ogImageUrl = 'https://flisacy.bergregions.pl/assets/logo_flisacy.png';
        ogDescription = 'Flisacy - Przygoda na rzece';
        ogTitle = 'Flisacy - Przygoda na rzece';
        ogName = 'Flisacy - Przygoda na rzece';
        favicon = '/assets/favicon/favicon_flisacy_32x32.png';
        break;
      case 'POLANA':
        title = 'Stacja Narciarska Długa Polana | Nowy Targ';
        description =
          'Ośrodek położony u stóp Gorców w Nowym Targu. Słynny mikroklimat stacji sprawia, że warunki śniegowe są tutaj zawsze idealne, a trasy perfekcyjnie przygotowane.';
        ogImageUrl = 'https://polana.bergregions.pl/assets/logo_polana.png';
        ogDescription = 'Stacja Narciarska Długa Polana | Nowy Targ';
        ogTitle = 'Stacja Narciarska Długa Polana | Nowy Targ';
        ogName = 'Stacja Narciarska Długa Polana | Nowy Targ';
        favicon = '/assets/favicon/favicon_polana_32x32.png';
        break;
      case 'POTOK':
        title =
          'Termy Gorący Potok | Szaflary | Baseny termalne na Podhalu z gorącą siarkową wodą | Termy, apartamenty, spa';
        description =
          'Gorący Potok to kompleks term z kilkunastoma unikalnymi basenami zewnętrznymi z naturalną, surową wodą siarkową, w których każdy znajdzie atrakcje dla siebie. Strefa basenowa, strefa spa, noclegi to wszystko dostępne na Podhalu w Szaflarach na termach Gorący Potok';
        ogImageUrl = 'https://potok.bergregions.pl/assets/logo_potok.png';
        ogDescription =
          'Termy Gorący Potok | Szaflary | Baseny termalne na Podhalu z gorącą siarkową wodą | Termy, apartamenty, spa';
        ogTitle =
          'Termy Gorący Potok | Szaflary | Baseny termalne na Podhalu z gorącą siarkową wodą | Termy, apartamenty, spa';
        ogName =
          'Termy Gorący Potok | Szaflary | Baseny termalne na Podhalu z gorącą siarkową wodą | Termy, apartamenty, spa';
        favicon = '/assets/favicon/favicon_potok_32x32.png';
        break;
      default:
        title = 'Niezwykła podróż - Odkrywaj z nami przyrodę';
        description = 'Niezwykła podróż - Odkrywaj z nami przyrodę';
        ogImageUrl = 'https://default.bergregions.pl/assets/logo_berg.png';
        ogDescription = 'Niezwykła podróż - Odkrywaj z nami przyrodę';
        ogTitle = 'Niezwykła podróż - Odkrywaj z nami przyrodę';
        ogName = 'Niezwykła podróż - Odkrywaj z nami przyrodę';
        favicon = '/assets/favicon/favicon-default-32x32.png';
    }

    document.title = title;

    // Ustawianie metadanych opisu strony
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }

    // Ustawianie favicona
    const linkFavicon = document.querySelector('link[rel="icon"]');
    if (linkFavicon) {
      linkFavicon.setAttribute('href', favicon);
    }

    // Ustawianie metadanych obrazu Open Graph
    const metaOgImageUrl = document.querySelector('meta[property="og:image"]');
    if (metaOgImageUrl) {
      metaOgImageUrl.setAttribute('content', ogImageUrl);
    }

    const metaOgDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (metaOgDescription) {
      metaOgDescription.setAttribute('content', ogDescription);
    }

    const metaOgTitle = document.querySelector('meta[property="og:image"]');
    if (metaOgTitle) {
      metaOgTitle.setAttribute('content', ogTitle);
    }

    const metaOgISitName = document.querySelector('meta[property="og:image"]');
    if (metaOgISitName) {
      metaOgISitName.setAttribute('content', ogName);
    }
  }, []);

  return (
    <>
      <Context.SeasonTheme>
        <header id="header">
          <div className="berg-pages-start__header">
            <div className="left">
              {linkBrama && (
                <a href={linkBrama}>
                  <img src={logoSrcBrama} alt="Logo Brama W Gorce" />
                </a>
              )}
              {linkFlisacy && (
                <a href={linkFlisacy}>
                  <img src={logoSrcFlisacy} alt="Logo Flisacy" />
                </a>
              )}
              {linkPolana && (
                <a href={linkPolana}>
                  <img src={logoSrcPolana} alt="Logo Długa Polana" />
                </a>
              )}
              {linkPotok && (
                <a href={linkPotok}>
                  <img src={logoSrcPotok} alt="Logo Goracy Potok" />
                </a>
              )}
              <div className="berg-pages-start__header--line" />
              <p>{t('navigation.header')}</p>
            </div>
            <div className="right">
              {visibleOnRelease.weather && <Components.Weather />}
              <Components.LanguageDropdown />
            </div>
          </div>
        </header>

        <Context.Navigation>
          <div id="sub-header"></div>
          <main>
            <Outlet />
          </main>
        </Context.Navigation>

        <footer>
          <Components.Footer />
        </footer>
        {location !== '/cart' &&
          location !== '/cart/order-data' &&
          location !== '/cart/summary' &&
          location !== '/payment/status' && <Components.BasketStickyCTA />}
        {visibleOnRelease.planStay && <Components.StickyCTA />}
        <Loader />
      </Context.SeasonTheme>
      <ScrollToTop />
      {/* <Billboard /> */}
      <Cookies />
      {location !== '/activities/therms-tickets' &&
        location !== '/activities/accommodation' &&
        location !== '/activities/parking' &&
        visibleOnRelease.outOfService && <OutOfService />}
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={({ closeToast }) => (
          <button onClick={closeToast} className="berg-styles-snackbar-close">
            <Icons.Xmark />
          </button>
        )}
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Start;
