import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import pages_pl from './assets/translations/pages/pl.json';
import pages_en from './assets/translations/pages/en.json';
import pages_sk from './assets/translations/pages/sk.json';
import pages_uk from './assets/translations/pages/uk.json';
import pages_hu from './assets/translations/pages/hu.json';
import components_pl from './assets/translations/components/pl.json';
import components_en from './assets/translations/components/en.json';
import components_sk from './assets/translations/components/sk.json';
import components_uk from './assets/translations/components/uk.json';
import components_hu from './assets/translations/components/hu.json';
import common_pl from './assets/translations/common/pl.json';
import common_en from './assets/translations/common/en.json';
import common_sk from './assets/translations/common/sk.json';
import common_uk from './assets/translations/common/uk.json';
import common_hu from './assets/translations/common/hu.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'pl',
    resources: {
      pl: {
        common: common_pl,
        pages: pages_pl,
        components: components_pl,
      },
      en: {
        common: common_en,
        pages: pages_en,
        components: components_en,
      },
      sk: {
        common: common_sk,
        pages: pages_sk,
        components: components_sk,
      },
      // cs: {
      //   common: common_cz,
      //   pages: pages_cz,
      //   components: components_cz,
      // },
      uk: {
        common: common_uk,
        pages: pages_uk,
        components: components_uk,
      },
      hu: {
        common: common_hu,
        pages: pages_hu,
        components: components_hu,
      },
    },
  });

export default i18n;
