import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../i18n';
import axiosInstance from '../../services/axios';
import { IProductType } from '../common/models/productModels';
import { hideLoader, hideLoaderFailure, showLoader } from '../layout/slice';
import actionTypes from './actionTypes';
import { ISkiSchool, ISkiSchoolResponse } from './models';
import { setDate } from './slice';

export const fetchSkiSchoolTypes = createAsyncThunk<IProductType[]>(
  actionTypes.FETCH_SKI_SCHOOL_TYPES,
  async (_, thunkApi): Promise<IProductType[]> => {
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<IProductType[]>(
        '/v1/ski-school/types'
      );

      thunkApi.dispatch(hideLoader());

      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);

export const fetchSkiSchools = createAsyncThunk(
  actionTypes.FETCH_SKI_SCHOOLS,
  async (
    params: { typeId: string; date: string },
    thunkApi
  ): Promise<ISkiSchool[]> => {
    const formattedDate = moment(params.date).format('YYYY-MM-DD');

    thunkApi.dispatch(setDate(formattedDate));
    thunkApi.dispatch(showLoader());
    try {
      const response = await axiosInstance.get<ISkiSchoolResponse>(
        '/v1/ski-school',
        {
          params: {
            typeId: params.typeId,
            date: formattedDate,
          },
        }
      );

      thunkApi.dispatch(hideLoader());

      return response.data.content;
    } catch (e) {
      console.error(JSON.stringify(e));
      // TODO: create right error message
      thunkApi.dispatch(hideLoaderFailure(i18n.t('common:error_message')));
      throw e;
    }
  }
);
