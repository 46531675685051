import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Season } from '../config/globalVariables';
import Hooks from '../hooks';
import { getTheme } from '../modules/location/settings/selectors';
import Loader from '../components/Loader/Loader';
import { hideLoader, showLoader } from '../modules/layout/slice';

interface IProps {
  children: ReactNode;
}

interface ISeasonContext {
  season: Season;
  theme: Season;
  setSeason: Dispatch<SetStateAction<Season>>;
  isSeasonSelectorTouched: boolean;
  setIsSeasonSelectorTouched: Dispatch<SetStateAction<boolean>>;
}

export const SeasonContext = createContext<Partial<ISeasonContext>>({});

const SeasonTheme = (props: IProps) => {
  const { children } = props;

  const theme = Hooks.useAppSelector((s) => getTheme(s.settings));
  const dispatch = Hooks.useAppDispatch();

  const [season, setSeason] = useState<Season>(theme);
  const [isSeasonSelectorTouched, setIsSeasonSelectorTouched] = useState(false);

  useEffect(() => {
    if (!theme) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
      setSeason(theme);
    }
  }, [dispatch, theme]);

  useEffect(() => {
    switch (season) {
      case Season.Summer:
        document.body.classList.add('berg-theme-summer');
        document.body.classList.remove('berg-theme-winter');
        break;

      case Season.Winter:
        document.body.classList.add('berg-theme-winter');
        document.body.classList.remove('berg-theme-summer');
        break;
    }
  }, [season]);

  return (
    <SeasonContext.Provider
      value={{
        season,
        theme,
        setSeason,
        isSeasonSelectorTouched,
        setIsSeasonSelectorTouched,
      }}
    >
      <Loader />
      {children}
    </SeasonContext.Provider>
  );
};

export default SeasonTheme;
