import React from "react";
import { Trans } from "react-i18next";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <section className="berg-page-privacy-policy">
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_1"
        components={{
          h1: <h1 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
        }}
      >
        <h1>Zásady ochrany osobných údajov</h1>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Táto informácia o ochrane osobných údajov a súkromia sa vzťahuje na
            Vás a na Vaše osobné údaje, pretože ste našim zákazníkom.
            Našaspoločnosť vystupuje ako
            <b>prevádzkovateľ pri spracúvaní Vašich osobných údajov.</b>
          </p>
          <p>
            Chceme Vám vysvetliť ako budeme používať osobné údaje, ktoré získame
            od vás alebo od tretích strán počas trvania nášho zmluvnéhovzťahu,
            či po jeho ukončení.
          </p>
          <p>
            Týmto dokumentom si zároveň chceme splniť našu
            <b>povinnosť poskytnúť informácie podľa čl. 13</b> Nariadenia
            Európskeho parlamentu a rady(EÚ) č. 2016/679 z 27. apríla 2016 o
            ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom
            pohybe takýchto údajov,ktorým sa zrušuje smernica 95/46/ES
            (všeobecné nariadenie o ochrane údajov) a zákona č. 18/2018 Z.z. z
            29. novembra 2017 oochrane osobných údajov a o zmene a doplnení
            niektorých zákonov
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_2"
        components={{
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
          br: <br />,
          a: <a href="https://www.meanderoravice.sk/" />,
        }}
      >
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            <b>
              Identifikačné údaje prevádzkovateľa:
              <br />
              Meander invest s.r.o.
              <br />
              M. R. Štefánika 1821
              <br />
              026 01 Dolný Kubín
              <br />
              IČO: 44 820 313
              <br />
              IČ DPH: SK2022842404
              <br />
              <a href="https://www.meanderoravice.sk/">www.meanderoravice.sk</a>
              <br />
            </b>
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_3"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
          ul: <ul />,
          li: <li />,
        }}
      >
        <h2>1. Typy osobných údajov</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>Spracovávame nasledujúce údaje:</p>
          <ul>
            <li>
              Osobné kontaktné údaje. Napríklad vaše meno, priezvisko, adresa
              trvalého bydliska, adresa na zasielanie písomností, emailová
              adresa, kontaktné telefónne číslo, dátum narodenia.
            </li>
            <li>
              Pracovné kontaktné údaje. Napríklad adresa firmy, ktorú
              zastupujete, pracovná emailová adresa a telefónne číslo.
            </li>
            <li>
              Zmluvné údaje. Napríklad obsah zmluvy uzatvorenej s našou
              spoločnosťou, vrátane všetkých jej dodatkov, rozsah poskytovaných
              služieb, typ vozidla, jeho označenie.
            </li>
            <li>Platobné informácie.</li>
            <li>
              Údaje o korešpondencii a komunikácii. Ako napr. emailová
              korešpondencia, internetové dátové prenosy a IP adresa.
            </li>
            <li>
              Prístupové práva. Vaše prístupové práva k rôznym aplikáciám v
              rámci IT infraštruktúry.
            </li>
            <li>Registračné údaje vrátane prístupového hesla.</li>
            <li>Informácie týkajúce za bezpečnosti.</li>
            <li>
              <b>Záznamy z kamerového systému.</b>
            </li>
          </ul>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_4"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
          ul: <ul />,
          li: <li />,
        }}
      >
        <h2>2. Účely a ciele spracúvania údajov</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>Vaše údaje budeme spracúvať na nasledovné účely:</p>
          <ul>
            <li>
              Poskytovanie zmluvných služieb. Musíme zabezpečovať evidenciu
              svojich klientov v zmysle osobitných predpisov. Právnym základom
              je
              <b>plnenie zákonnej povinnosti prevádzkovateľa.</b>
            </li>
            <li>
              Vernostný program . Evidencia zákazníka a zasielanie noviniek o
              najnovších ponukách a službách spojených s členstvom v našom
              programe. Právnym základom je
              <b>
                zmluva uzatvorená medzi nami a oprávnený záujem prevádzkovateľa.
              </b>
            </li>
            <li>
              Direct marketing. Zaoberáme sa tým, ako Vám vylepšiť rozvoj našich
              služieb tvorbu ponúk na mieru.
              <b> Právnym základom je oprávnený záujem prevádzkovateľa.</b>
            </li>
            <li>
              Dane a účtovníctvo. Z účelom plnenia povinností vyplývajúcich z
              daňového zákona a iných predpisov spojených finančným plnením sme
              povinní spracovávať určité osobné údaje. Právnym základom
              <b>je plnenie zákonných povinností.</b>
            </li>
            <li>
              Bezpečnosť a ohrozenie prevádzky a siete. Kontrolujeme funkčnosť,
              bezpečnosť a stabilitu prevádzky našej siete, ktorej ste
              účastníkom. Právnym základom je plnenie zákonných povinností a
              <b>oprávnený záujem prevádzkovateľa.</b>
            </li>
            <li>
              Zároveň máme v našej prevádzke nainštalovaný
              <b>kamerový systém</b>, ktorý Vás chráni. Právnym základom je
              <b>oprávnený záujem prevádzkovateľa.</b>
            </li>
            <li>
              Riešenie sporov a vyšetrovanie priestupkov. Môžeme spracovávať
              osobné údaje na účely riešenia sporov, sťažností alebo právnych
              postupov alebo ak máme podozrenie z priestupku, ktoré by sme
              chceli ďalej preskúmavať. Právnym základom je
              <b>
                plnenie zákonných povinností a oprávnený záujem prevádzkovateľa.
              </b>
            </li>
            <li>
              Dodržiavanie zákona. Možno budeme musieť spracovávať vaše osobné
              údaje, aby sme dodržiavali zákon (napr. Zhoda vášho mena s menami
              na takzvaných zoznamoch určených strán a dodržiavanie zákona o
              praní špinavých peňazí) alebo aby sme vyhoveli súdnemu príkazu.
            </li>
            <li>
              Marketingové súhlasy. Rovnako môže dôjsť k využívaniu aj iných
              údajov ale výlučne na základe osobitných súhlasov, ktoré si od Vás
              vopred vyžiadame.
              <b>Právnym základom je súhlas, ktorý sme od Vás získali.</b>
            </li>
            <li>
              Zákaznícka administratíva. Vedieme si záznamy o všetkých našich
              zákazníkoch a ich službách. Na základe analýzy našich záznamov
              tiež robíme strategické rozhodnutia ponukách pre našich
              zákazníkov. Právnym základom je
              <b>
                zmluva uzatvorená medzi nami a oprávnený záujem prevádzkovateľa.
              </b>
            </li>
          </ul>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_5"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
        }}
      >
        <h2>3. Kto má prístup k vašim údajom</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Prevádzkovateľ môže zdieľať vaše údaje s tretími stranami za
            nasledovných okolností:
          </p>
          <p>
            Vaše osobné údaje môžeme zdieľať s inými tretími stranami, ktoré
            konajú v našom mene, napríklad s poskytovateľom služieb. V takýchto
            prípadoch môžu tieto tretie strany používať vaše osobné údaje len na
            účely opísané vyššie a iba v súlade s našimi pokynmi. Zmluvne sme
            ich zaviazali, aby dodržiavali bezpečnostné pokyny upravujúce v
            zákone.
          </p>
          <p>
            K osobným údajom budú mať prístup <b>naši zamestnanci</b>. Prístup
            majú len tí, ktorí to pri svojej práci potrebujú na vyššie uvedené
            účely, a len vtedy, ak je zamestnanec viazaný povinnosťou
            mlčanlivosti.
          </p>
          <p>
            Ak to vyžaduje zákon alebo súdny príkaz, vaše osobné údaje môžeme
            zdieľať napríklad s našimi dodávateľmi alebo klientmi, daňovými
            úradmi, úradmi sociálneho zabezpečenia,
            <b>orgánmi činnými v trestnom konaní</b> alebo inými vládnymi
            orgánmi.
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_6"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
        }}
      >
        <h2>4. Umiestnenie vašich osobných údajov</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Vaše osobné údaje budú umiestnené výlučne v rámci Európskej únie a
            Európskeho hospodárskeho priestoru. Vaše údaje v našich systémoch
            chránime v najvyššej možnej miere.
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_7"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
        }}
      >
        <h2>5. Uchovávanie osobných údajov</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Vaše osobné údaje uchovávame na obmedzený čas a tieto údaje budú
            vymazané, keď už nebudú potrebné na účely spracovania. Vo väčšine
            prípadov to znamená, že uchováme vaše údaje počas trvania Vášho
            vzťahu s nami. Pokiaľ je to možné, údaje vymažeme ešte počas Vášho
            vzťahu s nami, ako náhle už nebudú potrebné. V každom prípade, vaše
            osobné záznamy vymažeme najneskôr do uplynutia zákonných lehôt po
            skončení zmluvného vzťahu, pokiaľ
            <b>legislatíva nevyžaduje ich uchovávanie</b>.
          </p>
          <p>
            Vaše osobné údaje môžeme spracovávať po dlhšiu dobu po ukončení
            vzťahu v prípade pretrvávajúceho právneho sporu alebo ak ste nám
            udelili povolenie na dlhodobé uchovávanie vašich osobných údajov.
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_8"
        components={{
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
        }}
      >
        <h2>6. Právny základ pre spracovávanie vašich údajov</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Vo väčšine prípadov spracovávame vaše osobné údaje na základe toho,
            že spracovanie je nevyhnutné na účely oprávneného záujmu, ktorý
            sledujeme, <b>na zmluvnom základe</b> alebo na základe súhlasu vás
            ako dotknutej osoby. Svoj súhlas môžete kedykoľvek odvolať
            preukázateľným oznámením na našej web stránke. V mnohých prípadoch
            budeme tiež musieť spracovať vaše osobné údaje na základe zákonnej
            povinnosti v zmysle osobitného predpisu.
            <b>
              V prípade spracúvania na základe súhlasu máte vždy možnosť svoj
              súhlas stiahnuť.
            </b>
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_9"
        components={{
          anchor_1: <a href="mailto:recepcia@meanderoravice.sk" />,
          anchor_2: <a href="https://www.dataprotection.gov.sk/" />,
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
          p: <p />,
          b: <b />,
          ul: <ul />,
          li: <li />,
        }}
      >
        <h2>7. PRÁVA DOTKNUTÝCH OSÔB</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Je dôležité, aby ste chápali, že ide o Vaše osobné údaje, ktoré
            spracovávame a že chceme, aby ste s tým boli uzrozumení. Aj keď na
            spracovanie Vašich osobných údajov nepotrebujeme vaše povolenie,
            lebo
            <b>nám to ukladá zákon, alebo zákony spojené s našou zmluvou</b>, v
            súvislosti so spracovaním Vašich osobných údajov máte veľa práv.
            Text hore Vám odpovie na väčšinu vašich otázok.
          </p>
          <p>
            <b>Vaše práva</b>
          </p>
          <p>Právo na prístup</p>
          <div>
            <p>
              Môžete si vyžiadať informácie o tom, ako spracovávame vaše osobné
              údaje, vrátane informácií o tom:
            </p>
            <ul>
              <li>Prečo spracovávame vaše osobné údaje</li>
              <li>Aké kategórie osobných údajov spracovávame</li>
              <li>S kým vaše osobné údaje zdieľame</li>
              <li>
                Ako dlho uchovávame vaše osobné údaje alebo aké sú kritéria na
                určenie tejto lehoty
              </li>
              <li>Aké máte práva</li>
              <li>
                Odkiaľ vaše osobné údaje získavame (ak sme ich nezískali od vás)
              </li>
              <li>
                Ak spracovávanie zahŕňa automatizované rozhodovanie (tzv.
                profilovanie)
              </li>
              <li>
                Ak vaše osobné údaje boli prevedené do krajiny, ktorá je mimo
                EEA, ako zabezpečíme ochranu vašich osobných údajov.
              </li>
            </ul>
            <p>
              Všetky vyššie uvedené informácie sú dostupné v tomto dokumente.
            </p>
            <p>
              Môžete taktiež požiadať o kópiu osobných údajov, ktoré o vás
              spracovávame. Avšak, dodatočné kópie budú spoplatnené.
            </p>
          </div>
          <p>
            <b>Právo na opravu</b>
          </p>
          <p>
            Je dôležité, aby sme mali o vás správne informácie a žiadame vás,
            aby ste nás upozornili, ak je niektorý z vašich osobných údajov
            nesprávny, napr. ak ste si zmenili meno alebo ak ste sa
            presťahovali.
          </p>
          <p>
            <b>Právo na vymazanie</b>
          </p>
          <p>
            Ak spracovávame vaše osobné údaje nezákonným spôsobom, napríklad ak
            spracovávame vaše osobné údaje dlhšie, než je potrebné alebo
            bezdôvodne, môžete nás požiadať o vymazanie týchto údajov.
          </p>
          <p>
            <b>Právo na obmedzenie</b>
          </p>
          <p>
            Od momentu, kedy ste požiadali o opravu vašich osobných údajov alebo
            ak ste namietali proti spracovaniu a do momentu, kým nebudeme môcť
            problém vyšetriť alebo potvrdiť
          </p>
          <p>
            presnosť vašich osobných údajov (alebo ich podľa vašich pokynov
            zmeniť), máte nárok na obmedzené spracovanie. To znamená, že my (s
            výnimkou uchovávania osobných údajov) môžeme spracovávať vaše osobné
            údaje iba v súlade s vaším súhlasom, ak je to potrebné v súvislosti
            s právnymi nárokmi, na ochranu práv niekoho iného, alebo ak existuje
            významný verejný záujem na spracovaní. Môžete tiež požiadať, aby sme
            obmedzili spracovanie vašich osobných údajov, ak je spracovanie
            nezákonné, ale nechcete, aby sme osobné údaje vymazali.
          </p>
          <p>
            <b>Právo namietať</b>
          </p>
          <p>
            Ak sa domnievate, že nemáme právo na spracovanie vašich osobných
            údajov, môžete proti nášmu spracovaniu namietať. V takýchto
            prípadoch môžeme pokračovať v spracovávaní iba vtedy, ak vieme
            preukázať presvedčivé oprávnené dôvody, ktoré prevážia vaše záujmy,
            práva a slobody. Vaše osobné údaje však môžeme vždy spracovať, ak je
            to potrebné na určenie, uplatnenie alebo obhajobu právnych nárokov.
          </p>
          <p>
            <b>Právo na prenos údajov</b>
          </p>
          <p>
            Môžete požiadať o to, aby vám vaše osobné údaje, ktoré ste nám
            poskytli na spracovanie na základe súhlasu alebo na splnenie zmluvy,
            boli poskytnuté v štruktúrovanom, bežne používanom a strojovo
            čitateľnom formáte. Máte tiež právo požiadať o prenos týchto
            informácií na iného správcu údajov.
          </p>
          <p>
            <b>Stiahnutie súhlasu</b>
          </p>
          <p>
            Máte právo svoj súhlas stiahnuť a my následne zastavíme svoje
            spracovateľské činnosti na základe tohto právneho dôvodu.
          </p>
          <p>
            O vašej požiadavke/požiadavkách budeme tiež informovať ostatné
            strany, ktorým sme vaše osobné údaje mohli poskytnúť.
          </p>
          <p>
            V prípade pochybností máte právo podať návrh na začatie konania v
            zmysle § 100 Zákona o ochrane osobných údajov na príslušnom dozornom
            orgáne, napríklad prostredníctvom www.dataprotection.gov.sk.
          </p>
          <p>
            <b>
              Ako sa môžem sťažovať ohľadom používania mojich údajov alebo ako
              si uplatním svoje práva?
            </b>
          </p>
          <p>
            Ak chcete podať sťažnosť na to, ako spracovávame vaše osobné údaje,
            a to aj vo vzťahu k vyššie uvedeným právam, môžete sa obrátiť na nás
            na mailovej adrese
            <a href="mailto:recepcia@meanderoravice.sk">
              recepcia@meanderoravice.sk
            </a>
            a vaše podnety a žiadosti budú preverené.
          </p>
          <p>
            Ak s našou odpoveďou nebudete spokojní, alebo sa domnievate, že
            spracovávame vaše údaje nespravodlivo alebo nezákonne, môžete sa
            sťažovať na príslušnom dozornom orgáne, ktorým je Úrad na ochranu
            osobných údajov (ÚOOÚ). Ďalšie informácie o ÚOOÚ a ich postupe
            podávania sťažností nájdete tu:
            <a href="https://www.dataprotection.gov.sk/">
              www.dataprotection.gov.sk
            </a>
            .
          </p>
        </div>
      </Trans>
      <Trans
        ns="pages"
        i18nKey="privacy.paragraph_10"
        components={{
          anchor_1: <a href="https://www.meanderoravice.sk/" />,
          anchor_2: <a href="https://www.dataprotection.gov.sk/" />,
          h2: <h2 />,
          div: <div className="berg-page-privacy-policy-paragraph" />,
        }}
      >
        <h2>8. Kontaktné údaje</h2>
        <div className="berg-page-privacy-policy-paragraph">
          <p>
            Ak máte akékoľvek ďalšie otázky týkajúce sa spracovania vašich
            osobných údajov, môžete nás kontaktovať prostredníctvom našej
            korešpondenčnej adresy uvedenej na našej stránke
            <a href="https://www.meanderoravice.sk/">www.meanderoravice.sk</a>
            alebo mailom na
            <a href="https://www.dataprotection.gov.sk/">
              www.dataprotection.gov.sk
            </a>
          </p>
        </div>
      </Trans>
    </section>
  );
};

export default PrivacyPolicy;
