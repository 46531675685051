import './AttractionsTemplate.scss';
import Attractions from './Attractions/Attractions';
import { IAttractions } from '../../modules/attractions/models';

interface IProps {
  attractions: IAttractions[];
}

const AttractionsTemplate = (props: IProps) => {
  const { attractions } = props;

  return (
    <div className="berg-components-attractions-template">
      {attractions?.map((attraction) => (
        <Attractions key={attraction.id} attraction={attraction} />
      ))}
    </div>
  );
};

export default AttractionsTemplate;
