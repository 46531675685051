import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Pages from './pages';
import './styles/_styles.scss';
import moment from 'moment';
import i18n from './i18n';
import { Provider } from 'react-redux';
import store from './store/store';
import { activitiesPaths } from './pages/activities/Activities';
import { Bugfender } from '@bugfender/sdk';

if (process.env.NODE_ENV !== 'development') {
  console.log('Init bugfender');
  Bugfender.init({
    appKey: 'g7hXeuKecS2gSUH4U5O23LiNFRWQrJrS',
  });
}

moment.updateLocale(i18n.resolvedLanguage, {
  weekdaysShort: i18n.t('common:moment.weekdays_short').split('_'),
  months: i18n.t('common:moment.months').split('_'),
});

const router = createBrowserRouter([
  {
    path: '*',
    element: <Pages.Status404 />,
  },
  {
    path: '/no-script',
    element: <Pages.NoScript />,
  },
  {
    path: '/',
    element: <Pages.Start />,
    children: [
      {
        path: '/cookies-policy',
        element: <Pages.CookiesPolicy />,
      },

      {
        path: '/privacy-policy',
        element: <Pages.PrivacyPolicy />,
      },
      {
        path: '/activities',
        element: <Pages.Activities />,
        children: activitiesPaths,
      },
      {
        path: '/',
        element: <Pages.Attractions />,
      },
      {
        path: '/cart',
        element: <Pages.CartManagement />,
      },
      {
        path: '/cart/order-data',
        element: <Pages.CartData />,
      },
      {
        path: 'cart/summary',
        element: <Pages.CartSummary />,
      },

      { path: '/payment/status', element: <Pages.PaymentStatus /> },
      { path: '/order/:orderId', element: <Pages.OrderStatus /> },
    ],
  },
]);

const root = document.getElementById('root') as HTMLDivElement;

ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
