import { ISkiRental } from '../../modules/skiRental/models';
import './ExtrasListTemplate.scss';
import Rental from './Rental/Rental';

interface IProps {
  rentals: ISkiRental[];
}

const ExtrasListTemplate = (props: IProps) => {
  const { rentals } = props;

  return (
    <div className="berg-components-extras-list-template">
      {rentals?.map((rental) => (
        <Rental key={rental.id} rental={rental} />
      ))}
    </div>
  );
};

export default ExtrasListTemplate;
