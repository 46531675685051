import Components from '..';
import './ChoiceNumber.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

interface IProps {
  name: string;
  count?: number;
  isExpanded?: boolean;
  onSave: (value: number) => void;
  disabled?: boolean;
}

const ChoiceNumberPersonsOrRooms = (props: IProps) => {
  const { name, onSave, isExpanded, disabled } = props;
  const { t } = useTranslation('components');

  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (typeof props?.count === 'number' && isExpanded) {
      setCount(props.count);
    }
  }, [props.count, isExpanded]);

  if (name !== 'adults') {
    return null;
  }

  const handleChange = (value: number) => {
    if (value < 1) {
      return;
    }

    setCount(value);
    // onSave(value, false);
  };

  const handleSave = () => {
    onSave(count);
  };

  return (
    <div className="berg-components-choice-number">
      <div className="berg-components-choice-number-header">
        <p className="berg-components-choice-number-text space">
          {t('choice_number.adults')}
        </p>
        <div className="berg-components-choice-number-number-counter">
          <Components.NumberCounter
            counter={count}
            onChange={handleChange}
            maxCounter={2}
            disabled={disabled}
          />
        </div>
      </div>
      <hr className="berg-components-choice-number-line" />
      <p className="berg-components-choice-number-text--under-3">
        {t('choice_number.max_people')}
      </p>
      {count ? (
        <button
          onClick={handleSave}
          type="button"
          className="berg-components-choice-number-btn"
        >
          {t('choice_number.choose')}
        </button>
      ) : (
        <button
          onClick={handleSave}
          type="button"
          className="berg-components-choice-number-btn"
          disabled={true}
        >
          {t('choice_number.choose')}
        </button>
      )}
      <input
        className="berg-components-choice-number-input"
        type="text"
        name={name}
        id={name}
        value={count || ''}
        readOnly
      />
    </div>
  );
};

export default ChoiceNumberPersonsOrRooms;
