/* eslint-disable max-lines */
import {
  getBasketCurrency,
  getBasketItemsByType,
  getBasketTotal,
  // getBasketTotalAtLocation,
} from '../../modules/basket/selectors';
import './CartManagement.scss';
import { useNavigate } from 'react-router-dom';

import Components from '../../components';
import Hooks from '../../hooks';
import BergPageContentWrapper from '../../components/Storybook/BergPageContentWrapper/BergPageContentWrapper';

import { useTranslation } from 'react-i18next';

import useCartSteps from '../../hooks/useCartSteps';
import { IColumn } from '../../components/OrderTable/OrderTable';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';
import CartOperations from './cart-management/CartOperations';
import { BasketItemType } from '../../modules/basket/models';
import CollapseList from '../../components/CollapseList/CollapseList';
import { usePromoCodeIndicator } from './promoCodeHooks';
import formattedPrice from '../../utils/formattedPrice';
import getClientsStyle from '../../utils/getClientsStyle';
import {
  IProductAdditionalSettings,
  IProductComponents,
} from '../../modules/common/models/productModels';
import formattedProductComponents from '../../utils/formattedProductComponents';

const CartManagement = () => {
  const { t } = useTranslation(['pages', 'common', 'components']);
  const styleForClient = getClientsStyle();
  const hasAutumnPromoCode = usePromoCodeIndicator();

  const navigate = useNavigate();
  const skiPasses = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiPass)
  );
  const skiSchools = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiSchool)
  );
  const therms = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Therms)
  );
  const skiRental = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.SkiRental)
  );
  const accommodations = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Accommodation)
  );
  const parking = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Parking)
  );
  const attraction = Hooks.useAppSelector((x) =>
    getBasketItemsByType(x.basket, BasketItemType.Attractions)
  );
  const basketTotal = Hooks.useAppSelector((x) => getBasketTotal(x.basket));

  const basketCurrency = Hooks.useAppSelector((x) =>
    getBasketCurrency(x.basket)
  );

  // const basketTotalAtLocation = Hooks.useAppSelector((x) =>
  //   getBasketTotalAtLocation(x.basket)
  // );

  const { steps, currentStep } = useCartSteps(0);

  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row.item.name}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row.dataCarrierIds?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const skiRentalTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row.item.name}
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row.item.additionalInformation?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.ski_rental_additional_info')}
              items={row.additionalInformation.map(
                (info: any) =>
                  `${info.name}: ${info.value}${
                    info.unit === null ? '' : info.unit
                  }`
              )}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const thermsTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row.item.name}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.dataCarrierIds?.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const skiSchoolTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row.item.name}
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w305@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:accommodation.cart_table.name_header'),
      rows: (row) => (
        <div>
          {row.item.name},
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
        </div>
      ),
      tdClassName: 'full@sm w25@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_room'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_adult'),
      rows: (row) => row.adultCount,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_child'),
      rows: (row) => row.childrenAges?.length || 0,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:accommodation.cart_table.quantity_header'),
      rows: (row) => row.daysCount,
      thClassName: 'visible@l max-width-th',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        const startDate = moment(row.startDate);
        const endDate = moment(row.endDate);

        if (startDate.isSame(endDate, 'month')) {
          return `${+startDate.format('DD')}-${+endDate.format(
            'DD'
          )}.${endDate.format('MM.YYYY')}`;
        } else if (startDate.isSame(endDate, 'year')) {
          return `${+startDate.format('DD')}.${startDate.format(
            'MM'
          )}-${+endDate.format('DD')}.${endDate.format('MM.YYYY')}`;
        } else {
          return `${+startDate.format('DD')}.${startDate.format(
            'MM.YYYY'
          )}-${+endDate.format('DD')}.${endDate.format('MM.YYYY')}`;
        }
      },
      thClassName: 'visible@l width-date',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(
          row.price,
          row.daysCount,
          row.quantity,
          row.additionalGuestPrice,
          row.additionalGuestsCount
        )} ${row.currency}`,
      thClassName: 'visible@l sum',
      tdClassName: 'w20@sm w10@l nowrap',
    },

    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row.item.consumerCategories
            .filter((category: any) => category.id === row.consumerCategoryId)
            .map((item: any) => item.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.licencePlates?.length > 0 && (
            <Components.CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${row.price} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.date).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) => (
        <div>
          {row?.priceRecalculated ? (
            <div className="berg-table-discount">
              {calculatePrice(row.price, row.quantity)} {row.currency}{' '}
              <span>
                {calculatePrice(row.priceBeforeRecalculation, row.quantity)}{' '}
                {row.currency}
              </span>
            </div>
          ) : (
            `${calculatePrice(row.price, row.quantity)} ${row.currency}`
          )}
        </div>
      ),

      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const attractionTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {`${row?.item?.consumerCategories
            .filter((category: any) => category?.id === row?.consumerCategoryId)
            .map((item: any) => item?.name)} `}
          <span className="cart-summary-ticket-info">{row?.item?.name}</span>
          {row.priceRecalculated && (
            <Components.DiscountMessage items={row.priceRecalculationMessage} />
          )}
          {row?.productComponents?.length > 0 &&
            row?.productComponents.some((product: IProductComponents) =>
              product?.productAdditionalSettings.some(
                (setting: IProductAdditionalSettings) => setting.value !== ''
              )
            ) && (
              <CollapseList
                buttonName={t('pages:cart_summary.skipass_additional_info')}
                items={formattedProductComponents(row)}
              />
            )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) => `${formattedPrice(row.price)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => (
        <div>
          <div>{moment(row.date).format('DD.MM.YYYY')}</div>
        </div>
      ),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.price, row.quantity)} ${row.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: (row) => <CartOperations basketId={row.id} />,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  return (
    <BergPageContentWrapper header={t('pages:cart.header')}>
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      {skiPasses?.length > 0 && (
        <div className="skiPasses-table-management">
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.passes')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiPasses}
            columns={skiPassTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </div>
      )}
      {skiRental?.length > 0 && (
        <>
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.rentals')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiRental}
            columns={skiRentalTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </>
      )}
      {therms?.length > 0 && (
        <div className="therms-table-management">
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.therms')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={therms}
            columns={thermsTable}
          />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
        </div>
      )}
      {skiSchools?.length > 0 && (
        <>
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.schools')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={skiSchools}
            columns={skiSchoolTable}
          />
        </>
      )}
      {accommodations?.length > 0 && (
        <div className="accommodations-table">
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.accommodation')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={accommodations}
            columns={accommodationTable}
          />
        </div>
      )}
      {parking?.length > 0 && (
        <div className="parking-table-management">
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.parking')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={parking}
            columns={parkingTable}
          />
        </div>
      )}
      {attraction?.length > 0 && (
        <div className="attraction-table-management">
          <p
            className="cart-managements-status-label"
            data-client-style={styleForClient}
          >
            {t('common:table_label.attraction')}
          </p>
          <Components.OrderTable
            tableClassName="berg-pages-cart-table"
            data={attraction}
            columns={attractionTable}
          />
        </div>
      )}
      <Components.BergStack
        direction="column"
        alignItems="end"
        justifyContent="space-between"
        className="cart-products__buttons"
      >
        {hasAutumnPromoCode() ? (
          <div className={'order-summary-footer__promo-code'}>
            {t('components:order_summary.promo_september')}
          </div>
        ) : null}
        <div>
          <div className="order-summary-footer">
            <div className="order-summary-footer__item-header--bold">
              {t('components:order_summary_header.amount_label')}
            </div>
            <div className="order-summary-footer__item-description--bold">
              {basketTotal} {basketCurrency}
            </div>
          </div>
          {/* <Components.BergDevider color="ligth-grey"></Components.BergDevider> */}
          {/* <div className="order-summary-footer">
            <div className="order-summary-footer__item-header--price-on-Site">
              {t('components:order_summary_header.amount_label_offline')}
            </div>
            <div className="order-summary-footer__item-description">
              {basketTotalAtLocation} {basketCurrency}
            </div>
          </div> */}
          {/* <div className="order-summary-footer">
            <div className="order-summary-footer__item-header--price-online">
              {t('components:order_summary_header.amount_label_online')}
            </div>
            <div className="order-summary-footer__item-description--price-online">
              {basketTotal - basketTotalAtLocation} {basketCurrency}
            </div>
          </div> */}
        </div>
        <div>
          <Components.BergButton
            color="border"
            onClick={() => navigate('/?prefill=today')}
            label={t('pages:cart.continue_shopping')}
          ></Components.BergButton>
          {!attraction ? (
            <Components.BergButton
              color="gray-400"
              onClick={() => navigate('/cart/order-data')}
              label={t('pages:cart.goto_order_data_button_text')}
              disabled
            ></Components.BergButton>
          ) : (
            <Components.BergButton
              color="full"
              onClick={() => navigate('/cart/order-data')}
              label={t('pages:cart.goto_order_data_button_text')}
            ></Components.BergButton>
          )}
        </div>
      </Components.BergStack>
    </BergPageContentWrapper>
  );
};

export default CartManagement;
