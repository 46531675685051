import React from 'react';
import './TouristAttraction.scss';
import Buttons from './Buttons';

interface IProps {
  attraction: {
    id: number;
    isLeftText: boolean;
    header: string;
    title: string;
    text: string;
    imgSrc: string;
    imgAlt: string;
    buttonText: string;
    linkHrefForBooking?: string;
    linkHrefForReadMore?: string;
    isRent: boolean;
    additionalButtonText: string;
    additionalLinkHref: string;
    isSeason?: boolean;
  };
}

const TouristAttraction = (props: IProps) => {
  const { attraction } = props;

  return (
    <>
      {attraction.isLeftText ? (
        <figure
          className="berg-components-tourist-attractions"
          data-berg-layout="leftText"
        >
          <figcaption>
            <h1>{attraction.header}</h1>
            <p>
              <span>{attraction.title}</span>
            </p>
            <p>{attraction.text}</p>
            <Buttons
              buttonText={attraction.buttonText}
              linkHrefForBooking={attraction.linkHrefForBooking}
              linkHrefForReadMore={attraction.linkHrefForReadMore}
              isRent={attraction.isRent}
              additionalButtonText={attraction.additionalButtonText}
              additionalLinkHref={attraction.additionalLinkHref}
              isSeason={attraction.isSeason}
            />
          </figcaption>
          <picture>
            <img src={attraction.imgSrc} alt={attraction.imgAlt} />
          </picture>
        </figure>
      ) : (
        <figure
          className="berg-components-tourist-attractions"
          data-berg-layout="rightText"
        >
          <picture>
            <img src={attraction.imgSrc} alt={attraction.imgAlt} />
          </picture>
          <figcaption>
            <h1>{attraction.header}</h1>
            <p>
              <span>{attraction.title}</span>
            </p>
            <p>{attraction.text}</p>
            <Buttons
              buttonText={attraction.buttonText}
              linkHrefForBooking={attraction.linkHrefForBooking}
              linkHrefForReadMore={attraction.linkHrefForReadMore}
              isRent={attraction.isRent}
              additionalButtonText={attraction.additionalButtonText}
              additionalLinkHref={attraction.additionalLinkHref}
              isSeason={attraction.isSeason}
            />
          </figcaption>
        </figure>
      )}
    </>
  );
};

export default TouristAttraction;
