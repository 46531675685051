import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '../../../assets/icons';
import Components from '../../../components';
import Hooks from '../../../hooks';
import { fetchAccommodations } from '../../../modules/accommodation/operations';

import './Criteria.scss';
import NumberField from './NumberField';
import { useSearchParams } from 'react-router-dom';
import { IAccommodationSearchParams } from '../../../modules/accommodation/models';
import { useCookies } from 'react-cookie';
import { CookieNames } from '../../../config/globalVariables';

interface IProps {
  selectedRoom: any;
}

const Criteria = (props: IProps) => {
  const { selectedRoom } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const dispatch = Hooks.useAppDispatch();

  const { t } = useTranslation(['pages', 'components', 'common']);
  const [cookies] = useCookies([CookieNames.PromoCode]);

  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    adultCount: 0,
    childrenAges: [] as number[],
    priceBeforeRecalculation: 0,
    priceRecalculated: false,
  } as IAccommodationSearchParams);

  const [errorFieldNames, setErrorFieldNames] = useState<{
    [key: string]: boolean;
  }>({
    date: true,
    adultCount: false,
    children: false,
  });

  const handleAdultChange = (event: { name: string; value: number }) => {
    const { value } = event;
    if (value) {
      searchParams.set('adultCount', value.toString());
      setSearchParams(searchParams);
      setFormData({ ...formData, adultCount: value });
    }
  };
  const handleChildrenChanged = (event: {
    name: string;
    value: number;
    childrenAges?: number[];
  }) => {
    const { childrenAges } = event;
    searchParams.delete('childrenAges');
    childrenAges?.map((x) => {
      searchParams.append('childrenAges', x.toString());
    });
    setSearchParams(searchParams);
    setFormData((fd) => ({ ...fd, childrenAges: childrenAges ?? [] }));
  };

  const toggleCalendar = () => {
    setIsCalendarVisible((prev) => !prev);
  };

  const onDatesChange = (dates: [string, string]) => {
    setFormData({ ...formData, startDate: dates[0], endDate: dates[1] });
    searchParams.set('startDate', dates[0]);
    searchParams.set('endDate', dates[1]);
    setSearchParams(searchParams);
    toggleCalendar();
  };

  useEffect(() => {
    if (searchParams.has('startDate') && searchParams.has('endDate')) {
      const startDate = searchParams.get('startDate') as string;
      const endDate = searchParams.get('endDate') as string;
      if (startDate != formData.startDate || endDate != formData.endDate) {
        setFormData((fd) => ({
          ...fd,
          startDate: startDate,
          endDate: endDate,
        }));
      }
    }

    if (searchParams.has('childrenAges')) {
      const childrenAges = searchParams
        .getAll('childrenAges')
        .map((x) => parseInt(x));

      if (childrenAges.length > 0) {
        setFormData((fd) => ({ ...fd, childrenAges: childrenAges }));
      }
    }

    if (searchParams.has('adultCount')) {
      const adultCount = Number.parseInt(
        searchParams.get('adultCount') as string
      );

      if (adultCount && adultCount !== formData.adultCount) {
        setFormData((fd) => ({ ...fd, adultCount: adultCount }));
      }
    }
  }, []);

  useEffect(() => {
    const errors = {
      date: false,
      adultCount: false,
      childrenAges: false,
    } as { [key: string]: boolean };
    if (formData.adultCount == 0) {
      errors.adultCount = true;
    }
    if (formData.startDate.length == 0 || formData.endDate.length == 0) {
      errors.date = true;
    }

    setErrorFieldNames(errors);
  }, [formData]);

  useEffect(() => {
    if (searchParams.has('list')) {
      onSubmit();
    }
  }, [errorFieldNames]);

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (Object.values(errorFieldNames).some((x) => x)) {
      return;
    }
    dispatch(
      fetchAccommodations({
        startDate: formData.startDate,
        endDate: formData.endDate,
        adultCount: formData.adultCount,
        childrenAges: formData.childrenAges,
        promoCode: cookies[CookieNames.PromoCode],
        priceBeforeRecalculation: formData.priceBeforeRecalculation,
        priceRecalculated: formData.priceRecalculated,
      })
    );
    if (searchParams.has('list')) {
      searchParams.delete('list');
      setSearchParams(searchParams);
    }
  };

  const maxCountChildren = (countAdult: number) => {
    switch (countAdult) {
      case 1:
        return 3;
      case 2:
        return 2;
      default:
        return 0;
    }
  };

  return (
    <>
      {!selectedRoom && (
        <form onSubmit={onSubmit} className="accommodation-criteria">
          <h3>{t('pages:activities.accommodation.criteria_header')}</h3>
          <div className="accommodation-criteria__inputs">
            <div className="accommodation-criteria__period-wrapper">
              <button
                type="button"
                className="accommodation-criteria__period"
                onClick={toggleCalendar}
                aria-invalid={errorFieldNames.date}
              >
                <Icons.Calendar />
                <span className="accommodation-criteria__period-dates">
                  {formData.startDate.length > 0 && formData.endDate.length > 0
                    ? moment(formData.startDate).format('DD.MM.YYYY') +
                      ' - ' +
                      moment(formData.endDate).format('DD.MM.YYYY')
                    : t('pages:activities.accommodation.period_placeholder')}
                </span>
                <div
                  aria-expanded={isCalendarVisible}
                  className="accommodation-criteria__period-arrow"
                  role="switch"
                />
              </button>
              {errorFieldNames.date && (
                <div className="accommodation-criteria__validation-error">
                  <Icons.AlertAttention />
                  {t('common:validation_required')}
                </div>
              )}
              <div
                aria-hidden={!isCalendarVisible}
                className="accommodation-criteria__calendar-wrapper"
              >
                <div className="accommodation-criteria__calendar">
                  <Components.RangeCalendar
                    name="accommodation"
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    onDatesChange={onDatesChange}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="accommodation-criteria__persons">
              {formData.childrenAges.length === 3 ? (
                <div>
                  <NumberField
                    icon={<Icons.Adult />}
                    label={t('components:search.adults')}
                    name="adults"
                    value={formData.adultCount}
                    onChange={handleAdultChange}
                    invalid={errorFieldNames.adultCount}
                    disabled={true}
                  />
                  {errorFieldNames.adultCount && (
                    <div className="accommodation-criteria__validation-error">
                      <Icons.AlertAttention />
                      {t('common:validation_required')}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <NumberField
                    icon={<Icons.Adult />}
                    label={t('components:search.adults')}
                    name="adults"
                    value={formData.adultCount}
                    onChange={handleAdultChange}
                    invalid={errorFieldNames.adultCount}
                  />
                  {errorFieldNames.adultCount && (
                    <div className="accommodation-criteria__validation-error">
                      <Icons.AlertAttention />
                      {t('common:validation_required')}
                    </div>
                  )}
                </div>
              )}
              {/* <div>
            <NumberField
              icon={<Icons.Adult />}
              label={t('components:search.adults')}
              name="adults"
              value={formData.adultCount}
              onChange={handleAdultChange}
              invalid={errorFieldNames.adultCount}
            />
            {errorFieldNames.adultCount && (
              <div className="accommodation-criteria__validation-error">
                <Icons.AlertAttention />
                {t('common:validation_required')}
              </div>
            )}
          </div> */}

              <div>
                <NumberField
                  icon={<Icons.Child />}
                  label={t('components:search.children')}
                  name="children"
                  invalid={errorFieldNames.childrenAges}
                  childrenAges={formData.childrenAges}
                  value={formData.childrenAges.length}
                  onChange={handleChildrenChanged}
                  maxCount={maxCountChildren(formData.adultCount)}
                />
                {errorFieldNames.childrenAges && (
                  <div className="accommodation-criteria__validation-error">
                    <Icons.AlertAttention />
                    {t('components:choice_number.age_of_children_error')}
                  </div>
                )}
              </div>
            </div>

            <hr />

            <button type="submit" className="accommodation-criteria__search">
              {t('components:search.submit')}
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default Criteria;
